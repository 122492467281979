import { Breadcrumb } from "antd";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { RouteContext } from "../Dashboard";

const Breadcrumbs = () => {
  const { breadcrumbItems } = useContext(RouteContext);

  return (
    <Breadcrumb style={{ margin: "0 30px 30px" }}>
      {breadcrumbItems.map((item: any, key: number) => (
        <Breadcrumb.Item key={key}>
          <Link to={`/dashboard${item.path}`}>{item.title}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
