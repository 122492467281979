import { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Row, Space, Typography } from "antd";
import Spinner from "../Spinner";
import { Link } from "react-router-dom";
import { ContentTitle } from "../ContentTitle";
import { RootCausesProperties } from "./PatientConditions";

interface RootCausesCardDataProperties {
  title: JSX.Element;
  conditions: JSX.Element[];
}

const RootCauses = ({
  data,
  loading,
}: {
  data: RootCausesProperties | undefined;
  loading: boolean;
}) => {
  const [risks, setRisks] = useState<RootCausesCardDataProperties[]>([]);

  const conditionButton = (
    condition: { id: number; conditionName: string },
    key: number
  ) => {
    return (
      <Link key={key} to={`/dashboard/condition-profile/${condition.id}`}>
        <Button block size="small">
          {condition.conditionName.slice(2).replace(/([a-z])([A-Z])/g, "$1 $2")}
        </Button>
      </Link>
    );
  };

  const prepareData = useCallback(() => {
    return [
      {
        title: <Typography.Text type="danger">High Risk:</Typography.Text>,
        conditions: data?.HighRisk ? data.HighRisk.map(conditionButton) : [],
      },
      {
        title: <Typography.Text type="warning">Moderate Risk:</Typography.Text>,
        conditions: data?.ModerateRisk
          ? data.ModerateRisk.map(conditionButton)
          : [],
      },
      {
        title: <Typography.Text type="success">Low Risk:</Typography.Text>,
        conditions: data?.LowRisk ? data.LowRisk.map(conditionButton) : [],
      },
      {
        title: (
          <Typography.Text style={{ color: "#4285F4" }}>
            Unknown:
          </Typography.Text>
        ),
        conditions: data?.UnknownRisk
          ? data.UnknownRisk.map(conditionButton)
          : [],
      },
    ];
  }, [data]);

  useEffect(() => {
    !loading && setRisks(prepareData());
  }, [loading, prepareData]);

  return (
    <Col
      className="root-causes-card"
      xxl={{ span: 14 }}
      xl={{ span: 14 }}
      span="24"
      style={{ paddingRight: "0" }}
    >
      <Card
        title={ContentTitle("Root Causes")}
        size="small"
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
        bodyStyle={{ padding: "0", flex: 1 }}
      >
        {loading ? (
          <Spinner
            spinnerStyles={{
              width: "100%",
              margin: "10px 0 0",
              justifyContent: "center",
            }}
          />
        ) : (
          <Row
            className="root-causes-row"
            align="stretch"
            style={{ height: "100%" }}
            wrap={false}
          >
            {risks.map((risk: any, key: any) => (
              <Col md={{ span: 6 }} span="24" key={key}>
                <Card
                  title={risk.title}
                  size="small"
                  style={{ height: "100%" }}
                >
                  <Space direction="vertical" style={{ width: "100%" }}>
                    {risk.conditions}
                  </Space>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Card>
    </Col>
  );
};

export default RootCauses;
