import { ReactFragment, useContext } from "react";
import { AuthContext } from "../../App";
import { Avatar, List } from "antd";
import { Menu, Dropdown } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { AuthProperties } from "../Login/useAuth";

const UserMenu = ({ isResponsive }: { isResponsive?: boolean }) => {
  const { user, logout } = useContext<AuthProperties>(AuthContext);
  const history = useHistory();
  const authUser = user || JSON.parse(localStorage.getItem("user") || "");

  const userMenuData: { text: string; icon: ReactFragment; action: any }[] = [
    {
      text: "My Profile",
      icon: <UserOutlined />,
      action: () => {
        history.push("/dashboard/my-account");
      }
    },
    { text: "Log Out", icon: <LogoutOutlined />, action: logout }
  ];

  const menu = (
    <Menu selectable={false}>
      {userMenuData.map((item, key) => (
        <Menu.Item icon={item.icon} key={key} onClick={item.action}>
          {item.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  return isResponsive ? (
    <List
      itemLayout="horizontal"
      dataSource={userMenuData}
      style={{ margin: "30px 0 0" }}
      renderItem={item => (
        <List.Item onClick={item.action} style={{ cursor: "pointer" }}>
          <List.Item.Meta avatar={item.icon} title={item.text} />
        </List.Item>
      )}
    />
  ) : (
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      <Avatar style={{ backgroundColor: "#294B6F", fontSize: "16px" }} size="large">
        {authUser.firstName && authUser.lastName
          ? (authUser.firstName.charAt(0) + authUser.lastName.charAt(0)).toUpperCase()
          : ""}
      </Avatar>
    </Dropdown>
  );
};

export default UserMenu;
