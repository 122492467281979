import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import apiClient, { endpoints } from "../../utils/apiClient";
import { ConsultationOrderStatus, TransactionStatuses } from "./types";

export interface Filters {
  currentPage: number;
  skip: number;
  startDate: string | null;
  endDate: string | null;
  transactionStatus: string | null;
  status: string | null;
  searchFilter: string | null;
  networkIds: number[] | undefined;
}

export interface ConsultationsTableProperties {
  patientConsultationOrderId: number;
  patientName: string;
  patientId: number;
  patientEmail: string;
  patientPhoneNumber: string;
  status: ConsultationOrderStatus;
  transactionId: string;
  transactionStatus: TransactionStatuses;
  price: string;
  consultationName: string;
  date: string | number | Date;
  [key: string]: any;
}

export const useItems = () => {
  const [items, setItems] = useState<ConsultationsTableProperties[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [consultationsFilters, setConsultationsFilters] =
    useState<Filters | null>(null);

  const history = useHistory();
  const currentLocation = useLocation();
  const [refreshSimulator, setRefreshSimulator] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocation.search);

    setConsultationsFilters({
      startDate: searchParams.get("startDate"),
      endDate: searchParams.get("endDate"),
      searchFilter: searchParams.get("searchFilter"),
      transactionStatus: searchParams.get("transactionStatus")
        ? searchParams.get("transactionStatus")
        : null,
      status: searchParams.get("orderStatus")
        ? searchParams.get("orderStatus")
        : null,
      currentPage: searchParams.get("page")
        ? Number(searchParams.get("page"))
        : 1,
      skip:
        ((searchParams.get("page") ? Number(searchParams.get("page")) : 1) -
          1) *
        (10 as number),
      networkIds: searchParams.get("networkIds")?.length
        ? searchParams
            .get("networkIds")
            ?.split(",")
            .map((n) => parseInt(n))
        : [],
    });
  }, [currentLocation]);

  useEffect(() => {
    console.log("FILTERS: ", consultationsFilters);

    if (consultationsFilters === null) return;

    const filters = {
      skip: consultationsFilters.skip,
      take: 10,
    };
    if (consultationsFilters.startDate && consultationsFilters.endDate) {
      Object.assign(filters, {
        startDate: consultationsFilters.startDate,
        endDate: consultationsFilters.endDate,
      });
    }
    if (consultationsFilters.networkIds) {
      Object.assign(filters, {
        networkIds: consultationsFilters.networkIds,
      });
    }
    if (consultationsFilters.status) {
      Object.assign(filters, { status: consultationsFilters.status });
    }
    if (consultationsFilters.transactionStatus) {
      Object.assign(filters, {
        transactionStatus: consultationsFilters.transactionStatus,
      });
    }
    if (consultationsFilters.searchFilter) {
      Object.assign(filters, {
        searchFilter: consultationsFilters.searchFilter,
      });
    }
    apiClient
      .post(endpoints.consultationOrders, filters)
      .then((response) => {
        console.log("ITEMS: ", response.data.consultationOrders);

        setItems(response.data.consultationOrders);
        setTotalItems(response.data.count);
        setLoading(false);
      })
      .catch((err) => {
        console.log("ERROR GET CONSULTATIONS: ", err);

        if (err.response?.status === 403) {
          history.push("/dashboard/consultation-orders");
        }
      });
  }, [consultationsFilters, refreshSimulator]);

  return {
    items,
    setItems,
    loading,
    setLoading,
    totalItems,
    consultationsFilters,
    setConsultationsFilters,
    refreshSimulator,
    setRefreshSimulator,
  };
};
