import { EditFilled } from "@ant-design/icons";
import { Button, Card, Col, Divider, Input, List, Row, Space, Table, Tooltip, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContentTitle } from "../ContentTitle";
import { RouteContext } from "../Dashboard";
import useShopOffers from "./useShopOffers";

interface LabPanelsDetailsProperties {
  summaryDescription: string;
  labPackageDescription: string;
  includedLabs: string[];
  shopOfferPrices: { [key: string]: number | string | undefined };
  packageType: number;
  labName: string;
  isHidden: boolean;
}

interface OfferTablePricesProperties {
  key: string;
  state: string;
  price: number | string | undefined;
}

const OfferDetails = () => {
  const { packageType } = useParams<{ packageType: string }>();
  const [data, setData] = useState<LabPanelsDetailsProperties>();
  const [offerTablePrices, setOfferTablePrices] = useState<OfferTablePricesProperties[]>([]);
  const [editHeadline, setEditHeadline] = useState("");
  const [showPriceInput, setShowPriceInput] = useState(false);
  const [showTextInput, setShowTextInput] = useState(false);
  const [isLabHidden, setIsLabHidden] = useState<boolean>(false);
  const [priceInputValue, setPriceInputValue] = useState<number | string>();
  const [textInputValue, setTextInputValue] = useState<any>();
  const [stateToBeEdited, setStateToBeEdited] = useState<string>();
  const [descriptionToBeSaved, setDescriptionToBeSaved] = useState<string>();
  const { getOffers, editOffer, loading } = useShopOffers();
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Shop Offers",
        path: "/shop-offers"
      },
      {
        title: "Offer Details",
        path: `/offer-details/${packageType}`
      }
    ]);
    setCurrentKey("shop-offers");
  }, [packageType, setBreadcrumbItems, setCurrentKey]);

  useEffect(() => {
    packageType &&
      getOffers(packageType).then(offerDetails => {
        setData(offerDetails);
        setIsLabHidden(offerDetails.isHidden);
      });
  }, [getOffers, packageType]);

  useEffect(() => {
    const tableData = [];

    for (const state in data?.shopOfferPrices) {
      const price = data?.shopOfferPrices[state];
      tableData.push({ state, price, key: state });
    }

    setOfferTablePrices(tableData);
  }, [data?.shopOfferPrices]);

  const handleEditPrice = (state: string = "") => {
    setEditHeadline(Boolean(state) ? `${state} State Price` : "All States Price");
    setPriceInputValue(data?.shopOfferPrices && data?.shopOfferPrices[state]);
    setStateToBeEdited(state);
    setShowPriceInput(true);
    setShowTextInput(false);

    setTextInputValue("");
    setDescriptionToBeSaved("");
  };

  const handleEditText = (description: keyof LabPanelsDetailsProperties) => {
    setEditHeadline(`Edit ${description === "summaryDescription" ? "Summary Description" : "Lab package description"}`);
    setTextInputValue(data && data[description]);
    setDescriptionToBeSaved(description);
    setShowPriceInput(false);
    setShowTextInput(true);

    setPriceInputValue("");
    setStateToBeEdited("");
  };

  const handleSave = () => {
    let dataToBeSaved = { ...data, isHidden: !isLabHidden, newPricesShopOffersDto: { ...data?.shopOfferPrices } };

    if (!isNaN(Number(priceInputValue)) && showPriceInput) {
      dataToBeSaved = {
        ...data,
        newPricesShopOffersDto: { ...data?.shopOfferPrices, [stateToBeEdited as string]: priceInputValue },
        isHidden: isLabHidden
      };

      if (!stateToBeEdited && !descriptionToBeSaved) {
        const allStatesPrices: any = {};

        for (const state in data?.shopOfferPrices) {
          allStatesPrices[state] = priceInputValue;
        }

        dataToBeSaved = {
          ...data,
          newPricesShopOffersDto: { ...allStatesPrices },
          isHidden: isLabHidden
        };
      }
    }

    if (descriptionToBeSaved && !stateToBeEdited) {
      dataToBeSaved = {
        ...data,
        [descriptionToBeSaved]: textInputValue,
        // @ts-ignore
        newPricesShopOffersDto: data?.shopOfferPrices,
        isHidden: isLabHidden
      };
    }

    editOffer(dataToBeSaved).then(response => {
      if (response) {
        //   @ts-ignore
        setData({ ...dataToBeSaved, shopOfferPrices: dataToBeSaved?.newPricesShopOffersDto });
        setShowPriceInput(false);
        setShowTextInput(false);
        setPriceInputValue("");
        setStateToBeEdited("");
        setTextInputValue("");
        setDescriptionToBeSaved("");
      }
    });
  };

  return (
    <Row align="stretch" gutter={15} style={{ margin: "0 30px" }}>
      <Col className="offer-details-col" xl={{ span: 8 }} span="24" style={{ paddingLeft: "0" }}>
        <Card
          size="small"
          title={
            <>
              <Space style={{ position: 'relative', display: "flex", justifyContent: "space-between" }}>
                <Typography.Text ellipsis strong style={{maxWidth: '75%', top: 0, position: 'absolute'}}>{data?.labName}</Typography.Text>
                <Button
                  size="small"
                  loading={loading}
                  onClick={() => {
                    setIsLabHidden(!isLabHidden);
                    setShowPriceInput(false);
                    setShowTextInput(false);
                    setPriceInputValue("");
                    setStateToBeEdited("");
                    setTextInputValue("");
                    setDescriptionToBeSaved("");

                    handleSave();
                  }}
                >
                  {isLabHidden ? "Unhide" : "Hide"} Offer
                </Button>
              </Space>
            </>
          }
          loading={loading}
        >
          <List
            size="small"
            split={false}
            header={
              <>
                <Typography.Text type="secondary">Labs included in package</Typography.Text>
                <Divider style={{ margin: "10px 0 0" }} />
              </>
            }
            dataSource={data?.includedLabs}
            renderItem={item => <List.Item style={{ padding: "4px 16px" }}>{item}</List.Item>}
          />

          <List
            size="small"
            split={false}
            header={
              <>
                <Space style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography.Text type="secondary">Summary Description</Typography.Text>
                  <Tooltip title="Edit summary description">
                    <Button type="text" onClick={() => handleEditText("summaryDescription")}>
                      <EditFilled style={{ color: "#ACACAC", fontSize: "17px" }} />
                    </Button>
                  </Tooltip>
                </Space>
                <Divider style={{ margin: "0" }} />
              </>
            }
            dataSource={[data?.summaryDescription || ""]}
            renderItem={item => <List.Item style={{ padding: "4px 0" }}>{item}</List.Item>}
          />

          <List
            size="small"
            split={false}
            header={
              <>
                <Space style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography.Text type="secondary">Lab package description</Typography.Text>
                  <Tooltip title="Edit lab package description">
                    <Button type="text" onClick={() => handleEditText("labPackageDescription")}>
                      <EditFilled style={{ color: "#ACACAC", fontSize: "17px" }} />
                    </Button>
                  </Tooltip>
                </Space>
                <Divider style={{ margin: "0" }} />
              </>
            }
            dataSource={[data?.labPackageDescription || ""]}
            renderItem={item => <List.Item style={{ padding: "4px 0" }}>{item}</List.Item>}
          />
        </Card>
      </Col>

      <Col className="offer-details-col" xl={{ span: 8 }} span="24">
        <Table
          columns={[
            {
              title: ContentTitle("state"), dataIndex: "state", width: "60%",

              render: (record: string) => (
                <>
                  {record.replace(/([A-Z])/g, ' $1').trim()}
                </>
              ),
            },
            {
              title: ContentTitle("price"),
              dataIndex: "price",
              align: "center",
              width: "25%",
              render: text => <Typography.Text>${text}</Typography.Text>
            },
            {
              title: <Button onClick={() => handleEditPrice()}>Edit price for all</Button>,
              dataIndex: "action",
              align: "center",
              width: "25%",
              render: (text, record, index) => (
                <Tooltip title={`Edit price for ${record.state}`}>
                  <Button type="text" onClick={() => handleEditPrice(record.state)}>
                    <EditFilled style={{ color: "#ACACAC", fontSize: "17px" }} />
                  </Button>
                </Tooltip>
              )
            }
          ]}
          dataSource={offerTablePrices}
          loading={loading}
          size="small"
          bordered
          pagination={{
            size: "small",
            hideOnSinglePage: true,
            showSizeChanger: false,
            pageSize: 10
          }}
        />
      </Col>

      <Col className="offer-details-col" xl={{ span: 8 }} span="24" style={{ paddingRight: "0" }}>
        {(showPriceInput || showTextInput) && <Typography.Paragraph>{editHeadline}</Typography.Paragraph>}

        {showPriceInput && (
          <Space>
            <Input
              prefix="$"
              value={priceInputValue}
              onChange={e => {
                const { value } = e.target;
                setPriceInputValue(isNaN(Number(value)) ? priceInputValue : Number(value) * 1);
              }}
            />
            <Button onClick={() => setShowPriceInput(false)}>Cancel</Button>
            <Button type="primary" disabled={!Boolean(priceInputValue)} loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        )}

        {showTextInput && (
          <>
            <Input.TextArea
              value={textInputValue}
              onChange={e => {
                const { value } = e.target;
                setTextInputValue(value);
              }}
            />
            <Space style={{ display: "flex", margin: "10px 0", justifyContent: "flex-end" }}>
              <Button onClick={() => setShowTextInput(false)}>Cancel</Button>
              <Button type="primary" disabled={!Boolean(textInputValue)} loading={loading} onClick={handleSave}>
                Save
              </Button>
            </Space>
          </>
        )}
      </Col>
    </Row>
  );
};

export default OfferDetails;
