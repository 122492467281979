import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Button, Modal } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import useDownloadReport from "./useDownloadReport";

const ExportButton = ({ isResponsive }: { isResponsive?: boolean }) => {
  const {
    downloadFinanceReport,
    downloadPatientsReport,
    downloadConsultationsReport,
  } = useDownloadReport();
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [networkIds, setNetworkIds] = useState<number[] | undefined>(undefined);

  const [loading, setLoading] = useState(false);
  const currentLocation = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocation.search);
    setStartDate(searchParams.get("startDate"));
    setEndDate(searchParams.get("endDate"));
    setNetworkIds(
      searchParams.get("networkIds")?.length
        ? searchParams
            .get("networkIds")
            ?.split(",")
            .map((n) => parseInt(n))
        : []
    );
  }, [currentLocation]);

  const handleDownloadReport = async () => {
    setLoading(true);

    const isFinances = currentLocation.pathname.includes("finances");
    const isPatients = currentLocation.pathname.includes("patients");
    const isConsultations = currentLocation.pathname.includes("consultations");

    let report = null;
    if (isFinances) {
      report = await downloadFinanceReport(startDate, endDate, networkIds);
    } else if (isPatients) {
      report = await downloadPatientsReport();
    } else if (isConsultations) {
      report = await downloadConsultationsReport(
        startDate,
        endDate,
        networkIds
      );
    }

    setLoading(false);
    Modal.confirm({
      title: "Your report is ready!",
      okText: (
        <a
          href={URL.createObjectURL(report)}
          download={`${
            isFinances ? "Finances" : isPatients ? "Patients" : "Consultations"
          }-Report.xlsx`}
        >
          Download
        </a>
      ),
    });
  };

  return (
    <Button
      block={isResponsive}
      loading={loading}
      onClick={handleDownloadReport}
      icon={<DownloadOutlined />}
      style={{ margin: isResponsive ? "10px 0 0" : "0" }}
    >
      Export Report
    </Button>
  );
};

export default ExportButton;
