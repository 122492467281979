import { MenuOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { useState } from "react";
import Navbar from "../Navbar";

const ResponsiveHeader = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        className="responsive-header-hamburger"
        type="text"
        onClick={() => setVisible(true)}
        style={{ position: "absolute", top: "15px", right: "0" }}
      >
        <MenuOutlined />
      </Button>

      <Drawer
        className="responsive-navbar"
        placement="right"
        mask={false}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Navbar isResponsive />
      </Drawer>
    </>
  );
};

export default ResponsiveHeader;
