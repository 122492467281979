import { Card, Col, Divider, List, Space, Typography } from "antd";
import { ContentTitle } from "../ContentTitle";
import { ConsultationDetailsProps } from ".";

export const OrderDetails = ({ data }: { data: ConsultationDetailsProps }) => {
  return (
    <Col
      className="order-details"
      xxl={{ span: 8 }}
      xl={{ span: 8 }}
      lg={{ span: 8 }}
      md={{ span: 24 }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
    >
      <Card
        title={ContentTitle("Order Details")}
        size="small"
        style={{ display: "flex", height: "100%", flexDirection: "column" }}
        bodyStyle={{ height: "100%", paddingBottom: "0" }}
      >
        <List
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          dataSource={[{ name: data.consultationName, price: data.price }]}
          renderItem={(item) => (
            <>
              <Space
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography.Paragraph>{item.name}</Typography.Paragraph>
                <Typography.Paragraph>${item.price}</Typography.Paragraph>
              </Space>

              <Space>
                <Typography.Paragraph type="secondary">
                  {data.consultationDescription}
                </Typography.Paragraph>
              </Space>
            </>
          )}
          footer={
            <>
              <Divider style={{ margin: "10px 0" }} />
              <Space
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography.Text strong>Total</Typography.Text>
                <Typography.Text strong>${data.price}</Typography.Text>
              </Space>
            </>
          }
        />
      </Card>
    </Col>
  );
};
