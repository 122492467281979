import { Button, Card, Col, Form, Input, Space } from "antd";
import { endpoints } from "../../utils/apiClient";
import useAdminsActions, {
  AdminActionsProperties,
} from "../AdminProfile/useAdminsActions";
import { ContentTitle } from "../ContentTitle";

interface EditMyAccountProperties {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const EditMyAccount = ({
  setShowEditProfile,
  data,
  setData,
}: {
  setShowEditProfile: Function;
  data: AdminActionsProperties | undefined;
  setData: Function;
}) => {
  const { postAdminData } = useAdminsActions();
  const handleEdit = async (values: EditMyAccountProperties) => {
    const response = await postAdminData(endpoints.editSelfProfile, values);
    response && setData(values);
  };

  return (
    <Col span="8">
      <Card title={ContentTitle("Edit My Account")} size="small">
        <Form
          name="editSelfProfile"
          layout="vertical"
          requiredMark={false}
          onFinish={handleEdit}
          initialValues={{ ...data }}
        >
          <Form.Item hidden name="id" />

          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
              {
                min: 3,
                message: "Your name should have at least 3 characters!",
              },
            ]}
            style={{ marginBottom: "10px" }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
              {
                min: 3,
                message: "Your name should have at least 3 characters!",
              },
            ]}
            style={{ marginBottom: "10px" }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input a valid email address!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              { required: true, message: "Please input a valid phone number!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={setShowEditProfile.bind(null, false)}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Col>
  );
};

export default EditMyAccount;
