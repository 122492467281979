import { Card, Space, Tag, Typography } from "antd";
import { ContentTitle } from "../ContentTitle";
import { conditionStatus, conditionStatusColors } from "./conditionStatus";

const AboutCondition = ({ conditionResultLevel }: any) => {
  const RiskTag = ({ color }: { color: string }) => (
    <div style={{ width: "12px", height: "12px", backgroundColor: color, borderRadius: "2px" }}></div>
  );

  const risks = [
    {
      title: "High",
      color: "#DB0000"
    },
    {
      title: "Moderate",
      color: "#F48842"
    },
    {
      title: "Low",
      color: "#018806"
    },
    {
      title: "Unknown",
      color: "#4285F4"
    }
  ];

  return (
    <>
      <Card title={ContentTitle("About")} size="small">
        <Typography.Text type="secondary">Status</Typography.Text>
        <Typography.Paragraph>
          <Tag color={conditionStatusColors[conditionResultLevel]}>{conditionStatus[conditionResultLevel]}</Tag>
        </Typography.Paragraph>
        <Typography.Text type="secondary">Risk Types:</Typography.Text>
        {risks.map((risk, key) => (
          <Typography.Paragraph key={key} style={{ margin: "0" }}>
            <Space>
              <RiskTag color={risk.color} />
              {risk.title}
            </Space>
          </Typography.Paragraph>
        ))}
      </Card>
    </>
  );
};

export default AboutCondition;
