import { useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import apiClient from "../../utils/apiClient";
import filterReducer from "../Navbar/filterReducer";

interface GetAdminsProperties {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: number;
  isDeleted: boolean;
}
interface Filters {
  query: string | null;
  networkId: string | null;
}

const useFetchAdmins = (path: string) => {
  const currentLocation: any = useLocation();
  const [data, setData] = useState<GetAdminsProperties[]>([]);
  const [loading, setLoading] = useState(true);
  const [adminsFilters, setAdminsFilters] = useState<Filters | null>(null);
  const history = useHistory();
  const [state, dispatch] = useReducer(filterReducer, {});

  useEffect(() => {
    if (currentLocation.state) {
      const state: any = currentLocation.state;

      dispatch({
        type: "UPDATE_BULK_FILTERS",
        paramCollection: {
          networkId: state.networkId,
          page: 1 + "",
        },
        history,
      });

      setAdminsFilters({
        query: "",
        networkId: currentLocation.state.networkId,
      });
    } else {
      const searchParams = new URLSearchParams(currentLocation.search);

      setAdminsFilters({
        query: searchParams.get("searchFilter"),
        networkId: searchParams.get("networkId"),
      });
    }
  }, [currentLocation]);

  const getData = useCallback(async () => {
    if (adminsFilters === null) return;

    try {
      const filters: any = {};

      if (adminsFilters.query) {
        Object.assign(filters, { searchFilter: adminsFilters.query });
      }
      if (adminsFilters.networkId) {
        Object.assign(filters, { networkId: adminsFilters.networkId });
      }

      const response = await apiClient.post(path, filters);

      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [adminsFilters]);

  useEffect(() => {
    getData();
  }, [getData]);

  // useEffect(() => {
  //   if (adminsFilters === null) return;

  //   const filters = {};
  //   if (adminsFilters.query) {
  //     Object.assign(filters, { searchFilter: adminsFilters.query });
  //   }

  //   if (adminsFilters.networkId) {
  //     Object.assign(filters, { networkId: adminsFilters.networkId });
  //   }

  //   apiClient
  //     .post(path, filters)
  //     .then((response) => {
  //       setData(response.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       if (err.response?.status === 403) {
  //         history.push("/dashboard/patients");
  //       }
  //     });
  // }, [history, path, adminsFilters]);

  return { data, setData, loading };
};

export default useFetchAdmins;
