import apiClient, { endpoints } from "../../../utils/apiClient"
import { Button, Modal, Tooltip, message } from "antd";
import { BackwardFilled, BackwardOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import useSolveOrder from '../useSolveOrder'
import { useHistory } from "react-router-dom";

interface SolveOrderProps{
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    setWarningData: React.Dispatch<any>
    patientOrderId: number
}

const SolveOrderAction = (props: SolveOrderProps) => {
   const { solveOrder} = useSolveOrder();
   const history = useHistory();

   const handleDeveloperStatus = () => {
        Modal.confirm({
            title: `Are you sure that the order ${props.patientOrderId} it's done now?`,
            icon: <ExclamationCircleOutlined />,
            okText: "Yes",
            okButtonProps: { danger: true },
            onOk: () => {
                props.setLoading(true);
                solveOrder(endpoints["solveStuckOrders"], {['patientOrderId'] : props.patientOrderId}, props.setLoading)
                .then(response =>{

                    props.setLoading(false);
                    if(response.isSuccessful === true){
                        message.success("Order resolved!");
                        history.push("/dashboard/admin-warnings")
                    }
                    else 
                    {
                        message.info("The order it's still stuck!");
                        props.setWarningData(response.stuckOrderDetails);
                    }
                })
            }
        });
    }
  
    return (
        <Tooltip placement="top" title={`Mark Order No. ${props.patientOrderId} as resolved`}>
            <Button style={{ margin: "23px"}} size = {'small'} onClick = {handleDeveloperStatus}>Solve Order</Button>
        </Tooltip>
    )};

export default SolveOrderAction;