import { DatePicker, Form, Space } from "antd";
import moment from "moment";
import { RouterProps, useLocation } from "react-router-dom";


const DateFilter = ({
  dispatch,
  history,
  isResponsive
}: {
  dispatch: React.Dispatch<any>;
  history: RouterProps["history"];
  isResponsive?: boolean;
}) => {
  const { RangePicker } = DatePicker;
  const searchParams = new URLSearchParams(useLocation().search);
  const startDate = searchParams?.get("startDate")?.toString();
  const endDate = searchParams?.get("endDate")?.toString();

  const handleDate = (dates: any, dateStrings: string[]) => {
    const [startDate, endDate] = dateStrings;

    dispatch({ type: "UPDATE_BULK_FILTERS", paramCollection: { startDate, endDate, page: 1 }, history });
  };


  const disabledDate = (current: any) => {
    var end = new Date();
    end.setDate(end.getDate() + 1);
    var dateString = moment(end).format('YYYY/MM/DD');
    if (current > moment(dateString)) {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <Space size="middle">
      <Form.Item name="date-filter" style={{ margin: isResponsive ? "10px 0 0" : "0" }}>
        <RangePicker
          ranges={{
            Today: [moment(), moment()],
            'Last Week': [moment().subtract(7, 'days'), moment()],
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 3 Months': [moment().subtract(3, 'months'), moment()],
            'Last 6 Months': [moment().subtract(6, 'months'), moment()],
            'Last Year': [moment().subtract(1, 'year'), moment()]
          }}
          defaultPickerValue={[moment().subtract(1, 'month'), moment()]}
          disabledDate={disabledDate}
          onChange={handleDate}
          format="MM/DD/YYYY"
          defaultValue={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
        />
      </Form.Item>
    </Space>
  );
};

export default DateFilter;
