import apiClient, { endpoints } from "../../utils/apiClient";

const useFetchPatientActions = () => {
    const updateDeveloperStatus = async (id: number | undefined) => {
        try {
            await apiClient.get(endpoints.updateDeveloperStatus, { params: { patientId: id } });
        } catch (error) {
            console.error(error);
        }
    };

    return { updateDeveloperStatus };
}

export default useFetchPatientActions;