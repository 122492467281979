import Icon, { ArrowDownOutlined, ArrowUpOutlined, CheckCircleFilled } from "@ant-design/icons";
import { Card, Col, Row, Statistic, Typography } from "antd";
import { ReactComponentElement, useContext, useEffect, useState } from "react";
import { RouteContext } from "../Dashboard";
import Spinner from "../Spinner";
import useFetchOverviews, { OverviewProperties } from "./useFetchOverviews";
import { ReactComponent as PatientIcon } from "../../assets/person.svg";
import { ReactComponent as SurveyIcon } from "../../assets/survey.svg";

interface HeadStatsProperties {
  title: string;
  value: number;
  prefix?: string;
  percentage: number;
  isPositive: boolean;
}

interface TotalCountDataProperties {
  title: string;
  value: number;
  recentValue?: number;
  icon: ReactComponentElement<any>;
}

const Overview = () => {
  const [headStats, setHeadStats] = useState<HeadStatsProperties[]>([]);
  const [totalCountData, setTotalCountData] = useState<TotalCountDataProperties[][]>([]);
  const { fetchOverviews, loading, numberOfDays } = useFetchOverviews();
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Dashboard Overview",
        path: "/overview"
      }
    ]);
    setCurrentKey("overview");
  }, [setBreadcrumbItems, setCurrentKey]);

  const checkIfPositive = (value: number) => value >= 0;

  useEffect(() => {
    fetchOverviews().then((overviews: OverviewProperties) => {
      setHeadStats([
        {
          title: "Recent Patients",
          value: overviews.recentPatientsCount,
          percentage: overviews.percentageIncreasePatients,
          isPositive: checkIfPositive(overviews.percentageIncreasePatients)
        },
        {
          title: "Recent Orders",
          value: overviews.recentOrdersCount,
          percentage: overviews.percentageIncreaseOrders,
          isPositive: checkIfPositive(overviews.percentageIncreaseOrders)
        },
        {
          title: "Recent Income",
          value: overviews.recentTotalIncome,
          prefix: "$",
          percentage: overviews.percentageIncreaseIncome,
          isPositive: checkIfPositive(overviews.percentageIncreaseIncome)
        }
      ]);

      setTotalCountData([
        [
          {
            title: "Total Patients",
            value: overviews.totalPatientsCount,
            icon: <Icon component={PatientIcon} />
          },
          {
            title: "Total Patients Deleted",
            value: overviews.totalDeletedPatientsCount,
            icon: <Icon component={PatientIcon} />
          },
          {
            title: "Total Patients Without Surveys",
            value: overviews.totalPatientsWithoutSurveys,
            icon: <Icon component={PatientIcon} />
          }
        ],
        [
          {
            title: "Total Screening Surveys",
            value: overviews.surveys.totalScreeningSurveysCount,
            recentValue: overviews.surveys.recentScreeningSurveysCount,
            icon: <Icon component={SurveyIcon} />
          },
          {
            title: "Total Lifestyle Surveys",
            value: overviews.surveys.totalLifestyleSurveysCount,
            recentValue: overviews.surveys.recentLifestyleSurveysCount,
            icon: <Icon component={SurveyIcon} />
          },
          {
            title: "Total Insights Surveys",
            value: overviews.surveys.totalInsightsSurveysCount,
            recentValue: overviews.surveys.recentInsightsSurveysCount,
            icon: <Icon component={SurveyIcon} />
          }
        ],
        [
          {
            title: "Generated Lifestyle Actions",
            value: overviews.actions.generatedLifestyleActionsCount,
            recentValue: overviews.actions.recentGeneratedLifestyleActionsCount,
            icon: <CheckCircleFilled />
          },
          {
            title: "Generated Awareness Actions",
            value: overviews.actions.generatedAwarenessActionsCount,
            recentValue: overviews.actions.recentGeneratedAwarenessActionsCount,
            icon: <CheckCircleFilled />
          },
          {
            title: "Generated Shop Actions",
            value: overviews.actions.generatedShopActionsCount,
            recentValue: overviews.actions.recentGeneratedShopActionsCount,
            icon: <CheckCircleFilled />
          }
        ],
        [
          {
            title: "Completed Lifestyle Actions",
            value: overviews.actions.completedLifestyleActionsCount,
            recentValue: overviews.actions.recentCompletedLifestyleActionsCount,
            icon: <CheckCircleFilled />
          },
          {
            title: "Completed Awareness Actions",
            value: overviews.actions.completedAwarenessActionsCount,
            recentValue: overviews.actions.recentCompletedAwarenessActionsCount,
            icon: <CheckCircleFilled />
          },
          {
            title: "Completed Shop Actions",
            value: overviews.actions.completedShopActionsCount,
            recentValue: overviews.actions.recentCompletedShopActionsCount,
            icon: <CheckCircleFilled />
          }
        ]
      ]);
    });
  }, [fetchOverviews]);

  const overviewDates: { [key: number]: string } = {
    7: "7 days",
    30: "30 days",
    90: "3 months"
  };

  return (
    <div style={{ margin: "0 30px" }}>
      {loading && !headStats.length ? (
        <Spinner />
      ) : (
        <>
          <Row gutter={15}>
            {headStats.map((stat, key) => (
              <Col
                key={key}
                xxl={{ span: 5 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ margin: "0 0 15px" }}
              >
                <Card>
                  <Statistic
                    title={stat.title}
                    value={stat.value}
                    prefix={stat.prefix}
                    loading={loading}
                    suffix={
                      <Typography.Text style={{ fontSize: "16px" }} type="secondary">
                        / {overviewDates[numberOfDays]}
                      </Typography.Text>
                    }
                  />

                  <Statistic
                    value={stat.percentage}
                    precision={2}
                    loading={loading}
                    valueStyle={{ fontSize: "16px", color: stat.isPositive ? "#3f8600" : "#cf1322" }}
                    prefix={stat.isPositive ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                    suffix="%"
                  />
                </Card>
              </Col>
            ))}
          </Row>

          {totalCountData.map((statgroup, key) => (
            <Row gutter={15} align="stretch" key={key}>
              {statgroup.map((stat, groupKey) => (
                <Col
                  xxl={{ span: 5 }}
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 8 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  key={groupKey}
                  style={{ margin: "0 0 15px" }}
                >
                  <Card style={{ height: "100%" }}>
                    <Statistic title={stat.title} value={stat.value} prefix={stat.icon} loading={loading} />
                    {!isNaN(Number(stat.recentValue)) && (
                      <Statistic
                        value={stat.recentValue}
                        loading={loading}
                        suffix={
                          <Typography.Text style={{ fontSize: "16px" }} type="secondary">
                            / {numberOfDays} days
                          </Typography.Text>
                        }
                      />
                    )}
                  </Card>
                </Col>
              ))}
            </Row>
          ))}
        </>
      )}
    </div>
  );
};

export default Overview;
