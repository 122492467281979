import axios from "axios";
import { showErrorNotification } from "./utils";

export const endpoints = {
  twoFactorLogin: "/auth/two-factor-login",
  twoFactorValidate: "/auth/two-factor-validate",
  logout: "/auth/logout",
  createAdmin: "/auth/create-admin",
  changePassword: "/auth/change-password",
  purchases: "/finances/purchases",
  downloadReport: "/finances/download-report",
  downloadConsultationsReport: "/finances/download-consultations-report",
  patients: "/patients",
  patientDetails: "/patients/id",
  patientsDownloadReport: "/patients/download-report",
  patientsSurveys: "/patients/surveys",
  patientsSurveysData: "/patients/data-source",
  patientsOrders: "/patients/orders",
  patientsOrdersData: "/patients/order-detail",
  patientsFailedPurchasesData: "/patients/failed-purchase-details",
  patientsConditions: "/patients/conditions",
  patientsConditionsData: "/patients/condition-details",
  patientsActions: "/patients/actions",
  patientOrderFile: "/patients/download-document",
  patientManualVariables: "/patients/manually-submitted-variables",
  getAdmins: "admins/get-admins",
  viewProfile: "admins/view-profile",
  editProfile: "admins/edit-profile",
  changeAdminPassword: "/admins/change-admin-password",
  deleteAdmin: "/admins/delete-admin",
  selfProfile: "/admins/self-profile",
  editSelfProfile: "/admins/edit-self-profile",
  getTokenStatus: "/auth/get-token-status",
  extractVariables: "/lab-data/extract-variables",
  submitLabData: "/lab-data/submit",
  variablesHistory: "/lab-data/variables-history",
  deleteVariables: "/lab-data/delete-variables",
  uploadRequisitionPdf: "/orders/upload-requisition-pdf",
  uploadLabResultsPdf: "/orders/upload-lab-results-pdf",
  submitLabResults: "/orders/submit-lab-results",
  deleteOrderVariables: "/orders/delete-variables",
  editOrderVariables: "/orders/edit-variables",
  orderVariablesHistory: "/orders/variables-history",
  stuckOrders: "/orders/stuck-orders",
  solveStuckOrders: "/orders/unstuck-order",
  detailedStuckOrder: "/orders/detailed-stuck-order",
  clinicErrorsCount: "ls-errors/count",
  activityLogs: "/activity-logs/general-filter",
  activityLogsExcerpt: "/activity-logs/patient-filter",
  searchAdmins: "/activity-logs/search-admins",
  searchPatients: "/activity-logs/search-patients",
  adminEmail: "/activity-logs/admin-email",
  patientEmail: "/activity-logs/patient-email",
  labPanels: "/shop-offers/lab-panels",
  labPanelsDetails: "/shop-offers/details",
  editShopOffers: "/shop-offers",
  dashboardOverview: "/dashboard/overview",
  updateDeveloperStatus: "/patients/update-developer-status",
  networks: "/networks",
  consultationOrders: "/consultation-orders/filtered",
  consultationsPurchasedCount: "/networks/consultations-purchased-count",
  consultationsHistory: "/patients/consultations",
};

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      // include auth header for future requests
      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    const errorMessage =
      error.response?.data?.Message ||
      error.response?.data?.message ||
      error.response?.data?.status;

    switch (error.response?.status) {
      case 401: {
        showErrorNotification(errorMessage || "Error!", true);
        break;
      }
      case 403: {
        showErrorNotification(
          errorMessage || "Unauthorized to access this resource!"
        );
        break;
      }
      default: {
        showErrorNotification(
          errorMessage || "Error!",
          error.response?.data?.needsLogin
        );
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
