import { Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { endpoints } from "../../utils/apiClient";
import { RouteContext } from "../Dashboard";
import useFetchPatientProfile from "../PatientProfile/useFetchPatientProfile";
import Spinner from "../Spinner";
import FailedPurchaseDetails from "./FailedPurchaseDetails";
import FailedPurchaseStatus from "./FailedPurchaseStatus";

export interface FailedPurchaseProperties {
    orderStatus: number;
    transactionStatus: number;
    rawStatus: string;
    amount: string;
    patientPurchaseItems: { labPackageName: string; totalPrice: string }[];
    createdAt: string;
}

const FailedPurchaseProfile = () => {
    const { transactionId } = useParams<{ transactionId: string }>();
    const [patientId, setPatientId] = useState("");
    const [breadcrumbTitle, setBreadcrumTitle] = useState("Failed Purchase Profile");
    const [data, setData] = useState<FailedPurchaseProperties>({} as FailedPurchaseProperties);
    const [patientName, setPatientName] = useState("");
    const { getConditions, loading } = useFetchPatientProfile();
    const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);

    useEffect(() => {
        setBreadcrumbItems([
            {
                title: "Patients Data",
                path: "/patients"
            },
            {
                title: patientName || "Patient Profile",
                path: `/patient-profile/${patientId}`
            },
            {
                title: breadcrumbTitle,
                path: `/failed-purchase-profile/${transactionId}`
            }
        ]);
        setCurrentKey("patients");
    }, [breadcrumbTitle, transactionId, patientId, patientName, setBreadcrumbItems, setCurrentKey]);


    useEffect(() => {
        getConditions(endpoints.patientsFailedPurchasesData, transactionId).then(responseData => {
            if (!responseData) return;

            setData(responseData);
            setPatientId(responseData.patientId);

            setBreadcrumTitle("Purchase no. " + responseData.purchaseId);

            if (!responseData.patientId) return;
            getConditions(endpoints.patientDetails, responseData.patientId).then(response => {
                response.firstName && response.lastName && setPatientName(`${response.firstName} ${response.lastName}`);
            });
        });
    }, [getConditions, transactionId]);

    return (
        <>
            <div style={{ margin: "0 30px" }}>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <Row align="stretch" gutter={15}>
                            <FailedPurchaseStatus data={data} />
                            <FailedPurchaseDetails data={data} />
                        </Row>
                    </>
                )}
            </div>
        </>
    );
};

export default FailedPurchaseProfile;
