import { createContext } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import LoginPage from "./features/Login";
import DashboardPage from "./features/Dashboard";
import useAuth, { AuthProperties } from "./features/Login/useAuth";
import "./App.css";

export const AuthContext = createContext<AuthProperties>({} as AuthProperties);

function App() {
  const token = localStorage.getItem("token");
  const auth = useAuth();

  return (
    <AuthContext.Provider value={auth}>
      <Router>
        {auth.user || token ? (
          <>
            <Switch>
               <Route path="/dashboard">
                <DashboardPage />
              </Route>
              <Route path="/">
                <Redirect
                  to={{
                    pathname: "/dashboard"
                  }}
                />
              </Route>
            </Switch>
          </>
        ) : (
          <>
            <Route path="/">
              <Redirect
                to={{
                  pathname: "/login"
                }}
              />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
          </>
        )}
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
