import { CalendarOutlined, DeleteFilled, EditFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Input,
  Popconfirm,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { endpoints } from "../../utils/apiClient";
import { formatDate } from "../../utils/utils";
import Spinner from "../Spinner";
import useFetchLabData from "./useFetchLabData";

interface PatientVariablesProperties {
  id: number;
  name: string;
  value: string | number | undefined;
  rawValue: string;
  measurementUnit: string;
  isDeleted?: boolean;
  originalValue?: string | number | boolean | undefined;
  lowerLimitValidation: number;
  upperLimitValidation: number;
}

export interface HistoryVariablesProperties {
  sampleCollectedAt?: moment.Moment | Date | undefined | string;
  reviewedBy?: number;
  date: string | number | Date;
  data: PatientVariablesProperties[];
  patientVariables?: PatientVariablesProperties[];
  manualLabsInputs?: { [key: string]: PatientVariablesProperties[] };
}

export interface ItemsToEditProperty {
  patientVariableId: number;
  editedValue: string | number | undefined;
}

const VariablesHistory = ({
  id,
  orderId,
}: {
  id: string | undefined;
  orderId: string | undefined;
}) => {
  const [historyVariables, setHistoryVariables] = useState<
    HistoryVariablesProperties[]
  >([]);
  const [itemsToDelete, setItemsToDelete] = useState<number[]>([]);
  const [itemsToEdit, setItemsToEdit] = useState<ItemsToEditProperty[]>([]);
  const [editNewValue, setEditNewValue] = useState<
    string | number | undefined
  >();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("Input a number!");
  const [lowerLimit, setLowerLimit] = useState(0);
  const [upperLimit, setUpperLimit] = useState(999999);
  const { getLabVariables, postLabData, deleteLabData, loading, setLoading } =
    useFetchLabData();

  const prepareData = (data: HistoryVariablesProperties) => {
    const dataArr = [];

    if (orderId) {
      "patientVariables" in data &&
        data.patientVariables &&
        data?.patientVariables.length &&
        dataArr.push({
          date: formatDate(data?.sampleCollectedAt as string),
          data: data?.patientVariables,
        });
    } else {
      for (const prop in data.manualLabsInputs) {
        dataArr.push({
          date: formatDate(prop),
          data: data.manualLabsInputs[prop],
        });
      }
    }

    setHistoryVariables(dataArr);
  };

  useEffect(() => {
    if (orderId) {
      getLabVariables(endpoints.orderVariablesHistory, {
        patientOrderId: orderId,
      }).then((response) => prepareData(response));
    } else {
      getLabVariables(endpoints.variablesHistory, { patientId: id }).then(
        (response) => prepareData(response)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (variableIndex: number, labIndex: number) => {
    const copyArr = [...historyVariables];
    const itemId = copyArr[variableIndex].data[labIndex].id;
    const isDeleted = copyArr[variableIndex].data[labIndex].isDeleted;

    copyArr[variableIndex].data[labIndex].isDeleted = !isDeleted;
    setHistoryVariables(copyArr);

    setItemsToDelete(
      itemsToDelete.includes(itemId)
        ? itemsToDelete.filter((item) => item !== itemId)
        : [...itemsToDelete, itemId]
    );
  };

  const handleChanges = async () => {
    setLoading(true);

    if (itemsToDelete.length) {
      const response = await deleteLabData(
        endpoints[orderId ? "deleteOrderVariables" : "deleteVariables"],
        orderId
          ? {
              patientId: id,
              patientOrderId: orderId,
              patientVariableIds: itemsToDelete,
            }
          : { patientId: id, patientVariableIds: itemsToDelete }
      );

      if (response) {
        const filteredArr = historyVariables.filter((variable, index) => {
          const dataArr = variable.data.filter(
            (data) => !itemsToDelete.includes(data.id)
          );
          if (dataArr.length) {
            variable.data = dataArr;
          }

          return dataArr.length;
        });

        setHistoryVariables(filteredArr);
        setItemsToDelete([]);
      }
    }

    if (itemsToEdit.length) {
      const response = await postLabData(endpoints.editOrderVariables, {
        patientId: id,
        patientOrderId: orderId,
        editedPatientVariables: itemsToEdit,
      });

      if (response) {
        setItemsToEdit([]);
      }
    }
  };

  const handleEdit = (orderIndex: number) => {
    const copyArr = [...historyVariables][0];

    const patientVariableId = copyArr.data[orderIndex].id;
    const originalValue = copyArr.data[orderIndex].value;

    copyArr.data[orderIndex]["originalValue"] =
      originalValue === editNewValue ? false : originalValue;
    copyArr.data[orderIndex].value = editNewValue;

    setHistoryVariables([copyArr]);

    let currentIndex = -1;
    itemsToEdit.forEach((item, index) => {
      if (item.patientVariableId === patientVariableId) {
        currentIndex = index;
      }
    });

    if (itemsToEdit[currentIndex]) {
      setItemsToEdit(
        itemsToEdit.map((item, index) =>
          index === currentIndex ? { ...item, editedValue: editNewValue } : item
        )
      );
    } else {
      setItemsToEdit([
        ...itemsToEdit,
        { patientVariableId, editedValue: editNewValue },
      ]);
    }

    setEditNewValue(undefined);
  };

  const handleSelect = (value: string, option: any) => {
    handleLimitValidation(
      option.data.lowerLimitValidation,
      option.data.upperLimitValidation
    );
  };

  const handleLimitValidation = (
    lowerLimitValidation: number,
    upperLimitValidation: number
  ) => {
    setLowerLimit(lowerLimitValidation || 0);
    setUpperLimit(upperLimitValidation || 999999);
    setTooltipTitle(
      !isNaN(lowerLimitValidation) && !isNaN(upperLimitValidation)
        ? `Input a number between ${lowerLimitValidation} and ${upperLimitValidation}`
        : "Input a number!"
    );
  };

  return (
    <>
      <Space style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="primary"
          disabled={!itemsToDelete.length && !itemsToEdit.length}
          onClick={handleChanges}
          loading={loading}
        >
          Save Changes
        </Button>
      </Space>

      {loading && !historyVariables.length ? (
        <Spinner />
      ) : (
        <>
          {historyVariables.map((variable, i) => (
            <Card
              size="small"
              key={i}
              title={
                <Typography.Text>
                  <Space>
                    <CalendarOutlined />
                    {variable.date}
                  </Space>
                </Typography.Text>
              }
              style={{ margin: "30px 0 0" }}
            >
              {variable?.data &&
                variable?.data.map((data, j) => (
                  <Card
                    size="small"
                    key={j}
                    bodyStyle={{
                      backgroundColor: data.isDeleted ? "#EFEFEF" : "#fff",
                    }}
                    style={{ margin: "10px 0 0", borderRadius: "5px" }}
                  >
                    <Space
                      style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ height: "100%" }}>
                        <Typography.Paragraph strong>
                          {data.name}
                        </Typography.Paragraph>
                        <Typography.Paragraph style={{ margin: "0" }}>
                          <Typography.Text
                            strong
                            style={{
                              marginRight: "3px",
                              color:
                                orderId &&
                                data.originalValue &&
                                itemsToEdit.length
                                  ? "#389e0d"
                                  : "",
                            }}
                          >
                            {data.value}
                          </Typography.Text>
                          <Typography.Text
                            type="secondary"
                            style={{
                              color:
                                orderId &&
                                data.originalValue &&
                                itemsToEdit.length
                                  ? "#389e0d"
                                  : "",
                            }}
                          >
                            {data.measurementUnit}
                          </Typography.Text>

                          {(orderId &&
                            data.originalValue &&
                            itemsToEdit.length && (
                              <Tag
                                color="green"
                                style={{ margin: "0 3px 0 15px" }}
                              >
                                <Typography.Text>
                                  Previous value:
                                  <Typography.Text
                                    strong
                                    style={{ margin: "0 0 0 5px" }}
                                  >
                                    {data.originalValue}
                                  </Typography.Text>
                                </Typography.Text>
                              </Tag>
                            )) ||
                            ""}
                        </Typography.Paragraph>
                      </div>

                      <div style={{ height: "100%" }}>
                        <Divider type="vertical" style={{ height: "100%" }} />

                        {orderId && (
                          <Popconfirm
                            title={
                              <>
                                <Typography.Paragraph>
                                  Input new value:
                                </Typography.Paragraph>
                                <Tooltip
                                  visible={tooltipVisible}
                                  title={tooltipTitle}
                                  placement="left"
                                >
                                  <Input
                                    allowClear
                                    size="small"
                                    value={editNewValue}
                                    onBlur={() => {
                                      setTooltipVisible(false);
                                    }}
                                    onFocus={() => setTooltipVisible(true)}
                                    onChange={(e) =>
                                      setEditNewValue(
                                        isNaN(Number(e.target.value))
                                          ? editNewValue
                                          : e.target.value
                                      )
                                    }
                                  />
                                </Tooltip>
                              </>
                            }
                            onConfirm={handleEdit.bind(null, j)}
                            onCancel={() => {
                              setEditNewValue(undefined);
                            }}
                            okText="Modify"
                            cancelText="Cancel"
                          >
                            <Tooltip title={"Edit variable!"} placement="left">
                              <Button
                                type="text"
                                onClick={() => {
                                  handleLimitValidation(
                                    data.lowerLimitValidation,
                                    data.upperLimitValidation
                                  );
                                }}
                              >
                                <EditFilled
                                  style={{ color: "#4285F4", fontSize: "18px" }}
                                />
                              </Button>
                            </Tooltip>
                          </Popconfirm>
                        )}

                        <Tooltip
                          title={
                            data.isDeleted ? "Undo delete!" : "Delete variable!"
                          }
                        >
                          <Button
                            type="text"
                            onClick={handleDelete.bind(null, i, j)}
                          >
                            <DeleteFilled
                              style={{
                                color: data.isDeleted ? "#23232B" : "#FF4B4B",
                                fontSize: "18px",
                              }}
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    </Space>
                  </Card>
                ))}
            </Card>
          ))}
        </>
      )}

      {!historyVariables.length && !loading && (
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          No data available!
        </Typography.Title>
      )}
    </>
  );
};

export default VariablesHistory;
