import { notification } from "antd";

export const formatDate = (date: string | number | Date, includeTime = false): string | number | Date => {
  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric"
  } as const;

  if (includeTime) {
    Object.assign(options, { hour: "numeric", minute: "numeric" });
  }

  return new Date(date).toLocaleDateString("en-US", options);
};

export const showErrorNotification = (message = "Something went wrong!", reload = false) => {
  if (reload) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    localStorage.setItem("loginError", message);
    return window.location.reload();
  }

  notification.error({
    message: "Error!",
    description: message,
    duration: 7
  });
};

export const generateUUID = () => {
  let u = "",
    i = 0;
  while (i++ < 36) {
    const c = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"[i - 1],
      r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    u += c === "-" || c === "4" ? c : v.toString(16);
  }
  return u;
};
