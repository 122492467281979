import { useCallback, useState } from "react";
import apiClient, { endpoints } from "../../utils/apiClient";

const useShopOffers = () => {
  const [loading, setLoading] = useState(true);

  const getOffers = useCallback((packageType?: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const params = {};
      if (packageType) {
        Object.assign(params, { packageType });
      }
      apiClient
        .get(packageType ? endpoints.labPanelsDetails : endpoints.labPanels, { params })
        .then(response => {
          if (response.status === 200) {
            resolve(response.data);
          }
        })
        .then(() => setLoading(false));
    });
  }, []);

  const editOffer = (data: any): Promise<any> => {
    setLoading(true);

    return new Promise((resolve, reject) => {
      apiClient
        .post(endpoints.editShopOffers, { ...data })
        .then(response => {
          if (response.status === 200) {
            resolve(true);
          }
        })
        .then(() => setLoading(false));
    });
  };

  return { getOffers, editOffer, loading };
};

export default useShopOffers;
