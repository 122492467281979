import { Button, Card, Col, Modal, Space, Tag, Typography } from "antd";
import { Link, useParams } from "react-router-dom";
import { OrderProfileProperties, TrackingNumberInput } from ".";
import { formatDate } from "../../utils/utils";
import { ContentTitle } from "../ContentTitle";
import {
  newOrderStatus,
  orderStatus,
  orderStatusColors,
  transactionStatus,
  transactionStatusColors,
} from "../PatientProfile/orderStatus";
import { EditFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import apiClient from "../../utils/apiClient";

const OrderStatus = ({
  data,
  setData,
}: {
  data: OrderProfileProperties;
  setData?: (val: OrderProfileProperties) => void;
}) => {
  const OrderReviewers: { [key: number]: string } = {
    1: "MyHealthPlan",
    2: "PWN",
    3: "LabSavvy",
  };

  const [secondTrackingInfo, setSecondTrackingInfo] = useState<string>(
    data.secondaryTrackingLink ?? ""
  );

  const [sendRequest, setSendRequest] = useState<boolean>(false);

  const { orderId } = useParams<{ orderId: string }>();

  const orderStatusArr = [
    {
      title: "Order Status",
      date: formatDate(data?.orderStatusDate),
      status: data?.isAnOrderAssociatedWithBoston
        ? newOrderStatus[data?.orderStatus]
        : orderStatus[data?.orderStatus],
      color: orderStatusColors[data?.orderStatus],
    },
    {
      title: "Transaction Status",
      date: formatDate(data?.transactionDate),
      status: transactionStatus[data?.transactionStatus],
      color: transactionStatusColors[data?.transactionStatus],
    },
    {
      title: "",
      date: "Reviewed By",
      status: OrderReviewers?.[data?.reviewedBy] || "Unknown",
      color: [1, 2, 3].includes(data?.reviewedBy) ? "blue" : "volcano",
    },
    {
      title: "Boston Clinic ID",
      date: data.bostonHeartlabClinicId,
    },
  ];

  const handleEditTrackingNumber = () => {
    Modal.confirm({
      title: "Edit Secondary Tracking Number",
      content: (
        <TrackingNumberInput
          val={secondTrackingInfo}
          setVal={setSecondTrackingInfo}
          title="Edit Secondary Tracking Number"
        />
      ),
      okText: "Ok",
      cancelText: "Cancel",
      onOk() {
        setSendRequest(true);
      },
    });
  };

  useEffect(() => {
    if (sendRequest) {
      apiClient
        .put("/orders/update-secondary-tracking-link", {
          patientOrderId: orderId,
          trackingLink: secondTrackingInfo,
        })
        .then(() => {
          setData?.({ ...data, secondaryTrackingLink: secondTrackingInfo });
          console.log(secondTrackingInfo);
        })
        .catch((err) => {
          console.log("Update tracking number endpoint crashed: ", err);
        })
        .finally(() => {
          setSendRequest(false);
        });
    }
  }, [sendRequest]);

  return (
    <Col
      xxl={{ span: 8 }}
      xl={{ span: 8 }}
      lg={{ span: 8 }}
      md={{ span: 24 }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
    >
      <Card title={ContentTitle("Lab Order Status")} size="small">
        {orderStatusArr.map((order, key) => {
          if (order.date) {
            return (
              <div key={key}>
                <Typography.Text type="secondary" style={{ fontSize: "14px" }}>
                  {order.title}
                </Typography.Text>
                <Typography.Paragraph strong style={{ fontSize: "13px" }}>
                  <Space style={{ fontSize: "13px" }} align="center">
                    {order.date}
                    {order.status && (
                      <>
                        <div
                          style={{
                            width: "4px",
                            height: "4px",
                            backgroundColor: "#000",
                            borderRadius: "50%",
                          }}
                        ></div>
                        <Tag color={order.color}>{order.status}</Tag>
                      </>
                    )}
                  </Space>
                </Typography.Paragraph>
              </div>
            );
          } else return null;
        })}
        <div>
          <Typography.Text type="secondary" style={{ fontSize: "14px" }}>
            Tracking Information
          </Typography.Text>
          <Typography.Paragraph strong style={{ fontSize: "13px" }}>
            {data.trackingLink ? (
              <a
                href={
                  data.trackingLink.startsWith("https://")
                    ? data.trackingLink
                    : "https://" + data.trackingLink
                }
                target="_blank"
              >
                {data.trackingLink}
              </a>
            ) : (
              "-"
            )}
          </Typography.Paragraph>
        </div>
        <div>
          <Typography.Text type="secondary" style={{ fontSize: "14px" }}>
            Secondary Tracking Information
          </Typography.Text>
          <Typography.Paragraph strong style={{ fontSize: "13px" }}>
            {data.secondaryTrackingLink ? (
              <a
                href={
                  data.secondaryTrackingLink.startsWith("https://")
                    ? data.secondaryTrackingLink
                    : "https://" + data.secondaryTrackingLink
                }
                target="_blank"
              >
                {data.secondaryTrackingLink}
              </a>
            ) : (
              "-"
            )}
            {[11, 14, 16].includes(data.orderStatus) && (
              <Button
                type="text"
                onClick={handleEditTrackingNumber}
                style={{ position: "absolute", right: 0 }}
              >
                <EditFilled style={{ color: "#ACACAC", fontSize: "17px" }} />
              </Button>
            )}
          </Typography.Paragraph>
        </div>
      </Card>
    </Col>
  );
};

export default OrderStatus;
