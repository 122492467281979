import { useState } from "react"
import apiClient, { endpoints } from "../../../utils/apiClient"


export const useWarningDetails = () => {

    const [loading, setLoading] = useState<boolean>(false)
    const [warningData, setWarningData] = useState<any>({})
    const [testData, setTestData] = useState<any>()

    const getWarningDetails = async (patientOrderId: string) => {
        setLoading(true)
        try {
            const response = await apiClient.get(endpoints.detailedStuckOrder, {
                params: {
                    patientOrderId: patientOrderId
                }
            })
            setWarningData(response.data)
        }
        catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const testWarningDetails = async () => {
        setLoading(true)
        try {
            const response = await apiClient.get("/debugging/fake-stuck-order")
            setTestData(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    return {
        warningData,
        setWarningData,
        testData,
        setTestData,
        getWarningDetails,
        testWarningDetails,
        loading,
        setLoading,
    }
}