import { Card, Col, List } from "antd";
import { ContentTitle } from "../ContentTitle";
import { OrderProfileProperties } from ".";
import FileDownload from "./FileDownload";
import { DownloadReqForm } from "./DownloadReqForm";
import { isNull } from "lodash";

const OrderFiles = ({ data }: { data: OrderProfileProperties }) => {
  return (
    <Col
      xxl={{ span: 8 }}
      xl={{ span: 8 }}
      lg={{ span: 8 }}
      md={{ span: 24 }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
    >
      <Card
        title={ContentTitle("Order Files")}
        bodyStyle={{
          paddingBottom: "0",
          maxHeight: "250px",
          overflow: "hidden",
          overflowY: "auto",
        }}
        size="small"
      >
        <List
          dataSource={
            data?.isAnOrderAssociatedWithBoston && !isNull(data!.bostonHeartlabClinicId)
              ? [...data?.orderFiles, "requisition"]
              : data?.orderFiles
          }
          renderItem={(item) =>
            item === "requisition" ? (
              <DownloadReqForm title={data.bostonReqFormName} />
            ) : (
              <FileDownload item={item} />
            )
          }
        />
      </Card>
    </Col>
  );
};

export default OrderFiles;
