import { Form, Select, Space, Tooltip } from "antd";
import { useEffect, useContext, useState } from "react";
import { RouterProps } from "react-router-dom";
import { RouteContext } from "../Dashboard";
import { FilterProps } from "../Dashboard";
import { useLocation } from "react-router-dom";
import {
  processItems,
  processOrderStatuses,
  processTransactionStatuses,
} from "./filterLogic";

const ConsultationsFilters = ({
  dispatch,
  history,
  isResponsive,
}: {
  dispatch: React.Dispatch<any>;
  history: RouterProps["history"];
  isResponsive?: boolean;
}) => {
  const { filters } = useContext(RouteContext);
  const searchParams = new URLSearchParams(useLocation().search);

  const [transactionStatuses, setTransactionStatuses] = useState<string[]>([
    "Failed",
    "Pending",
    "Succeeded",
    "AmountMissmatch",
    "Cancelled",
  ]);
  const [transactionOptions, setTransactionOptions] = useState<any>();

  const [orderStatuses, setOrderStatuses] = useState<string[]>([
    "ConsultationPurchased",
    "CustomerContacted",
    "ConsultCompleted",
  ]);
  const [orderOptions, setOrderOptions] = useState<any>();

  useEffect(() => {
    console.log("👀 ", filters);

    if (filters?.orderStatus === null || filters?.orderStatus === undefined)
      fillTransactionInitialData();

    if (
      filters?.transactionStatus === null ||
      filters?.transactionStatus === undefined
    )
      fillOrderInitialData();

    if (filters?.transactionStatus) fillOrderInitialData();

    if (filters?.orderStatus) fillTransactionInitialData();
  }, [filters?.orderStatus, filters?.transactionStatus]);

  const fillTransactionInitialData = () => {
    const newTransactionStatuses = [
      "Failed",
      "Pending",
      "Succeeded",
      "AmountMissmatch",
      "Cancelled",
    ];
    setTransactionStatuses(newTransactionStatuses);
    setTransactionOptions(processItems(newTransactionStatuses));
  };

  const fillOrderInitialData = () => {
    const newOrdersStatuses = [
      "ConsultationPurchased",
      "CustomerContacted",
      "ConsultCompleted",
    ];
    setOrderStatuses(newOrdersStatuses);
    setOrderOptions(processItems(newOrdersStatuses));
  };

  const handleTransactionStatusSelect = (value: string) => {
    dispatch({
      type: "UPDATE_BULK_FILTERS",
      paramCollection: { transactionStatus: value, page: 1 },
      history,
    });
  };

  const handleOrderStatusSelect = (value: string) => {
    dispatch({
      type: "UPDATE_BULK_FILTERS",
      paramCollection: { orderStatus: value, page: 1 },
      history,
    });
  };

  return (
    <Space style={{ display: isResponsive ? "block" : "inline-flex" }}>
      <Form.Item
        name="order-status-select"
        style={{ margin: isResponsive ? "10px 0" : "10px 0" }}
      >
        <Select
          defaultValue={searchParams.get("orderStatus")?.toString()}
          placeholder="Select Order Status"
          allowClear
          value={filters?.orderStatus}
          onChange={handleOrderStatusSelect}
        >
          {orderOptions}
        </Select>
      </Form.Item>

      <Form.Item
        name="transaction-status-select"
        style={{ margin: isResponsive ? "10px 0" : "0" }}
      >
        <Select
          defaultValue={searchParams.get("transactionStatus")?.toString()}
          placeholder="Select Transaction Status"
          allowClear
          value={filters?.transactionStatus}
          onChange={handleTransactionStatusSelect}
        >
          {transactionOptions}
        </Select>
      </Form.Item>
    </Space>
  );
};

export default ConsultationsFilters;
