import { Form, RadioChangeEvent, Select, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import { RouterProps } from "react-router-dom";
import Spinner from "../Spinner";
import apiClient, { endpoints } from "../../utils/apiClient";
import { SelectValue } from "antd/lib/select";
import useDownloadReport from "./useDownloadReport";
import { RouteContext } from "../Dashboard";

export const NetworksFilter = ({
  dispatch,
  history,
  isResponsive,
}: {
  dispatch: React.Dispatch<any>;
  history: RouterProps["history"];
  isResponsive?: boolean;
}) => {
  const [loading, setLoading] = useState(true);
  const [networks, setNetworks] = useState([]);
  const { filters } = useContext(RouteContext);

  useEffect(() => {
    apiClient
      .post(endpoints.networks, {
        skip: 0,
        take: 100,
        searchFilter: "",
      })
      .then((res) => {
        console.log("NETWORKS: ", res.data);

        setNetworks(
          res.data.networks.map((n: any) => {
            return { value: n.id, label: n.name };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error gettinf networks for filter: ", err);
      });
  }, []);

  const handleNetworkChange = (value: number[]) => {
    dispatch({
      type: "UPDATE_NETWORK_IDS",
      networkIds: value,
      history,
    });

    dispatch({
      type: "UPDATE_BULK_FILTERS",
      paramCollection: { page: 1 },
      history,
    });
  };

  if (loading) return <Spinner />;
  return (
    <Space style={{ display: isResponsive ? "block" : "inline-flex" }}>
      <Form.Item
        name="consultation-network-select"
        style={{ margin: isResponsive ? "10px 0" : "0" }}
      >
        <Select
          style={{ minWidth: 400, maxWidth: 500 }}
          mode="multiple"
          placeholder="Select Networks"
          allowClear
          value={filters?.networkIds}
          onChange={handleNetworkChange}
          options={networks}
        ></Select>
      </Form.Item>
    </Space>
  );
};
