export const orderStatus: { [key: number]: string } = {
  0: "Unknown",
  10: "Pending Approval",
  11: "Approved",
  12: "Rejected",
  13: "Cancelled",
  14: "Completed",
  15: "Incomplete",
  16: "Awaiting review",
  17: "Partial Results"
};

export const newOrderStatus: { [key: number]: string } = {
  0: "Unknown",
  10: "Order Created",
  11: "Lab Kit Shipped",
  12: "Rejected",
  13: "Cancelled",
  14: "Ready to view",
  15: "Incomplete",
  16: "Awaiting review",
  17: "Partial Results"
};

export const orderStatusColors: { [key: number]: string } = {
  0: "",
  10: "warning",
  11: "success",
  12: "danger",
  13: "danger",
  14: "success",
  15: "warning",
  16: "warning",
  17: "warning"
};

export const transactionStatus = ["Unknown", "Succeeded", "Pending", "Failed", "Amount Missmatch"];

export const transactionStatusColors = ["processing", "success", "warning", "error", "warning"];
