import { InfoCircleFilled } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";

const ContentTitle = (title: string) => (
  <Typography.Text strong style={{ textTransform: "uppercase" }}>
    {title}
  </Typography.Text>
);

const PriceContentTitle = () => <Typography.Text strong style={{ textTransform: "uppercase" }}>
  {"Price"}
  <Tooltip title="Prices may vary by lab location, these are for South Carolina labs!">
    <InfoCircleFilled style={{ marginLeft: "10px", color: "#ACACAC" }} />
  </Tooltip>
</Typography.Text>;

export { ContentTitle, PriceContentTitle };
