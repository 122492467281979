import { Form, Select, Space, Tooltip } from "antd";
import { useEffect, useContext, useState } from "react";
import { RouterProps } from "react-router-dom";
import { RouteContext } from "../Dashboard";
import { FilterProps } from "../Dashboard";
import { useLocation } from "react-router-dom";
import {
  processItems,
  processOrderStatuses,
  processTransactionStatuses,
} from "./filterLogic";

const FinancesFilters = ({
  dispatch,
  history,
  isResponsive,
}: {
  dispatch: React.Dispatch<any>;
  history: RouterProps["history"];
  isResponsive?: boolean;
}) => {
  const { filters } = useContext(RouteContext);
  const searchParams = new URLSearchParams(useLocation().search);

  const [transactionStatuses, setTransactionStatuses] = useState<string[]>([
    "Failed",
    "Pending",
    "Succeeded",
  ]);
  const [transactionOptions, setTransactionOptions] = useState<any>();

  const [orderStatuses, setOrderStatuses] = useState<string[]>([
    "Awaiting Review",
    "Approved",
    "Partial Results",
    "Completed",
  ]);
  const [orderOptions, setOrderOptions] = useState<any>();
  const [isOrderDisabled, setOrderDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (filters?.orderStatus === null || filters?.orderStatus === undefined)
      fillTransactionInitialData();

    if (
      filters?.transactionStatus === null ||
      filters?.transactionStatus === undefined
    )
      fillOrderInitialData();

    if (filters?.transactionStatus) updateOrderFilterOptions(filters);

    if (filters?.orderStatus) updateTransactionFilterOptions(filters);
  }, [filters?.orderStatus, filters?.transactionStatus]);

  const fillTransactionInitialData = () => {
    const newTransactionStatuses = ["Failed", "Pending", "Succeeded"];
    setTransactionStatuses(newTransactionStatuses);
    setTransactionOptions(processItems(newTransactionStatuses));
  };

  const fillOrderInitialData = () => {
    const newOrdersStatuses = [
      "Awaiting Review",
      "Approved",
      "Partial Results",
      "Completed",
    ];
    setOrderStatuses(newOrdersStatuses);
    setOrderOptions(processItems(newOrdersStatuses));

    setOrderDisabled(false);
  };

  const handleTransactionStatusSelect = (value: string) => {
    dispatch({
      type: "UPDATE_BULK_FILTERS",
      paramCollection: { transactionStatus: value, page: 1 },
      history,
    });
  };

  const handleOrderStatusSelect = (value: string) => {
    dispatch({
      type: "UPDATE_BULK_FILTERS",
      paramCollection: { orderStatus: value, page: 1 },
      history,
    });
  };

  function updateOrderFilterOptions(filters: FilterProps) {
    const newOrderStatuses = processOrderStatuses(filters.transactionStatus);
    setOrderStatuses(newOrderStatuses);
    setOrderOptions(processItems(newOrderStatuses));
    newOrderStatuses.length === 0
      ? setOrderDisabled(true)
      : setOrderDisabled(false);
  }

  function updateTransactionFilterOptions(filters: FilterProps) {
    const newTransactionStatuses = processTransactionStatuses(
      filters.orderStatus
    );
    setTransactionStatuses(newTransactionStatuses);
    setTransactionOptions(processItems(newTransactionStatuses));
  }

  return (
    <Space style={{ display: isResponsive ? "block" : "inline-flex" }}>
      <Form.Item
        name="order-status-select"
        style={{ margin: isResponsive ? "10px 0" : "0" }}
      >
        <Tooltip
          overlayInnerStyle={{ width: "182px", textAlign: "center" }}
          placement="bottom"
          title={`No order filters for transaction status: ${filters?.transactionStatus}`}
          visible={isOrderDisabled}
        >
          <Select
            defaultValue={searchParams.get("orderStatus")?.toString()}
            placeholder="Select Order Status"
            allowClear
            value={filters?.orderStatus}
            onChange={handleOrderStatusSelect}
            disabled={isOrderDisabled}
          >
            {orderOptions}
          </Select>
        </Tooltip>
      </Form.Item>

      <Form.Item
        name="transaction-status-select"
        style={{ margin: isResponsive ? "10px 0" : "0" }}
      >
        <Select
          defaultValue={searchParams.get("transactionStatus")?.toString()}
          placeholder="Select Transaction Status"
          allowClear
          value={filters?.transactionStatus}
          onChange={handleTransactionStatusSelect}
        >
          {transactionOptions}
        </Select>
      </Form.Item>
    </Space>
  );
};

export default FinancesFilters;
