import { useCallback, useState } from "react";
import apiClient, { endpoints } from "../../utils/apiClient";
import { NetworksTableProperties } from "../NetworksTable/types";

interface ParamProperties {
  patientId?: string;
  patientConditionId?: string;
  patientDataSourceId?: string;
  patientOrderId?: string;
  patientPurchaseId?: string;
}

const useFetchPatientProfile = () => {
  const [loading, setLoading] = useState(true);
  const [networks, setNetworks] = useState<NetworksTableProperties[]>([]);
  const [patientNetworks, setPatientNetworks] = useState<
    { networkName: string; networkId: number }[]
  >([]);

  const getConditions = useCallback((path, id): Promise<any> => {
    return new Promise((resolve) => {
      let params: ParamProperties = { patientId: id };
      if (path.includes("condition-details")) {
        params = { patientConditionId: id };
      }
      if (path.includes("data-source")) {
        params = { patientDataSourceId: id };
      }
      if (path.includes("order-detail")) {
        params = { patientOrderId: id };
      }
      if (path.includes("failed-purchase-details")) {
        params = { patientPurchaseId: id };
      }

      apiClient
        .get(path, { params })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          }
        })
        .then(() => {
          setLoading(false);
        });
    });
  }, []);

  const getManualVariables = async (
    patientId: number,
    dataSourceType: number
  ) => {
    try {
      setLoading(true);
      return apiClient
        .get(endpoints.patientManualVariables, {
          params: {
            patientId: patientId,
            dataSourceType: dataSourceType,
          },
        })
        .then((response) => {
          setLoading(false);
          return response;
        })
        .then((response) => {
          return response.data;
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getNetworks = () => {
    setLoading(true);
    apiClient
      .post("/networks", {
        skip: 0,
        take: 99999,
        searchFilter: "",
      })
      .then((res) => {
        setNetworks(res.data.networks);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPatientNetworks = (id: number) => {
    setLoading(true);
    apiClient
      .get(`/networks/patient/${id}`)
      .then((res) => {
        setPatientNetworks(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    getConditions,
    getManualVariables,
    networks,
    setNetworks,
    getNetworks,
    patientNetworks,
    setPatientNetworks,
    getPatientNetworks,
  };
};

export default useFetchPatientProfile;
