import { useState, useEffect } from "react";
import { Modal, Switch, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import usePatientActions from "./usePatientActions";
import { PatientsTableProperties } from "../PatientsTable";

const DeveloperStatus = ({ patient }: { patient: PatientsTableProperties }) => {
    const { updateDeveloperStatus } = usePatientActions();
    const [isDeveloper, setIsDeveloper] = useState(patient.isDeveloper);

    useEffect(() => {
        setIsDeveloper(patient.isDeveloper);
    }, [patient.id]);


    const handleDeveloperStatus = () => {
        Modal.confirm({
            title: `Are you sure you want to change ${patient.firstName}'s ${isDeveloper ? "developer" : "user"}  status?`,
            icon: <ExclamationCircleOutlined />,
            okText: "Change",
            okButtonProps: { danger: true },
            onOk: () => {
                updateDeveloperStatus(patient?.id);
                setIsDeveloper(!isDeveloper)
            }
        });
    };

    return (
        <Tooltip placement="left" title={`Change ${patient.firstName}'s ${isDeveloper ? "developer" : "user"}  status`}>
            <Switch checkedChildren="USR" unCheckedChildren="DEV" size="small" checked={!isDeveloper} onChange={handleDeveloperStatus} />
        </Tooltip>)
}

export default DeveloperStatus;