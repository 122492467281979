import { Layout } from "antd";
import Brand from "./Brand";
import { useState, useEffect } from "react";
import SidebarList from "./SidebarList";
import { endpoints } from "../../utils/apiClient";
import useFetchSidebarData from "./useFetchSidebarData";
import Spinner from "../Spinner";

const Sidebar = () => {
  const { Sider } = Layout;
  const {
    loading,
    setLoading,
    fetchWarningsCount,
    fetchConsultationsPurchasedCount,
  } = useFetchSidebarData();
  const [existClinicErrors, setExistClinicErrors] = useState<number>(0);
  const [consultationsPurchasedCount, setConsultationsPurchasedCount] =
    useState<number>(0);

  useEffect(() => {
    setLoading(true);
    fetchWarningsCount(endpoints["clinicErrorsCount"])
      .then((response) => {
        if (
          response.clinicErrorsCount !== null &&
          response.clinicErrorsCount !== undefined
        )
          setExistClinicErrors(response.clinicErrorsCount);
      })
      .then(() => {
        fetchConsultationsPurchasedCount(
          endpoints["consultationsPurchasedCount"]
        ).then((response) => {
          if (
            response.purchasedConsultationsCount !== null &&
            response.purchasedConsultationsCount !== undefined
          )
            setConsultationsPurchasedCount(
              response.purchasedConsultationsCount
            );
        });
      });
  }, []);

  return (
    <Sider
      breakpoint="lg"
      width="220"
      collapsedWidth="0"
      style={{ backgroundColor: "#294B6F" }}
      zeroWidthTriggerStyle={{ backgroundColor: "#294B6F" }}
    >
      <Brand />
      {loading ? (
        <Spinner />
      ) : (
        <SidebarList
          purchasedConsultationsCount={consultationsPurchasedCount}
          clinicErrorsCount={existClinicErrors}
        />
      )}
    </Sider>
  );
};

export default Sidebar;
