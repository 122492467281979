import { Table, Card, Row, Col, Typography } from "antd";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { endpoints } from "../../utils/apiClient";
import { ContentTitle } from "../ContentTitle";
import { RouteContext } from "../Dashboard";
import useFetchPatientProfile from "../PatientProfile/useFetchPatientProfile";

interface SurveyPatientVariablesProperties {
  patientId: number;
  variableCode: string;
  variableName: string;
  question: string;
  valueType: number;
  rawValue: string;
}

interface SurveyProfileProperties {
  createdAt: string;
  patientId: number;
  dataSourceId: number;
  id: number;
  updatedAt: string;
  patientVariables: SurveyPatientVariablesProperties[];
  dataSource: {
    name: string;
    description: string;
    ownerName: string;
    dataSourceType: number;
  };
}

interface SurveyProfileTableProperties {
  key: number;
  question: string;
  rawValue: string;
}

const SurveyProfile = () => {
  const { surveyId } = useParams<{ surveyId: string }>();
  const [patientId, setPatientId] = useState<number>();
  const [surveys, setSurveys] = useState<SurveyProfileTableProperties[]>([]);
  const [patientName, setPatientName] = useState("");
  const [surveyName, setSurveyName] = useState("");
  const { getConditions, loading } = useFetchPatientProfile();
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const [surveyTimeStamp, setSurveyTimeStamp] = useState<string>("")

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Patients Data",
        path: "/patients"
      },
      {
        title: patientName || "Patient Profile",
        path: `/patient-profile/${patientId}`
      },
      {
        title: surveyName,
        path: `/survey-profile/${surveyId}`
      }
    ]);
    setCurrentKey("patients");
  }, [surveyId, patientId, patientName, setBreadcrumbItems, setCurrentKey]);

  const tableColumns = [
    { title: ContentTitle("Question"), dataIndex: "question", width: "50%" },
    { title: ContentTitle("Answer"), dataIndex: "rawValue" }
  ];

  const prepareData = useCallback((data: SurveyProfileProperties) => {
    const dataArr: SurveyProfileTableProperties[] = [];

    data.patientVariables.forEach((val, key) => {
      dataArr.push({ key, question: val.question, rawValue: val.rawValue });
    });

    return dataArr;
  }, []);

  useEffect(() => {
    getConditions(endpoints.patientsSurveysData, surveyId).then((responseData: SurveyProfileProperties) => {
      if (!responseData) return;
      //questions+answers
      setSurveys(prepareData(responseData));
      //patientId
      setPatientId(responseData.patientId);
      //surveyName
      setSurveyName(responseData.dataSource.name);
      //surveyTimeStamp or surveyCreatedAt
      setSurveyTimeStamp(moment(responseData.createdAt).format('MM/DD/YYYY').toString())


      if (!responseData.patientId) return;
      getConditions(endpoints.patientDetails, responseData.patientId).then(response => {
        response.firstName && response.lastName && setPatientName(`${response.firstName} ${response.lastName}`);
      });
    });
  }, [getConditions, surveyId, prepareData]);

  return (
    <>
      <Row style={{ display: "flex" }} align="stretch" gutter={[16, 16]}>
        <Col xxl={{ span: 4 }} xl={{ span: 6 }} md={{ span: 8 }} span="24" style={{ paddingLeft: "0" }}>
          <Card
            title="Survey Details"
            headStyle={{ backgroundColor: "#fafafa" }} style={{ marginLeft: 32, marginTop: 10, minWidth: "10vw", height: "100%" }}
            size="small"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "space-between",
              }}
            >
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography.Text type="secondary">Survey Name</Typography.Text>
                  <Typography.Paragraph strong>{surveyName}</Typography.Paragraph>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography.Text type="secondary">Created at</Typography.Text>
                  <Typography.Paragraph strong>{surveyTimeStamp}</Typography.Paragraph>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography.Text type="secondary">Patient's Name</Typography.Text>
                  <Typography.Paragraph strong>{patientName}</Typography.Paragraph>
                </div>
              </div>
            </div>
          </Card>
        </Col>

        <Col xxl={{ span: 18 }} xl={{ span: 16 }} md={{ span: 12 }} span="24" style={{ paddingLeft: "0" }}>
          <Table
            bordered
            columns={tableColumns}
            dataSource={surveys}
            pagination={{
              size: "small",
              hideOnSinglePage: true,
              showSizeChanger: false,
              pageSize: 10
            }}
            style={{ margin: "10px 32px", width: "100vw" }}
            loading={loading}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};

export default SurveyProfile;
