import { AlignCenterOutlined, EyeFilled } from "@ant-design/icons";
import { Button, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ContentTitle } from "../ContentTitle";
import { RouteContext } from "../Dashboard";
import { orderStatus, orderStatusColors } from "../PatientProfile/orderStatus";
import { useAdminWarnings } from "./hooks";
import { clinicErrorTypes } from "./WarningPage/ClinicErrorSteps";

export const AdminWarningsContext = React.createContext<any>(null);

export interface WarningTableProperties {
  key: number;
  labName: string;
  patientName: string;
  patientEmail: string;
  createdAt: string | number | Date;
  price: string;
  orderStatus: number;
  patientOrderId: number;
}

export const AdminWarnings = () => {

  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const allWarnings = useAdminWarnings()
  const { loading, warnings } = allWarnings

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Admin Warnings",
        path: "/admin-warnings"
      }
    ]);
    setCurrentKey("admin-warnings");
  }, [setBreadcrumbItems, setCurrentKey]);

  const columns =
    [
      {
        title: ContentTitle("Order No"),
        dataIndex: "key",
        key: "key",
        render: (record: any) => {
          return (
            record != 0 ? record : '-'
          )
        }
      },
      {
        title: ContentTitle("Lab Name"),
        dataIndex: "labName",
        key: "labName",
        render: (record: any) => {
          return (
            record ?? '-'
          )
        }
      },
      {
        title: ContentTitle("Patient Name"),
        dataIndex: "patientName",
        key: "patientName",
        render: (record: any) => {
          return (
            record ?? '-'
          )
        }
      },
      {
        title: ContentTitle("Email"),
        dataIndex: "patientEmail",
        key: "patientEmail",
        render: (record: any) => {
          return (
            record ?? '-'
          )
        }
      },
      {
        title: ContentTitle("Date"),
        dataIndex: "createdAt",
        key: "createdAt",
        render: (record: any) => {
          return (
            moment(record).format("MM/DD/YYYY")
          )
        }
      },
      {
        title: ContentTitle("Price"),
        dataIndex: "price",
        key: "price",
        render: (record: any) => {
          return (
            record != 0 ? record : '-'
          )
        }
      },
      {
        title: ContentTitle("Order Status"),
        dataIndex: "orderStatus",
        key: "orderStatus",
        render: (record: any) => {
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
              }}
            >
              <Tag color={orderStatusColors[record]}>{orderStatus[record]}</Tag>
            </div>
          )
        }
      },
      {
        title: ContentTitle("Error Step"),
        dataIndex: "errorStep",
        key: "errorStep",
        render: (record: any) => {
          return (

            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
              }}
            >
              <Tag color="pink">{clinicErrorTypes[record]}</Tag>
            </div>
          )
        }
      },
      {
        title: ContentTitle("Action"),
        key: "action",
        render: (record: WarningTableProperties) => {
          return (
            <Tooltip placement="left" title={record.patientOrderId != 0 ? `Go to Order No. ${record.key}` : ``}>
              <Button type="text" disabled={record.patientOrderId == 0}>
                <Link to={`/dashboard/warning-details/${record.key}`}>
                  <EyeFilled style={{ color: "#ACACAC", fontSize: "17px" }} />
                </Link>
              </Button>
            </Tooltip>
          );
        }
      }
    ]


  return (
    <AdminWarningsContext.Provider value={allWarnings}>
      <div style={{ margin: "10px 30px", overflow: "auto" }}>
        <Table columns={columns}
          pagination={{
            size: "small",
            hideOnSinglePage: true,
            showSizeChanger: false,
            pageSize: 10
          }}
          dataSource={warnings}
          style={{ minWidth: "max-content" }}
          loading={loading}
          size="small">
        </Table>
      </div>
    </AdminWarningsContext.Provider>
  )
}