export const heardAboutUsDbAnswerValues: { [key: number]: {dbValue: string, uiValue: string} }= {
    1: {dbValue : "From my doctor", uiValue: "From my doctor"},
    101: {dbValue : "From my doctor;Ford Brewer, MD, MPH", uiValue: "Ford Brewer, MD, MPH"},
    102: {dbValue : "From my doctor;David B Wright, MD", uiValue: "David B Wright, MD"},
    103: {dbValue : "From my doctor;Other Doctor", uiValue: "Other Doctor"},
    104: {dbValue : "From my doctor;David Meinz RD", uiValue: "David Meinz RD"},

    2: {dbValue : "Social media", uiValue: "Social media"},
    201: {dbValue : "Social media;PrevMed/Ford Brewer MD MPH", uiValue: "PrevMed/Ford Brewer MD MPH"},
    202: {dbValue : "Social media;Other social media", uiValue: "Other social media"},
    203: {dbValue : "Social media;Marie Pizano", uiValue: "Marie Pizano"},

    3: {dbValue : "From a friend", uiValue: "From a friend"},
    4: {dbValue : "Other", uiValue: "Other"}
  };
