import { useCallback, useEffect, useState } from "react";
import apiClient from "../../utils/apiClient";
import { useLocation } from "react-router-dom";
import { NetworksTableProperties } from "./types";

interface Filters {
  currentPage: number;
  query: string | null;
  skip: number;
}

export const useFetchNetworks = (path: string) => {
  const currentLocation = useLocation();

  const [data, setData] = useState<NetworksTableProperties[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [networksFilters, setNetworksFilters] = useState<Filters | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocation.search);

    setNetworksFilters({
      query: searchParams.get("searchFilter"),
      currentPage: searchParams.get("page")
        ? Number(searchParams.get("page"))
        : 1,
      skip:
        ((searchParams.get("page") ? Number(searchParams.get("page")) : 1) -
          1) *
        (10 as number),
    });
  }, [currentLocation]);

  const getData = useCallback(async () => {
    if (networksFilters === null) return;

    try {
      const filters = {
        skip: networksFilters.skip,
        take: 10,
        searchFilter: "",
      };

      if (networksFilters.query) {
        Object.assign(filters, { searchFilter: networksFilters.query });
      }

      const response = await apiClient.post(path, filters);
      setData(response.data.networks);
      setTotalItems(response.data.count);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [networksFilters]);

  useEffect(() => {
    getData();
  }, [getData]);

  return {
    data,
    setData,
    totalItems,
    loading,
    setLoading,
    networksFilters,
  };
};
