export const clinicErrorSteps: { [key: number]: string } = {
  1: "Authentication Error",
  10: "Create Order Error",
  20: "Get Requisition PDF Error",
  30: "Get Lab Results PDF Error",
  40: "Get Lab Results Error",
  50: "Matching Error", 
  60: "General Error"
  };

  export const clinicErrorTypes: { [key: number]: string } = {
    1: "Authentication",
    10: "Create Order",
    20: "Get Requisition PDF",
    30: "Get Lab Results PDF",
    40: "Get Lab Results",
    50: "Matching",
    60: "General"
  };