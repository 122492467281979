import { Button, Card, Col, Form, Input, Space } from "antd";
import { endpoints } from "../../utils/apiClient";
import useAdminsActions from "../AdminProfile/useAdminsActions";
import { ContentTitle } from "../ContentTitle";

interface ChangeMyPassProperties {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangeMyPass = ({ setShowChangePass }: { setShowChangePass: Function }) => {
  const { postAdminData } = useAdminsActions();
  const [form] = Form.useForm();

  const handleChangePass = async (values: ChangeMyPassProperties) => {
    const response = await postAdminData(endpoints.changePassword, values);
    response && form.resetFields();
  };

  return (
    <Col span="8">
      <Card title={ContentTitle("Change Password")} size="small">
        <Form name="changePassword" form={form} layout="vertical" requiredMark={false} onFinish={handleChangePass}>
          <Form.Item
            label="Current Password"
            name="currentPassword"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input your current password!"
              }
            ]}
            style={{ marginBottom: "10px" }}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            hasFeedback
            dependencies={["currentPassword"]}
            rules={[
              {
                required: true,
                message: "Please input your password!"
              },
              {
                min: 5,
                message: "Your password should have at least 5 characters!"
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("currentPassword") !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The new password can't be the same as current one!"));
                }
              })
            ]}
            style={{ marginBottom: "10px" }}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            hasFeedback
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!"
              },
              {
                min: 5,
                message: "Your password should have at least 5 characters!"
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The two passwords do not match!"));
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={setShowChangePass.bind(null, false)}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Col>
  );
};

export default ChangeMyPass;
