import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Button, Table, Typography } from "antd";
import { useState } from "react";
import { PatientRiskFactorsProperties } from ".";
import { ContentTitle } from "../ContentTitle";
import { shortRiskLevels } from "./conditionStatus";

interface ConditionVariablesTableProperties {
  variableName: string | undefined;
  rawValue: string;
  color: string;
}

const ConditionVariablesTable = ({
  data,
  setCurrentHistoryTable,
  setHistoryTableVisible
}: {
  data: PatientRiskFactorsProperties[] | undefined;
  setCurrentHistoryTable: Function;
  setHistoryTableVisible: Function;
}) => {
  const [oldIndex, setOldIndex] = useState(-1);

  const handleActionClick = (record: ConditionVariablesTableProperties, index: number) => {
    setOldIndex(index === oldIndex ? -1 : index);
    setHistoryTableVisible(index !== oldIndex);
    setCurrentHistoryTable(record.variableName);
  };

  const tableColumns = [
    {
      title: ContentTitle("Variable Name"),
      dataIndex: "variableName"
    },
    {
      title: ContentTitle("Variable Description"),
      dataIndex: "riskVariableDescription"
    },
    {
      title: ContentTitle("Current Value"),
      dataIndex: "rawValue",
      render: (text: any, record: ConditionVariablesTableProperties) => (
        <Typography.Text style={{ color: record.color }}>
          {isNaN(text) ? text : parseFloat(Number(text).toFixed(2))}
        </Typography.Text>
      )
    },
    {
      title: ContentTitle("Details"),
      dataIndex: "action",
      render: (text: string, record: ConditionVariablesTableProperties, index: number) => {
        return (
          record.rawValue !== "Unknown" && record.rawValue !== "Hidden" && (
            <Button type="text" onClick={handleActionClick.bind(null, record, index)}>
              {oldIndex === index ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </Button>
          )
        );
      }
    }
  ];

  const tableData = data
    ? data
      .filter(el => el.patientVariable)
      .map((el, key) => {

        var rawValue = "";

        if (el.hidden) {
          rawValue = "Hidden"
        }
        else {
          rawValue = el.patientVariable?.rawValue || "Unknown"
        }

        return {
          key,
          variableName: el.patientVariable?.variableName,
          riskVariableDescription: el.riskVariableDescription,
          hidden: el.hidden,
          rawValue: rawValue,
          color: shortRiskLevels[el.shortRiskLevel],
        };
      })
    : [];

  return (
    <Table
      columns={tableColumns}
      dataSource={tableData}
      size="small"
      bordered
      pagination={{
        size: "small",
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSize: 10
      }}
    />
  );
};

export default ConditionVariablesTable;
