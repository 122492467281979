import { Cascader, Form, Select, Space } from "antd";
import { useDebugValue, useEffect, useState } from "react";
import { RouterProps } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { heardAboutUsDbAnswerValues } from "../PatientsTable/PatientFilteringOptions";
import { NetworksTableProperties } from "../NetworksTable/types";
import apiClient from "../../utils/apiClient";
import Spinner from "../Spinner";

const AdminFilters = ({
  dispatch,
  history,
  isResponsive,
}: {
  dispatch: React.Dispatch<any>;
  history: RouterProps["history"];
  isResponsive?: boolean;
}) => {
  const searchParams = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState<boolean>(true);
  const [networks, setNetworks] = useState<NetworksTableProperties[]>([]);

  useEffect(() => {
    apiClient
      .post("/networks", {
        skip: 0,
        take: 99999,
        searchFilter: "",
      })
      .then((res) => {
        setNetworks(res.data.networks);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleNetworkSelect = (value: string) => {
    dispatch({
      type: "UPDATE_BULK_FILTERS",
      paramCollection: { networkId: value, page: 1 },
      history,
    });
  };

  if (loading) return <Spinner />;

  return (
    <Space style={{ display: isResponsive ? "block" : "inline-flex" }}>
      <Form.Item
        name="admin-network"
        style={{ margin: isResponsive ? "10px 0" : "0" }}
      >
        <Select
          defaultValue={searchParams.get("networkId")?.toString()}
          placeholder="Select network"
          allowClear
          onChange={handleNetworkSelect}
        >
          {networks.map((n) => {
            return <Select.Option value={n.id + ""}>{n.name}</Select.Option>;
          })}
        </Select>
      </Form.Item>
    </Space>
  );
};

export default AdminFilters;
