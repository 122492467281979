import { Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { endpoints } from "../../utils/apiClient";
import useAdminsActions from "../AdminProfile/useAdminsActions";
import { RouteContext } from "../Dashboard";
import ChangeMyPass from "./ChangeMyPass";
import EditMyAccount from "./EditMyAccount";
import MyAccountInfo from "./MyAccountInfo";

const MyAccount = () => {
  const { getAdminData, data, setData, loading } = useAdminsActions();
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showChangePass, setShowChangePass] = useState(false);

  useEffect(() => {
    getAdminData(endpoints.selfProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "My Account",
        path: "/my-account",
      },
    ]);
    setCurrentKey("none");
  }, [setBreadcrumbItems, setCurrentKey]);

  return (
    <Row gutter={15} style={{ margin: "0 30px" }}>
      <MyAccountInfo
        data={data}
        loading={loading}
        editProfile={{ showEditProfile, setShowEditProfile }}
        changePass={{ showChangePass, setShowChangePass }}
      />
      {showEditProfile && (
        <EditMyAccount
          setShowEditProfile={setShowEditProfile}
          data={data}
          setData={setData}
        />
      )}
      {showChangePass && <ChangeMyPass setShowChangePass={setShowChangePass} />}
    </Row>
  );
};

export default MyAccount;
