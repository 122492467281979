import { useEffect, useState } from "react";
import apiClient, { endpoints } from "./apiClient";
import { showErrorNotification } from "./utils";

const useCheckTokenStatus = () => {
  const [validStatus, setValidStatus] = useState(true);

  const fetchStatus = async () => {
    const response = await apiClient.post(endpoints.getTokenStatus, { token: localStorage.getItem("token") });
    setValidStatus(response.data.isValid);
  };

  useEffect(() => {
    setInterval(fetchStatus, 60000);
  }, []);

  useEffect(() => {
    !validStatus && showErrorNotification("Invalid or expired token!", true);
  }, [validStatus]);
};

export default useCheckTokenStatus;
