import { Tabs, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { endpoints } from "../../utils/apiClient";
import { RouteContext } from "../Dashboard";
import useFetchPatientProfile from "../PatientProfile/useFetchPatientProfile";
import VariablesHistory from "./VariablesHistory";
import VariablesTable from "./VariablesTable";

interface ParamProperties {
  id: string | undefined;
  orderId: string | undefined;
}

const AddLabInput = () => {
  const { id, orderId } = useParams<ParamProperties>();
  const [patientName, setPatientName] = useState<string | null>(null);
  const [hasAccess, setHasAccess] = useState(true);
  const { getConditions } = useFetchPatientProfile();
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);

  useEffect(() => {
    const breadArr = [
      {
        title: "Patients Data",
        path: "/patients",
      },
      {
        title: orderId
          ? `Boston Heart Diagnostics - No. ${orderId}`
          : patientName || "Patient Profile",
        path: orderId ? `/order-profile/${orderId}` : `/patient-profile/${id}`,
      },
      {
        title: orderId ? "Add Order Results" : "Lab Input",
        path: `/add-lab-input/${id}/${orderId}`,
      },
    ];

    if (id && orderId) {
      breadArr.splice(1, 0, {
        title: patientName || "Patient Profile",
        path: `/patient-profile/${id}`,
      });
    }

    setBreadcrumbItems(breadArr);
    setCurrentKey("patients");
  }, [id, orderId, patientName, setBreadcrumbItems, setCurrentKey]);

  useEffect(() => {
    getConditions(endpoints.patientDetails, id).then(
      (data: {
        firstName: string;
        lastName: string;
        screeningSurveySustained: boolean;
      }) => {
        data.firstName &&
          data.lastName &&
          setPatientName(`${data.firstName} ${data.lastName}`);
        setHasAccess(data.screeningSurveySustained === true);
      }
    );
  }, [getConditions, id]);

  const props = { id, orderId };

  return hasAccess ? (
    <Tabs size="large" style={{ margin: "0 30px" }} destroyInactiveTabPane>
      <Tabs.TabPane tab="Add" key="1">
        <VariablesTable {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="History" key="2">
        <VariablesHistory {...props} />
      </Tabs.TabPane>
    </Tabs>
  ) : (
    <Typography.Title level={4} style={{ textAlign: "center" }}>
      Action not allowed! This patient doesn't have screening survey.
    </Typography.Title>
  );
};

export default AddLabInput;
