import { Button, Card, Col, Modal, Space, Typography } from "antd";
import { ContentTitle } from "../ContentTitle";
import { adminRoles } from "./adminRoles";
import useAdminsActions, { AdminActionsProperties } from "./useAdminsActions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { endpoints } from "../../utils/apiClient";

interface AccountInfoDataProperties {
  data: AdminActionsProperties | undefined;
  loading: boolean;
  editProfile: { showEditProfile: boolean; setShowEditProfile: Function };
  changePass: { showChangePass: boolean; setShowChangePass: Function };
}

const AccountInfo = ({
  data,
  loading,
  editProfile,
  changePass,
}: AccountInfoDataProperties) => {
  const { deleteAdmin } = useAdminsActions();

  const accountInfoData = [
    {
      label: "First Name",
      value: data?.firstName,
    },
    {
      label: "Last Name",
      value: data?.lastName,
    },
    {
      label: "Email",
      value: data?.email,
    },
    {
      label: "Phone Number",
      value: data?.phone,
    },
    {
      label: "Role",
      value: adminRoles[data ? data.role : 0],
    },
  ];

  const toggleEditDisplay = () => {
    editProfile.setShowEditProfile(!editProfile.showEditProfile);
    changePass.setShowChangePass(false);
  };

  const toggleChangeDisplay = () => {
    changePass.setShowChangePass(!changePass.showChangePass);
    editProfile.setShowEditProfile(false);
  };

  const handleDelete = () => {
    Modal.confirm({
      title: `Are you sure you want to delete ${data?.lastName}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okButtonProps: { danger: true },
      onOk: () => {
        deleteAdmin(endpoints.deleteAdmin, data?.id);
      },
    });
  };

  return (
    <Col span="5" style={{ paddingLeft: "0" }}>
      <Card title={ContentTitle("Account Info")} size="small" loading={loading}>
        {accountInfoData.map((el, key) => (
          <div key={key}>
            <Typography.Text type="secondary">{el.label}</Typography.Text>
            <Typography.Paragraph>{el.value}</Typography.Paragraph>
          </div>
        ))}
        {data?.role == 10 && (
          <div key={data.lastName}>
            <Typography.Text type="secondary">Network</Typography.Text>
            <Typography.Paragraph>{data.networkName}</Typography.Paragraph>
          </div>
        )}
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button onClick={toggleEditDisplay} block>
            Edit Profile
          </Button>
          <Button onClick={toggleChangeDisplay} block>
            Change Password
          </Button>
          <Button onClick={handleDelete} block danger>
            Delete Profile
          </Button>
        </Space>
      </Card>
    </Col>
  );
};

export default AccountInfo;
