import { message } from "antd";
import { useState } from "react";
import apiClient from "../../utils/apiClient";

const useUploadPdf = () => {
  const [loading, setLoading] = useState(false);

  const uploadData = (path: string, formData: FormData, params = {}) => {
    setLoading(true);

    return new Promise((resolve, reject) => {
      apiClient
        .post(path, formData, {
          params,
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status === 200) {
            resolve(true);
            message[response.data?.isSuccessful ? "success" : "warning"](response.data?.message);
          } else {
            reject();
          }
        })
        .then(() => {
          setLoading(false);
        });
    });
  };

  return { loading, uploadData };
};

export default useUploadPdf;
