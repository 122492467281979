import { Layout, Divider, Space, Button, Form } from "antd";
import SearchInput from "./SearchInput";
import DateFilter from "./DateFilter";
import ExportButton from "./ExportButton";
import UserMenu from "./UserMenu";
import Breadcrumbs from "./Breadcrumbs";
import { useContext, useEffect, useReducer, useState } from "react";
import { RouteContext } from "../Dashboard";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import LogFilters from "./LogFilters";
import ClearFiltersBtn from "./ClearFiltersBtn";
import filterReducer from "./filterReducer";
import OverviewDates from "./OverviewDates";
import FinancesFilters from "./FinancesFilters";
import PatientFilters from "./PatientFilters";
import AdminFilters from "./AdminFilters";
import useAccessPermissions from "../../utils/useAccessPermissions";
import ConsultationsFilters from "./ConsultationsFilters";
import { NetworksFilter } from "./NetworksFilter";

const Navbar = ({ isResponsive }: { isResponsive?: boolean }) => {
  const { Header } = Layout;
  const [form] = Form.useForm();
  const history = useHistory();
  const [state, dispatch] = useReducer(filterReducer, {});
  const { breadcrumbItems, filters, setFilters } = useContext(RouteContext);
  const [showSearchInput, setShowSearchInput] = useState(true);
  const [showFinancesFilters, setShowFinancesFilters] = useState(true);
  const [showPatientFilters, setShowPatientFilters] = useState(true);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showExportBtn, setShowExportBtn] = useState(false);
  const [showAddAdminBtn, setShowAddAdminBtn] = useState(false);
  const [showLogLevels, setShowLogLevels] = useState(false);
  const [showOverviewDates, setShowOverviewDates] = useState(false);
  const [showAdminFilters, setShowAdminFilters] = useState(false);
  const [showConsultationsFilters, setShowConsultationsFilters] =
    useState(false);
  const [showNetworksFilter, setShowNetworksFilter] = useState(false);
  const { hasMedicalAccess } = useAccessPermissions();

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);

    !searchParams.toString() && form.resetFields();

    if (searchParams.has("patientEmail")) {
      form.setFieldsValue({
        "patient-autocomplete": searchParams.get("patientEmail"),
      });
    }

    const newFilters = {};

    for (const [key, value] of searchParams as any) {
      Object.assign(newFilters, { [key]: value });
    }

    setFilters(newFilters);
  }, [history.location.search]);

  useEffect(() => {
    if (!breadcrumbItems.length) return;

    const lastItem = breadcrumbItems[breadcrumbItems.length - 1];
    setShowSearchInput(
      lastItem.path.includes("finances") ||
        lastItem.path.includes("patients") ||
        lastItem.path.includes("networks") ||
        lastItem.path.includes("admins") ||
        lastItem.path.includes("activity-logs") ||
        lastItem.path.includes("consultations")
    );
    setShowFinancesFilters(lastItem.path.includes("finances"));
    setShowConsultationsFilters(lastItem.path.includes("consultations"));
    setShowPatientFilters(lastItem.path.includes("patients"));
    setShowDateFilter(
      lastItem.path.includes("finances") ||
        lastItem.path.includes("activity-logs") ||
        lastItem.path.includes("consultations")
    );
    setShowExportBtn(
      lastItem.path.includes("finances") ||
        lastItem.path.includes("patients") ||
        lastItem.path.includes("consultations")
    );
    setShowAddAdminBtn(lastItem.path.includes("admins"));
    setShowAdminFilters(lastItem.path.includes("admins"));
    setShowLogLevels(lastItem.path.includes("activity-logs"));
    setShowOverviewDates(lastItem.path.includes("overview"));
    setShowNetworksFilter(
      (lastItem.path.includes("finances") ||
        lastItem.path.includes("consultations")) &&
        !hasMedicalAccess
    );
  }, [breadcrumbItems]);

  const props = { state, dispatch, history };

  return (
    <>
      <Header
        className="mhpheader"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Form
          form={form}
          name="filter-controller"
          style={{ display: "inline-block", width: "100%" }}
        >
          <Space
            className="header-outer-space"
            style={{
              display: isResponsive ? "block" : "flex",
              justifyContent:
                showSearchInput || showOverviewDates
                  ? "space-between"
                  : "flex-end",
            }}
          >
            <Space
              size="middle"
              style={{ display: isResponsive ? "block" : "inline-flex" }}
            >
              {showSearchInput && <SearchInput {...props} />}

              {showLogLevels && (
                <LogFilters isResponsive={isResponsive} {...props} />
              )}

              {showFinancesFilters && (
                <FinancesFilters isResponsive={false} {...props} />
              )}

              {showConsultationsFilters && (
                <ConsultationsFilters isResponsive={false} {...props} />
              )}

              {showPatientFilters && (
                <PatientFilters isResponsive={false} {...props} />
              )}

              {showAdminFilters && (
                <AdminFilters isResponsive={false} {...props} />
              )}

              {showOverviewDates && <OverviewDates {...props} />}
            </Space>

            <Space
              size="middle"
              style={{
                display: isResponsive ? "block" : "inline-flex",
                position: isResponsive ? "initial" : "absolute",
                right: isResponsive ? 0 : "30px",
                top: isResponsive ? 0 : 80,
              }}
            >
              {(showLogLevels && filters && Object.values(filters).length && (
                <ClearFiltersBtn {...props} form={form} />
              )) ||
                ""}

              {showDateFilter && (
                <DateFilter isResponsive={isResponsive} {...props} />
              )}

              {showNetworksFilter && !hasMedicalAccess && (
                <NetworksFilter isResponsive={isResponsive} {...props} />
              )}

              {showExportBtn && <ExportButton isResponsive={isResponsive} />}
              {showAddAdminBtn && (
                <Link to="/dashboard/add-new-admin">
                  <Button
                    block={isResponsive}
                    icon={<PlusOutlined />}
                    style={{ margin: isResponsive ? "10px 0 0" : "0" }}
                  >
                    Add New Admin
                  </Button>
                </Link>
              )}
            </Space>
            <UserMenu isResponsive={isResponsive} />
          </Space>
        </Form>
      </Header>

      {!isResponsive && (
        <>
          <Divider style={{ marginTop: "0" }} />
          <Breadcrumbs />
        </>
      )}
    </>
  );
};

export default Navbar;
