import apiClient, { endpoints } from "../../utils/apiClient";

const useDownloadReport = () => {
  const downloadPatientsReport = async () => {
    let response;

    response = await apiClient.get(endpoints.patientsDownloadReport, {
      responseType: "blob",
    });

    return response.data;
  };

  const downloadFinanceReport = async (
    startDate: string | null,
    endDate: string | null,
    networkIds: number[] | undefined
  ) => {
    let filters = {};

    if (startDate && endDate) {
      filters = { startDate, endDate, networkIds };
    } else {
      filters = { networkIds };
    }

    let response;

    response = await apiClient.post(endpoints.downloadReport, filters, {
      responseType: "blob",
    });

    return response.data;
  };

  const downloadConsultationsReport = async (
    startDate: string | null,
    endDate: string | null,
    networkIds: number[] | undefined
  ) => {
    let filters = {};

    if (startDate && endDate) {
      filters = { startDate, endDate, networkIds };
    } else {
      filters = { networkIds };
    }

    let response;

    response = await apiClient.post(
      endpoints.downloadConsultationsReport,
      filters,
      {
        responseType: "blob",
      }
    );

    return response.data;
  };

  const downloadProviderReport = async (patientId: string) => {
    let response;

    response = await apiClient.get(
      `/patients/download-provider-report/${patientId}`,
      {
        responseType: "blob",
      }
    );

    return response.data;
  };

  return {
    downloadPatientsReport,
    downloadFinanceReport,
    downloadProviderReport,
    downloadConsultationsReport,
  };
};

export default useDownloadReport;
