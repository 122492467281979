import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import apiClient from "../../utils/apiClient";

interface Filters {
  startDate: string | null;
  endDate: string | null;
  messageSequence: string | null;
  logLevel: string | null;
  patientId: string | null;
  adminId: string | null;
  currentPage: number;
  skip: number;
}

const useFetchLogs = (path: string) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [logsFilters, setLogsFilters] = useState<Filters | null>(null);

  const history = useHistory();
  const currentLocation = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocation.search);

    setLogsFilters({
      startDate: searchParams.get("startDate"),
      endDate: searchParams.get("endDate"),
      messageSequence: searchParams.get("messageSequence"),
      logLevel: searchParams.get("logLevel"),
      patientId: searchParams.get("patientId"),
      adminId: searchParams.get("adminId"),
      currentPage: searchParams.get("page") ? Number(searchParams.get("page")) : 1,
      skip: ((searchParams.get("page") ? Number(searchParams.get("page")) : 1) - 1) * (10 as number),
    })
  }, [currentLocation]);

  useEffect(() => {
    if (!logsFilters) return;

    const filters = {
      skip: logsFilters ? logsFilters.skip : 0,
      take: 10,
    };
    if (
      logsFilters.startDate &&
      logsFilters.endDate
    ) {
      Object.assign(filters, {
        startDate: logsFilters.startDate,
        endDate: logsFilters.endDate,
      });
    }
    if (logsFilters.adminId) {
      Object.assign(filters, { adminId: logsFilters.adminId });
    }
    if (logsFilters.patientId) {
      Object.assign(filters, { patientId: logsFilters.patientId });
    }
    if (logsFilters.logLevel) {
      Object.assign(filters, {
        logLevel: logsFilters.logLevel,
      });
    }
    if (logsFilters.messageSequence) {
      Object.assign(filters, { messageSequence: logsFilters.messageSequence });
    }

    apiClient
      .post(path, filters)
      .then(results => {
        setLogs(results.data.activityLogs);
        setTotalItems(results.data.count);
        setLoading(false);
      })
      .catch(err => {
        if (err.response?.status === 403) {
          history.push("/dashboard/patients");
        }
      });
  }, [logsFilters]);

  return { logs, loading, setLoading, totalItems, logsFilters };
};

export default useFetchLogs;
