import { useState, useEffect, useContext, useReducer } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Table, Tooltip, Typography } from "antd";
import { ContactsOutlined, TeamOutlined } from "@ant-design/icons";
import { endpoints } from "../../utils/apiClient";
import { RouteContext } from "../Dashboard";
import { ContentTitle } from "../ContentTitle";
import { NetworksTableProperties } from "./types";
import { useFetchNetworks } from "./useFetchNetworks";
import filterReducer from "../Navbar/filterReducer";

export const NetworksTable = () => {
  const [tableRows, setTableRows] = useState<NetworksTableProperties[]>([]);
  const { data, loading, totalItems, setLoading, networksFilters } =
    useFetchNetworks(endpoints.networks);
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const history = useHistory();
  const [state, dispatch] = useReducer(filterReducer, {});

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Networks",
        path: "/networks",
      },
    ]);
    setCurrentKey("networks");
  }, [setBreadcrumbItems, setCurrentKey]);

  const tableColumns = [
    {
      title: ContentTitle("ID"),
      dataIndex: "id",
    },
    {
      title: ContentTitle("Name"),
      dataIndex: "name",
      sorter: (a: NetworksTableProperties, b: NetworksTableProperties) =>
        a.name.localeCompare(b.name),
      render: (text: string, record: NetworksTableProperties) => (
        <Typography.Text style={{ width: "90%" }} ellipsis={{ tooltip: text }}>
          {text}
        </Typography.Text>
      ),
    },
    {
      title: ContentTitle("Patients Number"),
      dataIndex: "patientsNumber",
      sorter: (a: NetworksTableProperties, b: NetworksTableProperties) =>
        a.patientsNumber - b.patientsNumber,
    },
    {
      title: ContentTitle("Admins Number"),
      dataIndex: "adminsNumber",
    },
    {
      title: ContentTitle("Actions"),
      dataIndex: "action",
      width: "fit-content",
      render: (text: string, record: NetworksTableProperties) => (
        <div
          style={{
            minWidth: 110,
          }}
        >
          <Link
            to={{
              pathname: "/dashboard/patients",
              state: {
                networkId: record.id,
                page: 1,
              },
            }}
          >
            <Tooltip placement="left" title={"See Patients."}>
              <Button type="text">
                <ContactsOutlined />
              </Button>
            </Tooltip>
          </Link>

          <Link
            to={{
              pathname: "/dashboard/admins",
              state: {
                networkId: record.id,
                page: 1,
              },
            }}
          >
            <Tooltip placement="left" title={"See Admins."}>
              <Button type="text">
                <TeamOutlined />
              </Button>
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setTableRows(data);
  }, [data]);

  const sortableColumns: { [key: string]: string } = {
    id: "idSortingType",
    lastOrderDate: "lastOrderDateSortingType",
  };

  const handlePageUpdate = (page: number, pageSize: number | undefined) => {
    dispatch({
      type: "UPDATE_FILTERS",
      paramKey: "page",
      paramValue: page.toString(),
      history,
    });

    setLoading(true);
  };

  return (
    <div style={{ margin: "10px 30px", overflow: "auto" }}>
      <Table
        columns={tableColumns}
        dataSource={tableRows}
        style={{ minWidth: "max-content" }}
        loading={loading}
        size="small"
        pagination={{
          size: "small",
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize: 10,
          current: networksFilters ? networksFilters.currentPage : 1,
          total: totalItems,
          showTotal: (total) => `${tableRows.length} of ${total} results`,
          onChange: handlePageUpdate,
        }}
      />
    </div>
  );
};
