import { useContext } from "react";
import { AuthContext } from "../App";

const useAccessPermissions = () => {
  const auth = useContext(AuthContext);
  const user = auth.user || JSON.parse(localStorage.getItem("user") || "");

  const hasMedicalAccess = user.role === 10;
  const hasGeneralAccess = user.role === 50;
  const hasFullAccess = user.role === 100;

  return {
    hasGeneralAccess,
    hasMedicalAccess,
    hasFullAccess,
  };
};

export default useAccessPermissions;
