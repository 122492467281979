import { Card, Col, List, Space, Typography } from "antd";
import { ContentTitle } from "../ContentTitle";
import { ConsultationDetailsProps } from ".";

export const NetworkDetails = ({
  data,
}: {
  data: ConsultationDetailsProps;
}) => {
  const detailsArray = [
    {
      title: "Phone",
      value: data.networkPhoneNumber,
    },
    {
      title: "Email",
      value: data.networkEmail,
    },
    {
      title: "Website",
      value: (
        <Typography.Text>
          <a
            href={
              //   data.networkWebsite.startsWith("https://")
              //     ?
              data.networkWebsite
              // : "https://" + data.networkWebsite
            }
            target="_blank"
          >
            {data.networkWebsite}
          </a>
        </Typography.Text>
      ),
    },
  ];

  return (
    <Col
      xxl={{ span: 8 }}
      xl={{ span: 8 }}
      lg={{ span: 8 }}
      md={{ span: 24 }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
    >
      <Card
        title={ContentTitle("Network Details")}
        bodyStyle={{
          paddingBottom: "0",
          maxHeight: "250px",
          overflow: "hidden",
          overflowY: "auto",
        }}
        size="small"
      >
        <List
          dataSource={detailsArray}
          renderItem={(item) => (
            <Typography.Paragraph type="secondary" style={{ fontSize: "10px" }}>
              <Space style={{ fontSize: "13px" }} align="center">
                <div
                  style={{
                    width: "4px",
                    height: "4px",
                    backgroundColor: "#000",
                    borderRadius: "50%",
                  }}
                ></div>
                <Typography.Text
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography.Text
                    style={{ marginRight: 5 }}
                  >{`${item.title}: `}</Typography.Text>

                  <Typography.Text> {item.value}</Typography.Text>
                </Typography.Text>
              </Space>
            </Typography.Paragraph>
          )}
        />
      </Card>
    </Col>
  );
};
