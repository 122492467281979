import { Card, Col, Space, Tag, Typography } from "antd";
import { ConsultationDetailsProps } from ".";
import { formatDate } from "../../utils/utils";
import {
  consultationStatusColors,
  consultationStatusRanking,
  transactionStatusRanking,
} from "../ConsultationsTable/types";
import { ContentTitle } from "../ContentTitle";
import { transactionStatusColors } from "../PatientProfile/orderStatus";

export const OrderStatus = ({ data }: { data: ConsultationDetailsProps }) => {
  const orderStatusArr = [
    {
      title: "Order Status",
      date: formatDate(data.date),
      status: consultationStatusRanking[data.status],
      color: consultationStatusColors[data.status],
    },
    {
      title: "Transaction Status",
      date: formatDate(data.date),
      status: transactionStatusRanking[data.transactionStatus],
      color: transactionStatusColors[data.transactionStatus],
    },
  ];

  return (
    <Col
      xxl={{ span: 8 }}
      xl={{ span: 8 }}
      lg={{ span: 8 }}
      md={{ span: 24 }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
    >
      <Card title={ContentTitle("Consultation Order Status")} size="small">
        {orderStatusArr.map((order, key) => {
          if (order.date) {
            return (
              <div key={key}>
                <Typography.Text type="secondary" style={{ fontSize: "14px" }}>
                  {order.title}
                </Typography.Text>
                <Typography.Paragraph strong style={{ fontSize: "13px" }}>
                  <Space style={{ fontSize: "13px" }} align="center">
                    {order.date}
                    {order.status && (
                      <>
                        <div
                          style={{
                            width: "4px",
                            height: "4px",
                            backgroundColor: "#000",
                            borderRadius: "50%",
                          }}
                        ></div>
                        <Tag color={order.color}>{order.status}</Tag>
                      </>
                    )}
                  </Space>
                </Typography.Paragraph>
              </div>
            );
          } else return null;
        })}
      </Card>
    </Col>
  );
};
