import { Button, FormInstance } from "antd";
import { RouterProps } from "react-router-dom";

const ClearFiltersBtn = ({
  dispatch,
  history,
  form
}: {
  dispatch: React.Dispatch<any>;
  history: RouterProps["history"];
  form: FormInstance<any>;
}) => {
  const handleResetFilters = () => {
    form.resetFields();
    dispatch({ type: "CLEAR_FILTERS", history });
  };

  return <Button onClick={handleResetFilters}>Clear All Filters</Button>;
};

export default ClearFiltersBtn;
