import { Button, Modal, Space, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import apiClient from "../../utils/apiClient";
import { useParams } from "react-router-dom";

interface DownloadReqFormProps {
  title: string;
}

export const DownloadReqForm: React.FC<DownloadReqFormProps> = ({ title }) => {
  const { orderId } = useParams<{ orderId: string }>();
  const [downloadOrderLoading, setDownloadOrderLoading] = useState(false);

  const downloadFile = async () => {
    const res = await apiClient.get(
      `/patients/download-boston-req-form/${orderId}`,
      {
        responseType: "blob",
      }
    );
    setDownloadOrderLoading(false);
    return res.data;
  };

  const handleDownloadOrder = async () => {
    setDownloadOrderLoading(true);
    const file = await downloadFile();

    Modal.confirm({
      title: "Your file is ready!",
      okText: (
        <a href={URL.createObjectURL(file)} download={title}>
          Download
        </a>
      ),
    });
  };

  return (
    <Space
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
    >
      <Typography.Text>{title}</Typography.Text>
      <Button
        loading={downloadOrderLoading}
        onClick={handleDownloadOrder}
        icon={<DownloadOutlined />}
      />
    </Space>
  );
};
