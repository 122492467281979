export enum ConsultationOrderStatus {
  Unknown = 0,
  ConsultationPurchased,
  CustomerContacted,
  ConsultCompleted,
}

export const consultationStatusRanking: { [key: number]: string } = {
  0: "Unknown",
  1: "Consultation Purchased",
  2: "Customer Contacted",
  3: "Consultation Completed",
};

export const consultationStatusColors: { [key: number]: string } = {
  0: "default",
  1: "gold",
  2: "gold",
  3: "green",
};

export const consultationStatusColorsContrast: { [key: number]: string } = {
  0: "default",
  1: "#F6B145",
  2: "#F6B145",
  3: "green",
};

export enum TransactionStatuses {
  Unknown = 0,
  Succeeded,
  Pending,
  Failed,
  AmountMissmatch,
  Cancelled,
}

export const transactionStatusRanking: { [key: number]: string } = {
  0: "Unknown",
  1: "Succeeded",
  2: "Pending",
  3: "Failed",
  4: "Amount Missmatch",
  5: "Cancelled",
};
export const transactionStatusColors: { [key: number]: string } = {
  0: "default",
  1: "green",
  2: "gold",
  3: "red",
  4: "volcano",
  5: "volcano",
};
