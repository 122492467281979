import { Button, Card, Col, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContentTitle } from "../ContentTitle";
import apiClient, { endpoints } from "../../utils/apiClient";
import {
  ConsultationOrderStatus,
  consultationStatusColors,
  consultationStatusColorsContrast,
  consultationStatusRanking,
} from "../ConsultationsTable/types";
import Spinner from "../Spinner";
import { formatDate } from "../../utils/utils";

interface ConsultationData {
  consultationOrderName: string;
  patientConsultationOrderId: number;
  status: ConsultationOrderStatus;
  createdAt: string;
}

export const ConsultationsHistory = ({
  patientId,
  patientEmail,
  patientName,
}: {
  patientId: number;
  patientEmail: string;
  patientName: string;
}) => {
  const [data, setData] = useState<ConsultationData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  //   useEffect(() => {
  //     fetchLogsExcerpt(patientData.id).then(data => setLogs(data));
  //   }, [fetchLogsExcerpt, patientData.id]);

  useEffect(() => {
    apiClient
      .get(endpoints.consultationsHistory, { params: { patientId } })
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log("ERROR GETTING CONSULTATIONS HISTORY: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <Spinner />;

  return (
    <Col
      className="recommendations-card"
      xxl={{ span: 24 }}
      xl={{ span: 24 }}
      span="24"
      style={{ paddingLeft: "0" }}
    >
      <Card
        title={ContentTitle("Consultations")}
        size="small"
        loading={loading}
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <div style={{ flex: 1 }}>
          {data.length ? (
            data.slice(0, 5).map((consult, key) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "end",
                }}
              >
                {/* <Typography.Paragraph key={key}>
                  {`${consult.patientConsultationOrderId}: `}
                </Typography.Paragraph> */}

                <Typography.Paragraph
                  strong
                  style={{
                    color: "#4285F4",
                    marginLeft: 5,
                    marginRight: 5,
                    width: 300,
                  }}
                >
                  <Link
                    to={`/dashboard/consultation-details/${consult.patientConsultationOrderId}/${patientName}/${patientId}`}
                  >
                    {consult.consultationOrderName}
                  </Link>
                </Typography.Paragraph>

                <Typography.Paragraph type="secondary">
                  {" "}
                  purchased at
                </Typography.Paragraph>

                <Typography.Paragraph
                  strong
                  style={{ marginLeft: 15, width: 90 }}
                >
                  {" "}
                  {formatDate(consult.createdAt)}
                </Typography.Paragraph>

                {consult.status !== undefined ? (
                  <div
                    style={{
                      marginLeft: 5,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Typography.Paragraph type="secondary">
                      {" "}
                      with status
                    </Typography.Paragraph>
                    <div style={{ marginLeft: 10 }}>
                      <Typography.Paragraph
                        style={{
                          color:
                            consultationStatusColorsContrast[consult.status],
                        }}
                      >
                        {consultationStatusRanking[consult.status]}
                      </Typography.Paragraph>
                    </div>
                  </div>
                ) : (
                  <Typography.Paragraph> undefined</Typography.Paragraph>
                )}
              </div>
            ))
          ) : (
            <Typography.Text>No consultations ordered!</Typography.Text>
          )}
        </div>

        {(data.length && (
          <Link
            to={{
              pathname: "/dashboard/consultations",
              search: `?searchFilter=${patientEmail}`,
            }}
          >
            <Button block>See all consultations</Button>
          </Link>
        )) ||
          ""}
      </Card>
    </Col>
  );
};
