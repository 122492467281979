
export const validatePhoneNumber = (value: string) => {
  if(!checkPlusCharacter(value))
     return  {isSuccessful: false, message:'The number should start with + character!'} 
 
  if(!checkPhoneStructure(value))
     return {isSuccessful: false, message: 'The phone number shold not contain special characters, just digits!'}

  if(!checkPhoneLength(value)){
    return {isSuccessful: false, message: 'Invalid phone length!'}
  }

   return {isSuccessful: true, message: 'Your phone is valid!'}
}

export const checkPlusCharacter = (value: string) => {
  return value[0] === '+';
}


export const checkPhoneStructure = (value: string) => {
  let regex = /^[\+][0-9]+$/;
  let result = regex.test(value)

  return result;
};

export const checkPhoneLength = (value: string) => {
  let regex = /^[\+][0-9]{11,14}$/;
  let result = regex.test(value)
  
  return result;
}

