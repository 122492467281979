import {
  DeleteFilled,
  DeleteTwoTone,
  ExclamationCircleOutlined,
  EyeFilled,
} from "@ant-design/icons";
import { Button, Modal, Space, Table, Tooltip, Typography } from "antd";
import { useContext, useEffect, useReducer } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { endpoints } from "../../utils/apiClient";
import { adminRoles } from "../AdminProfile/adminRoles";
import useAdminsActions from "../AdminProfile/useAdminsActions";
import { ContentTitle } from "../ContentTitle";
import { RouteContext } from "../Dashboard";
import useFetchAdmins from "./useFetchAdmins";

interface AdminTableProperties {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: number;
  isDeleted: boolean;
}

const AdminTable = () => {
  const { data, setData, loading } = useFetchAdmins(endpoints.getAdmins);
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const { deleteAdmin } = useAdminsActions();

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Admins",
        path: "/admins",
      },
    ]);
    setCurrentKey("admins");
  }, [setBreadcrumbItems, setCurrentKey]);

  const handleDelete = (record: AdminTableProperties, index: number) => {
    Modal.confirm({
      title: `Are you sure you want to delete ${record.lastName}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okButtonProps: { danger: true },
      onOk: () => {
        setData(
          data.map((el, i) => ({
            ...el,
            key: el.id,
            isDeleted: el.isDeleted || index === i,
          }))
        );
        deleteAdmin(endpoints.deleteAdmin, record.id);
      },
    });
  };

  const tableColumns = [
    {
      title: ContentTitle("ID"),
      dataIndex: "id",
    },
    {
      title: ContentTitle("First Name"),
      dataIndex: "firstName",
      render: (text: string, record: AdminTableProperties) => (
        <Space>
          {record.isDeleted && (
            <Tooltip title="User is deleted!">
              <DeleteTwoTone twoToneColor="#eb2f96" />
            </Tooltip>
          )}
          <Typography.Text>{text}</Typography.Text>
        </Space>
      ),
    },
    {
      title: ContentTitle("Last name"),
      dataIndex: "lastName",
    },
    {
      title: ContentTitle("Email"),
      dataIndex: "email",
    },
    {
      title: ContentTitle("Role"),
      dataIndex: "role",
      render: (text: string) => (
        <Typography.Text>{adminRoles[Number(text)]}</Typography.Text>
      ),
    },
    {
      title: ContentTitle("Network"),
      dataIndex: "networkName",
    },
    {
      title: ContentTitle("Actions"),
      dataIndex: "actions",
      render: (text: string, record: AdminTableProperties, index: number) => {
        return (
          !record.isDeleted && (
            <>
              <Tooltip title={`Go to ${record.lastName}'s profile`}>
                <Button type="text">
                  <Link to={`/dashboard/admin-profile/${record.id}`}>
                    <EyeFilled style={{ color: "#ACACAC", fontSize: "17px" }} />
                  </Link>
                </Button>
              </Tooltip>

              <Tooltip title={`Delete ${record.lastName}`}>
                <Button
                  type="text"
                  onClick={handleDelete.bind(null, record, index)}
                >
                  <DeleteFilled
                    style={{ color: "#ACACAC", fontSize: "17px" }}
                  />
                </Button>
              </Tooltip>
            </>
          )
        );
      },
    },
  ];

  return (
    <div style={{ margin: "10px 30px", overflow: "auto" }}>
      <Table
        columns={tableColumns}
        dataSource={data.map((el) => ({ ...el, key: el.id }))}
        pagination={{
          size: "small",
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize: 10,
        }}
        style={{ minWidth: "max-content" }}
        loading={loading}
        size="small"
      />
    </div>
  );
};

export default AdminTable;
