import { Card, Col, Divider, List, Space, Typography } from "antd";
import { FailedPurchaseProperties } from ".";
import { ContentTitle } from "../ContentTitle";

const FailedPurchaseDetails = ({ data }: { data: FailedPurchaseProperties }) => {
    return (
        <Col
            className="purchase-details"
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
        >
            <Card
                title={ContentTitle("Purchase Details")}
                size="small"
                style={{ display: "flex", height: "100%", flexDirection: "column" }}
                bodyStyle={{ height: "100%", paddingBottom: "0" }}
            >
                <List
                    style={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "space-between" }}
                    dataSource={data?.patientPurchaseItems}
                    renderItem={item => (
                        <Space style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography.Paragraph>{item.labPackageName}</Typography.Paragraph>
                            <Typography.Paragraph>${item.totalPrice}</Typography.Paragraph>
                        </Space>
                    )}
                    footer={
                        <>
                            <Divider style={{ margin: "10px 0" }} />
                            <Space style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography.Text strong>Total</Typography.Text>
                                <Typography.Text strong>${data?.amount}</Typography.Text>
                            </Space>
                        </>
                    }
                />
            </Card>
        </Col>
    );
};

export default FailedPurchaseDetails;
