import { message } from "antd";
import { useState } from "react";
import apiClient from "../../utils/apiClient";
import { ItemsToEditProperty } from "./VariablesHistory";

interface LabDtoProperties {
  labTimestamp: moment.Moment | Date | undefined | string;
  values: { variableCode: string | undefined; decimalValue: string | undefined }[];
}

interface ParamProperties {
  patientId?: string;
  patientOrderId?: string;
  patientVariableIds?: number[];
  editedPatientVariables?: ItemsToEditProperty[];
  submitLabDto?: LabDtoProperties;
}

const useFetchLabData = () => {
  const [loading, setLoading] = useState(true);

  const getLabVariables = (path: string, params?: ParamProperties): Promise<any> => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      apiClient
        .get(path, { params })
        .then(response => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject();
          }
        })
        .then(() => setLoading(false));
    });
  };

  const postLabData = (path: string, data?: ParamProperties) => {
    return new Promise((resolve, reject) => {
      apiClient.post(path, data).then(response => {
        if (response.status === 200) {
          resolve(response);
          message.success("Lab results have been successfully submitted!");
        } else {
          reject();
        }

        setLoading(false);
      });
    });
  };

  const deleteLabData = (path: string, data?: ParamProperties) => {
    return new Promise((resolve, reject) => {
      apiClient.delete(path, { data }).then(response => {
        if (response.status === 200) {
          resolve(true);
          message.success("Items deleted successfully!");
        } else {
          reject();
        }

        setLoading(false);
      });
    });
  };

  return { loading, setLoading, getLabVariables, postLabData, deleteLabData };
};

export default useFetchLabData;
