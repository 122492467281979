export const orderStatusRanking: { [key: number]: string } = {
  0: "Unknown",
  10: "Pending Approval",
  11: "Approved",
  12: "Rejected",
  13: "Cancelled",
  14: "Completed",
  15: "Incomplete",
  16: "Awaiting Review",
  17: "Partial Results"
};

export const newOrderStatusRanking: { [key: number]: string } = {
  0: "Unknown",
  10: "Order Created",
  11: "Lab Kit Shipped",
  12: "Rejected",
  13: "Cancelled",
  14: "Ready to view",
  15: "Incomplete",
  16: "Awaiting Review",
  17: "Partial Results"
};

export const orderStatusColors: { [key: number]: string } = {
  0: "default",
  10: "gold",
  11: "green",
  12: "red",
  13: "default",
  14: "green",
  15: "volcano",
  16: "warning",
  17: "warning"
};

export const transactionStatusRanking: { [key: number]: string } = {
  0: "Unknown",
  1: "Succeeded",
  2: "Pending",
  3: "Failed",
  4: "Amount Missmatch"
};
export const transactionStatusColors: { [key: number]: string } = {
  0: "default",
  1: "green",
  2: "gold",
  3: "red",
  4: "volcano"
};
