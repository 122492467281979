import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Popover, Typography } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PatientDataProperties } from ".";
import useFetchLogsExcerpt from "../ActivityLogs/useFetchLogsExcerpt";
import { ContentTitle } from "../ContentTitle";

interface LogsProperties {
  message: string;
}

const ActivityLogsExcerpt = ({ patientData }: { patientData: PatientDataProperties }) => {
  const [logs, setLogs] = useState<LogsProperties[]>([]);
  const { fetchLogsExcerpt, loading } = useFetchLogsExcerpt();

  useEffect(() => {
    fetchLogsExcerpt(patientData.id).then(data => setLogs(data));
  }, [fetchLogsExcerpt, patientData.id]);

  return (
    <Col className="activity-logs-excerpt-card" xxl={{ span: 3 }} span="24" style={{ display: "flex", padding: "0 0 0 15px" }}>
      <Card
        title={ContentTitle("Activity Logs")}
        size="small"
        loading={loading}
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
        bodyStyle={{ display: "flex", flexDirection: "column", justifyContent: "space-between", flex: 1 }}
      >
        <div style={{ flex: 1 }}>
          {logs.length ? (
            logs.slice(0, 5).map((log, key) => (
              <div key={key}>
                <Typography.Paragraph key={key} ellipsis>
                  <Popover content={log.message} overlayStyle={{ maxWidth: "400px", wordWrap: "break-word" }}>
                    <InfoCircleOutlined style={{ marginRight: "5px" }} />
                  </Popover>
                  {log.message}
                </Typography.Paragraph>
              </div>
            ))
          ) : (
            <Typography.Text>No logs found!</Typography.Text>
          )}
        </div>

        {(logs.length && (
          <Link
            to={{
              pathname: "/dashboard/activity-logs",
              search: `?patientId=${patientData.id}&patientEmail=${patientData.email}`
            }}
          >
            <Button block>See all activity</Button>
          </Link>
        )) ||
          ""}
      </Card>
    </Col>
  );
};

export default ActivityLogsExcerpt;
