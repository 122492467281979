import {
  PlusOutlined,
  ExclamationCircleFilled,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Row,
  Table,
  Typography,
  Modal,
  Input,
  notification,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apiClient, { endpoints } from "../../utils/apiClient";
import { ContentTitle } from "../ContentTitle";
import { RouteContext } from "../Dashboard";
import useFetchPatientProfile from "../PatientProfile/useFetchPatientProfile";
import Spinner from "../Spinner";
import OrderDetails from "./OrderDetails";
import OrderFiles from "./OrderFiles";
import OrderStatus from "./OrderStatus";
import { shortRiskLevels } from "../ConditionProfile/conditionStatus";
import { isNull } from "lodash";

export interface OrderProfileProperties {
  bostonReqFormName: string;
  isAnOrderAssociatedWithBoston: boolean;
  bostonHeartlabClinicId: string;
  orderStatus: number;
  reviewedBy: number;
  totalPrice: string;
  orderDetails: { labName: string; price: string }[];
  orderFiles: { title: string; patientId: string; documentId: string }[];
  orderStatusDate: string;
  transactionDate: string;
  transactionStatus: number;
  trackingLink: string | null;
  secondaryTrackingLink: string | null;
}

interface OrderProfileTableProperties {
  variableName: string;
  clinicCode: string;
  value: string;
  unit: string;
  shortRiskLevel: number;
  key: number;
  [key: string]: any;
}

interface TrackingNumberInputProps {
  val: string;
  setVal: (v: string) => void;
  title: string;
}

export const TrackingNumberInput: React.FC<TrackingNumberInputProps> = ({
  val,
  setVal,
  title,
}) => {
  const [myVal, setMyVal] = useState<string>(val);

  useEffect(() => {
    setVal(myVal);
  }, [myVal]);

  return (
    <>
      <p
        style={{
          marginBottom: 5,
        }}
      >
        {title}
      </p>
      <Input
        value={myVal}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setMyVal(e.target.value)
        }
        placeholder={title}
        style={{
          marginTop: 0,
          marginBottom: 20,
        }}
      />
    </>
  );
};

const OrderProfile = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [patientId, setPatientId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [refreshSimulator, setRefreshSimulator] = useState(false); //variable to trigger the useEffect that fetch order data
  const [breadcrumbTitle, setBreadcrumTitle] = useState("Order Profile");
  const [data, setData] = useState<OrderProfileProperties>(
    {} as OrderProfileProperties
  );
  const [patientName, setPatientName] = useState("");
  const { getConditions, loading } = useFetchPatientProfile();
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const [trackingInfo, setTrackingInfo] = useState<string>(
    data.trackingLink ?? ""
  );
  const [secondTrackingInfo, setSecondTrackingInfo] = useState<string>(
    data.secondaryTrackingLink ?? ""
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  console.log(data);

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Patients Data",
        path: "/patients",
      },
      {
        title: patientName || "Patient Profile",
        path: `/patient-profile/${patientId}`,
      },
      {
        title: breadcrumbTitle,
        path: `/order-profile/${orderId}`,
      },
    ]);
    setCurrentKey("patients");
  }, [
    breadcrumbTitle,
    orderId,
    patientId,
    patientName,
    setBreadcrumbItems,
    setCurrentKey,
  ]);

  const tableColumns = [
    {
      title: ContentTitle("Variables"),
      dataIndex: "variableName",
      width: "25%",
    },
    {
      title: ContentTitle(
        `${data.isAnOrderAssociatedWithBoston ? "Boston" : "Quest"} Clinic Code`
      ),
      dataIndex: "clinicCode",
      width: "25%",
    },
    {
      title: ContentTitle("Value"),
      dataIndex: "value",
      width: "25%",
      render: (
        text: number,
        record: OrderProfileTableProperties,
        index: number
      ) => {
        return (
          <Typography.Text
            style={{ color: shortRiskLevels[record.shortRiskLevel] }}
          >
            {text === null
              ? "-"
              : isNaN(text)
              ? text
              : parseFloat(Number(text).toFixed(3))}
          </Typography.Text>
        );
      },
    },
    {
      title: ContentTitle("Unit"),
      dataIndex: "unit",
      width: "25%",
    },
  ];

  useEffect(() => {
    getConditions(endpoints.patientsOrdersData, orderId).then(
      (responseData) => {
        if (!responseData) return;

        setData(responseData);
        setPatientId(responseData.patientId);
        setBreadcrumTitle(responseData.title);

        if (responseData.firstName && responseData.lastName) {
          setPatientName(`${responseData.firstName} ${responseData.lastName}`);
        }

        setTableData(
          responseData.variables
            ? responseData.variables.map(
                (el: OrderProfileTableProperties, key: number) => {
                  el.key = key;

                  Object.keys(el).forEach((k) => {
                    el[k] = el[k] ?? "-";
                  });

                  return el;
                }
              )
            : []
        );
      }
    );
  }, [getConditions, orderId, refreshSimulator]);

  const handleApproveOrder = () => {
    Modal.confirm({
      title: "Set Tracking Information",
      content: (
        <>
          <TrackingNumberInput
            val={trackingInfo}
            setVal={setTrackingInfo}
            title="Tracking information"
          />
          <TrackingNumberInput
            val={secondTrackingInfo}
            setVal={setSecondTrackingInfo}
            title="Secondary Tracking Information (optional)"
          />
        </>
      ),
      okText: "Ok",
      cancelText: "Cancel",
      onCancel() {
        setTrackingInfo("");
      },
      onOk() {
        setIsOpen(true);
      },
    });
  };

  useEffect(() => {
    if (isOpen)
      if (trackingInfo === "") {
        setIsOpen(false);
        notification.warn({
          message: "Warning!",
          description:
            "An order cannot be approved without first tracking information!",
          duration: 5,
        });
      } else {
        Modal.confirm({
          title: "Confirm",
          icon: <ExclamationCircleFilled />,
          content: "Are you sure you want to approve this order?",

          okText: "Yes",
          cancelText: "No",
          onCancel() {
            setIsOpen(false);
            setTrackingInfo("");
          },
          async onOk() {
            await apiClient
              .put("/orders/update-tracking-link", {
                patientOrderId: orderId,
                trackingLink: trackingInfo === "" ? null : trackingInfo,
              })
              .then(async () => {
                await apiClient
                  .put("/orders/update-secondary-tracking-link", {
                    patientOrderId: orderId,
                    trackingLink: secondTrackingInfo,
                  })
                  .then(async () => {
                    setData?.({
                      ...data,
                      trackingLink: trackingInfo,
                      secondaryTrackingLink: secondTrackingInfo,
                    });
                    await apiClient
                      .post(`/orders/approve-order/${orderId}`)
                      .then(async () => {
                        setIsOpen(false);
                        setTrackingInfo("");
                        setRefreshSimulator(!refreshSimulator);
                      })
                      .catch((err) =>
                        console.log("Approve order endpoint crashed: ", err)
                      );
                  })
                  .catch((err) => {
                    console.log("Approve order endpoint crashed: ", err);
                  });
              })
              .catch((err) => {
                console.log("Update tracking number endpoint crashed: ", err);
              });
          },
        });
      }
  }, [isOpen]);

  return (
    <>
      <div style={{ margin: "0 30px" }}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {data.orderStatus == 11 ? (
              <Link
                to={`/dashboard/add-lab-input/${patientId}/${orderId}`}
                style={{
                  position: "absolute",
                  right: "0",
                  bottom: "103%",
                  margin: "0 30px",
                }}
              >
                <Button icon={<PlusOutlined />}>Add Order Results</Button>
              </Link>
            ) : data.orderStatus == 10 ? (
              <Button
                style={{
                  position: "absolute",
                  right: "0",
                  bottom: "103%",
                  margin: "0 30px",
                }}
                onClick={handleApproveOrder}
              >
                Approve Order
              </Button>
            ) : null}

            {data.isAnOrderAssociatedWithBoston &&
              isNull(data.bostonHeartlabClinicId) && (
                <Row style={{ marginBottom: "30px" }}>
                  <Typography.Text strong style={{ color: "#d20000" }}>
                    There was an error with the order!
                  </Typography.Text>
                </Row>
              )}

            <Row align="stretch" gutter={15}>
              <OrderStatus data={data} setData={setData} />
              <OrderDetails data={data} />
              <OrderFiles data={data} />
            </Row>
            <Row style={{ margin: "30px 0 0", overflow: "auto" }}>
              <Table
                bordered
                columns={tableColumns}
                dataSource={tableData}
                pagination={{
                  size: "small",
                  hideOnSinglePage: true,
                  showSizeChanger: false,
                  pageSize: 10,
                }}
                loading={loading}
                size="small"
                style={{ width: "100%", minWidth: "max-content" }}
              />
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default OrderProfile;
