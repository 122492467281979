import { message } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../../utils/apiClient";

export interface AdminActionsProperties {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: number;
  networkName: string;
  networkId: number;
  isDeleted: boolean;
}

export interface NteworkInterface {
  id: number;
  name: string;
}

const useAdminsActions = () => {
  const history = useHistory();
  const [data, setData] = useState<AdminActionsProperties>();
  const [networks, setNetworks] = useState<NteworkInterface[]>();
  const [loading, setLoading] = useState(true);

  const getAdminData = (path: string, id?: string) => {
    const params = {};
    if (id) {
      Object.assign(params, { adminId: id });
    }
    apiClient
      .get(path, { params })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          history.push("/dashboard/patients");
        }
      });
  };

  const postAdminData = (path: string, data: any) => {
    return new Promise((resolve, reject) => {
      apiClient.post(path, data).then((response) => {
        if (response.status === 200) {
          resolve(true);
          message.success(
            `Account successfully ${
              path.includes("create-admin") ? "created" : "updated"
            }!`
          );
        } else {
          reject();
        }

        setLoading(false);
      });
    });
  };

  const deleteAdmin = async (path: string, id: number | undefined) => {
    try {
      await apiClient.delete(path, { params: { adminId: id } });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getNetworks = async () => {
    apiClient
      .post("/networks", {
        skip: 0,
        take: 99999,
        searchFilter: "",
      })
      .then((res) => {
        setNetworks(res.data.networks);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    getAdminData,
    postAdminData,
    deleteAdmin,
    data,
    setData,
    networks,
    getNetworks,
    loading,
  };
};

export default useAdminsActions;
