import { ReactComponent as Logo } from "../../assets/logoWhite.svg";

const Brand = () => {
  return (
    <div style={{ textAlign: "center", padding: "15px 0" }}>
      <Logo style={{ width: "100%", height: "41px" }} />
    </div>
  );
};

export default Brand;
