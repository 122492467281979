import { Table, Typography } from "antd";
import { PatientRisksHistoryProperties } from ".";
import { formatDate } from "../../utils/utils";
import { ContentTitle } from "../ContentTitle";
import { shortRiskLevels } from "./conditionStatus";

interface ConditionHistoryTableProperties {
  rawValue: string | undefined;
  collectedAt: string | number | Date;
  color: string;
}

const ConditionHistoryTable = ({
  data,
  currentHistoryTable
}: {
  data: PatientRisksHistoryProperties | undefined;
  currentHistoryTable: string;
}) => {
  const tableColumns = [
    {
      title: ContentTitle("Value"),
      dataIndex: "rawValue",
      render: (text: any, record: ConditionHistoryTableProperties) => (
        <Typography.Text style={{ color: record.color }}>
          {isNaN(text) ? text : parseFloat(Number(text).toFixed(2))}
        </Typography.Text>
      ),
      width: "50%"
    },
    {
      title: ContentTitle("Date"),
      dataIndex: "collectedAt",
      width: "50%"
    }
  ];

  const tableData = data
    ? data[currentHistoryTable]
      .filter(el => el.patientVariable)
      .map((el, key: number) => {
        return {
          key,
          rawValue: el.patientVariable?.rawValue,
          collectedAt: formatDate(el.collectedAt),
          color: shortRiskLevels[el.shortRiskLevel]
        };
      })
    : [];
  return (
    <Table
      columns={tableColumns}
      dataSource={tableData}
      size="small"
      bordered
      pagination={{
        size: "small",
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSize: 10
      }}
    />
  );
};

export default ConditionHistoryTable;
