import { useContext, useEffect, useReducer, useState } from "react";
import { RouteContext } from "../Dashboard";
import filterReducer from "../Navbar/filterReducer";
import { ConsultationsTableProperties, useItems } from "./useItems";
import { Link, useHistory } from "react-router-dom";
import { ContentTitle } from "../ContentTitle";
import { formatDate } from "../../utils/utils";
import { Button, Modal, Select, Table, Tag, Tooltip, Typography } from "antd";
import {
  orderStatusColors,
  orderStatusRanking,
} from "../FinancesTable/financesSortOptions";
import {
  ConsultationOrderStatus,
  consultationStatusColors,
  consultationStatusRanking,
  transactionStatusColors,
  transactionStatusRanking,
} from "./types";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  EyeFilled,
} from "@ant-design/icons";
import apiClient from "../../utils/apiClient";

export const UpdateOrderSelect = ({
  status,
  setOrderStatusUpdate,
}: {
  status: number;
  setOrderStatusUpdate: (v: number) => void;
}) => {
  const [localStatus, setLocalStatus] = useState<number>(status);

  const handleOrderStatusUpdate = (newStatus: string) => {
    setLocalStatus(parseInt(newStatus));
  };

  useEffect(() => {
    setOrderStatusUpdate(localStatus);
  }, [localStatus]);

  return (
    <Select
      placeholder="Select Order Status"
      value={localStatus + ""}
      onChange={handleOrderStatusUpdate}
      style={{ width: "100%" }}
    >
      {Object.keys(consultationStatusRanking)
        .slice(1)
        .map((key) => {
          return (
            <Select.Option key={key} value={key}>
              {consultationStatusRanking[key as unknown as number]}
            </Select.Option>
          );
        })}
    </Select>
  );
};

export const ConsultationsTable = () => {
  const [tableRows, setTableRows] = useState<ConsultationsTableProperties[]>(
    []
  );
  const {
    items,
    loading,
    totalItems,
    setLoading,
    consultationsFilters,
    refreshSimulator,
    setRefreshSimulator,
  } = useItems();
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const history = useHistory();
  const [state, dispatch] = useReducer(filterReducer, {});
  const [orderStatusUpdate, setOrderStatusUpdate] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [curentPacientConsultationId, setCurentPacientConsultationId] =
    useState<number>(0);

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Consultations",
        path: "/consultations",
      },
    ]);
    setCurrentKey("consultations");
  }, [setBreadcrumbItems, setCurrentKey]);

  const handleUpdateOrderStatus = (order: ConsultationsTableProperties) => {
    setCurentPacientConsultationId(order.patientConsultationOrderId);
    Modal.confirm({
      title: "Update Order Status",
      icon: <ExclamationCircleTwoTone color="blue" />,
      content: (
        <UpdateOrderSelect
          status={order.status}
          setOrderStatusUpdate={setOrderStatusUpdate}
        />
      ),
      okText: "Next",
      cancelText: "Cancel",
      onCancel() {},
      onOk() {
        setIsOpen(true);
      },
    });
  };

  useEffect(() => {
    if (isOpen) {
      Modal.confirm({
        title: "Are you sure?",
        content: (
          <Typography.Text>
            <Typography.Text style={{}}>
              Are you sure you want to change the order status to
            </Typography.Text>
            <Typography.Text strong style={{}}>
              {` ${consultationStatusRanking[orderStatusUpdate]}?`}
            </Typography.Text>
          </Typography.Text>
        ),
        okText: "Update",
        cancelText: "Cancel",
        onCancel() {},
        async onOk() {
          await apiClient
            .put("/consultation-orders/update-status", {
              patientConsultationOrderId: curentPacientConsultationId,
              status: orderStatusUpdate,
            })
            .then((res) => {
              setRefreshSimulator(!refreshSimulator);
              setIsOpen(false);
            })
            .catch((err) => {
              console.log("ERROR UPDATE ORDER STATUS: ", err);
            });
        },
      });
    }
  }, [isOpen]);

  const tableColumns = [
    {
      title: ContentTitle("Order No."),
      dataIndex: "patientConsultationOrderId",
    },

    {
      title: ContentTitle("Tx Id"),
      dataIndex: "transactionId",
    },
    {
      title: ContentTitle("Consultation Name"),
      dataIndex: "consultationName",
    },
    {
      title: ContentTitle("Patient Name"),
      dataIndex: "patientName",
    },
    {
      title: ContentTitle("Email"),
      dataIndex: "patientEmail",
    },
    {
      title: ContentTitle("Date"),
      dataIndex: "date",
    },
    {
      title: ContentTitle("Price"),
      dataIndex: "price",
    },
    {
      title: ContentTitle("Order Status"),
      dataIndex: "status",
      render: (status: number, record: ConsultationsTableProperties) =>
        status !== undefined && (
          <Tag color={consultationStatusColors[status]}>
            {consultationStatusRanking[status]}
          </Tag>
        ),
    },
    {
      title: ContentTitle("Transaction Status"),
      dataIndex: "transactionStatus",
      render: (status: number) => (
        <Tag color={transactionStatusColors[status]}>
          {transactionStatusRanking[status]}
        </Tag>
      ),
    },
    {
      title: ContentTitle("Action"),
      dataIndex: "action",
      render: (text: string, record: ConsultationsTableProperties) => {
        return (
          <div style={{ minWidth: 110 }}>
            <Link
              to={`/dashboard/consultation-details/${record.patientConsultationOrderId}/${record.patientName}/${record.patientId}`}
            >
              <Tooltip
                placement="left"
                title={`Go to Consultation No.${record.patientConsultationOrderId}`}
              >
                <Button type="text">
                  <EyeFilled style={{ color: "#ACACAC", fontSize: "17px" }} />
                </Button>
              </Tooltip>
            </Link>

            <Tooltip placement="left" title="Update Order Status">
              <Button
                type="text"
                onClick={() => handleUpdateOrderStatus(record)}
              >
                <EditOutlined style={{ color: "#ACACAC", fontSize: "17px" }} />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setTableRows(
      items.map((item, key) => {
        Object.keys(item).forEach((k) => {
          item[k] = item[k] ?? "-";
        });

        return {
          ...item,
          key,
          price: "$" + item.price,
          date: formatDate(item.date),
        };
      })
    );
  }, [items]);

  const handlePageUpdate = (page: number, pageSize: number | undefined) => {
    dispatch({
      type: "UPDATE_FILTERS",
      paramKey: "page",
      paramValue: page.toString(),
      history,
    });

    setLoading(true);
  };

  return (
    <div style={{ margin: "10px 30px", overflow: "auto" }}>
      <Table
        columns={tableColumns}
        dataSource={tableRows}
        style={{ minWidth: "max-content" }}
        loading={loading}
        size="small"
        pagination={{
          size: "small",
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize: 10,
          current: consultationsFilters ? consultationsFilters.currentPage : 1,
          total: totalItems,
          showTotal: (total) => `${tableRows.length} of ${total} results`,
          onChange: handlePageUpdate,
        }}
      />
    </div>
  );
};
