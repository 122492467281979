import { Row, Col, notification } from "antd";
import { ReactComponent as Logo } from "../../assets/logoBlue.svg";
import { ReactComponent as LandingImage } from "../../assets/admin.svg";
import MHPFooter from "../Dashboard/MHPFooter";
import { useContext, useEffect, useState } from "react";
import LoginForm from "./LoginForm";
import TwoFactorForm from "./TwoFactorForm";
import { AuthContext } from "../../App";

const LoginPage = () => {
  const auth = useContext(AuthContext);
  const [needs2FAValidation, setNeeds2FAValidation] = useState(false);

  useEffect(() => {
    const loginError = localStorage.getItem("loginError");
    if (loginError) {
      notification.error({
        message: "Error!",
        description: loginError,
        duration: 7
      });
      localStorage.removeItem("loginError");
    }
  }, []);

  return (
    <Row justify="center" align="stretch" style={{ height: "100vh", overflow: "hidden" }}>
      <Col className="login-hero-img" span="12" style={{ backgroundColor: "#F0F7FD" }}>
        <LandingImage style={{ width: "100%", height: "100%", padding: "0 100px" }} />
      </Col>
      <Col span="12">
        <div
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div style={{ margin: "20% 0 auto", textAlign: "center" }}>
            <Logo style={{ width: "100%", height: "41px" }} />
            {needs2FAValidation ? (
              <TwoFactorForm auth={auth} setNeeds2FAValidation={setNeeds2FAValidation} />
            ) : (
              <LoginForm auth={auth} setNeeds2FAValidation={setNeeds2FAValidation} />
            )}
          </div>
          <MHPFooter />
        </div>
      </Col>
    </Row>
  );
};

export default LoginPage;
