import { Card, Col, Space, Tag, Typography } from "antd";
import { FailedPurchaseProperties } from ".";
import { formatDate } from "../../utils/utils";
import { ContentTitle } from "../ContentTitle";
import { transactionStatus, transactionStatusColors } from "../PatientProfile/orderStatus";

const FailedPurchaseStatus = ({ data }: { data: FailedPurchaseProperties }) => {

    const purchaseStatusArr = [
        {
            title: "Transaction Status",
            date: formatDate(data?.createdAt),
            status: transactionStatus[data?.transactionStatus],
            color: transactionStatusColors[data?.transactionStatus]
        },
        {
            title: "Stripe Transaction Status",
            date: formatDate(data?.createdAt),
            status: data?.rawStatus,
            color: "error"
        }
    ];

    return (
        <Col xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Card title={ContentTitle("Purchase Status")} size="small">
                {purchaseStatusArr.map((purchase, key) => (
                    (purchase.status != null) && (<div key={key}>
                        <Typography.Text type="secondary" style={{ fontSize: "13px" }}>
                            {purchase.title}
                        </Typography.Text>
                        <Typography.Paragraph strong style={{ fontSize: "13px" }}>
                            <Space style={{ fontSize: "13px" }} align="center">
                                {purchase.date}
                                <div style={{ width: "4px", height: "4px", backgroundColor: "#000", borderRadius: "50%" }}></div>
                                <Tag color={purchase.color}>{purchase.status}</Tag>
                            </Space>
                        </Typography.Paragraph>
                    </div>)
                ))}
            </Card>
        </Col>
    );
};

export default FailedPurchaseStatus;
