import { Card, Typography } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { endpoints } from "../../utils/apiClient";
import { formatDate } from "../../utils/utils";
import { ContentTitle } from "../ContentTitle";
import useFetchPatientProfile from "./useFetchPatientProfile";

interface DataSourceProperties {
  name: string;
  description: string;
  ownerName: string;
  dataSourceType: number;
}

interface SurveyProperties {
  date: string;
  createdAt: string;
  updatedAt: string;
  patientId: number;
  dataSourceId: number;
  id: number;
  dataSource: DataSourceProperties;
}

const Surveys = ({ id }: { id: number }) => {
  const [reports, setReports] = useState<SurveyProperties[]>([]);
  const { getConditions, loading } = useFetchPatientProfile();

  useEffect(() => {
    getConditions(endpoints.patientsSurveys, id).then(responseData => {
      setReports(
        responseData.map((el: SurveyProperties) => ({
          ...el,
          date: formatDate(el.createdAt)
        }))
      );
    });
  }, [getConditions, id]);

  return (
    <Card
      title={ContentTitle("Surveys")}
      size="small"
      loading={loading}
      style={{ margin: "0 0 15px" }}
      bodyStyle={{ maxHeight: "400px", overflow: "hidden", overflowY: "auto" }}
    >
      {reports?.length ? (
        reports.map((report, key) => (
          <div key={key}>
            <Typography.Text strong style={{ color: "#4285F4", fontSize: "13px" }}>
              <Link to={`/dashboard/survey-profile/${report.id}`}>{report.dataSource.name}</Link>
            </Typography.Text>
            <Typography.Paragraph type="secondary" style={{ fontSize: "13px" }}>
              {report.date}
            </Typography.Paragraph>
          </div>
        ))
      ) : (
        <Typography.Text>No data available!</Typography.Text>
      )}
    </Card>
  );
};

export default Surveys;
