import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { Button, Modal, Row, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../../utils/apiClient";
import { UpdateOrderSelect } from "../ConsultationsTable";
import { consultationStatusRanking } from "../ConsultationsTable/types";
import { RouteContext } from "../Dashboard";
import Spinner from "../Spinner";
import { ConsultInfo } from "./ConsultInfo";
import { NetworkDetails } from "./NetworkDetails";
import { OrderDetails } from "./OrderDetails";
import { OrderStatus } from "./OrderStatus";

export interface ConsultationDetailsProps {
  title: string;
  date: string;
  price: number;
  networkPhoneNumber: string;
  networkEmail: string;
  networkWebsite: string;
  consultationName: string;
  status: number;
  consultationDescription: string;
  transactionStatus: number;
}

export const ConsultationDetails = () => {
  const { consultationId, patientName, patientId } = useParams<{
    consultationId: string;
    patientName: string;
    patientId: string;
  }>();
  const [refreshSimulator, setRefreshSimulator] = useState(false); //variable to trigger the useEffect that fetch order data
  const [breadcrumbTitle, setBreadcrumTitle] = useState("Consultation Details");
  const [data, setData] = useState<ConsultationDetailsProps>(
    {} as ConsultationDetailsProps
  );
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const [loading, setLoading] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [orderStatusUpdate, setOrderStatusUpdate] = useState<number>(0);

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Consultations",
        path: "/consultations",
      },
      {
        title: patientName || "Patient Profile",
        path: `/patient-profile/${parseInt(patientId)}`,
      },
      {
        title: breadcrumbTitle,
        path: `/consultation-details/${consultationId}`,
      },
    ]);
    setCurrentKey("consultations");
  }, [
    breadcrumbTitle,
    consultationId,
    patientName,
    patientId,
    setBreadcrumbItems,
    setCurrentKey,
  ]);

  useEffect(() => {
    setLoading(true);

    apiClient
      .get(`consultation-orders/details/${consultationId}`)
      .then((res) => {
        console.log("CONSULT DETAILS: ", res.data);

        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("ERROR GETTING CONSULT DETAILS: ", err);
      });
  }, [consultationId, refreshSimulator]);

  const refreshPage = () => {
    setRefreshSimulator(!refreshSimulator);
  };

  const handleUpdateOrderStatus = (status: number) => {
    Modal.confirm({
      title: "Update Order Status",
      icon: <ExclamationCircleTwoTone color="blue" />,
      content: (
        <UpdateOrderSelect
          status={status}
          setOrderStatusUpdate={setOrderStatusUpdate}
        />
      ),
      okText: "Next",
      cancelText: "Cancel",
      onCancel() {},
      onOk() {
        setIsOpen(true);
      },
    });
  };

  useEffect(() => {
    if (isOpen) {
      Modal.confirm({
        title: "Are you sure?",
        content: (
          <Typography.Text>
            <Typography.Text style={{}}>
              Are you sure you want to change the order status to
            </Typography.Text>
            <Typography.Text strong style={{}}>
              {` ${consultationStatusRanking[orderStatusUpdate]}?`}
            </Typography.Text>
          </Typography.Text>
        ),
        okText: "Update",
        cancelText: "Cancel",
        onCancel() {},
        async onOk() {
          await apiClient
            .put("/consultation-orders/update-status", {
              patientConsultationOrderId: consultationId,
              status: orderStatusUpdate,
            })
            .then((res) => {
              setIsOpen(false);
              setRefreshSimulator(!refreshSimulator);
            })
            .catch((err) => {
              console.log("ERROR UPDATE ORDER STATUS: ", err);
            });
        },
      });
    }
  }, [isOpen]);

  return (
    <>
      <div style={{ margin: "0 30px" }}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Button
              style={{
                position: "absolute",
                right: "0",
                bottom: "103%",
                margin: "0 30px",
              }}
              onClick={() => handleUpdateOrderStatus(data.status)}
            >
              Update Order Status
            </Button>
            <Row align="stretch" gutter={15}>
              <OrderStatus data={data} />
              <OrderDetails data={data} />
              <NetworkDetails data={data} />
            </Row>

            <Row align="stretch">
              <ConsultInfo data={data} />
            </Row>
          </>
        )}
      </div>
    </>
  );
};
