import { useState } from "react";
import apiClient from "../../utils/apiClient";

const useFetchEmails = () => {
  const [loading, setLoading] = useState(false);

  const fetchEmails = (path: string, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(path, { params })
        .then(response => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject();
          }
        })
        .then(() => {
          setLoading(false);
        });
    });
  };

  return { loading, setLoading, fetchEmails };
};

export default useFetchEmails;
