import { DownloadOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useState } from "react";
import useDownloadReport from "../Navbar/useDownloadReport";

export const ProviderReport = ({
  isResponsive,
  patientId,
}: {
  patientId: string;
  isResponsive?: boolean;
}) => {
  const { downloadProviderReport } = useDownloadReport();

  const [loading, setLoading] = useState(false);

  const handleDownloadReport = async () => {
    setLoading(true);

    let report = await downloadProviderReport(patientId);

    setLoading(false);
    Modal.confirm({
      title: "Your report is ready!",
      okText: (
        <a href={URL.createObjectURL(report)} download="Provider-Report.xlsx">
          Download
        </a>
      ),
    });
  };

  return (
    <Button
      block={isResponsive}
      loading={loading}
      onClick={handleDownloadReport}
      icon={<DownloadOutlined />}
      style={{ margin: isResponsive ? "10px 0 0" : "0" }}
    >
      Provider Report
    </Button>
  );
};
