import { Link } from "react-router-dom";
import { Badge, Menu, Typography } from "antd";
import {
  AuditOutlined,
  BarChartOutlined,
  ContactsOutlined,
  GroupOutlined,
  HistoryOutlined,
  TagFilled,
  TeamOutlined,
  UnorderedListOutlined,
  WarningFilled,
} from "@ant-design/icons";
import { useContext } from "react";
import useAccessPermissions from "../../utils/useAccessPermissions";
import { RouteContext } from "../Dashboard";
interface SidebarListProps {
  clinicErrorsCount: number;
  purchasedConsultationsCount: number;
}
const SidebarList = (props: SidebarListProps) => {
  const { currentKey } = useContext(RouteContext);
  const { hasMedicalAccess, hasGeneralAccess } = useAccessPermissions();

  const menuData = [
    { text: "Overview", path: "overview", icon: <BarChartOutlined /> },
    { text: "Finances", path: "finances", icon: <UnorderedListOutlined /> },
    {
      text: "Consultations",
      path: "consultations",
      icon:
        props.purchasedConsultationsCount > 0 ? (
          <AuditOutlined style={{ color: "orange" }} />
        ) : (
          <AuditOutlined />
        ),
    },
    { text: "Patients Data", path: "patients", icon: <ContactsOutlined /> },
    { text: "Networks", path: "networks", icon: <GroupOutlined /> },
    { text: "Admins", path: "admins", icon: <TeamOutlined /> },
    { text: "Activity Logs", path: "activity-logs", icon: <HistoryOutlined /> },
    { text: "Shop Offers", path: "shop-offers", icon: <TagFilled /> },

    {
      text: "Admin Warnings",
      path: "admin-warnings",
      icon:
        props.clinicErrorsCount > 0 ? (
          <WarningFilled style={{ color: "orange" }} />
        ) : (
          <WarningFilled />
        ),
    },
  ];

  const keyCollection: { [key: string]: string } = {
    none: "-1",
    overview: "0",
    finances: "1",
    consultations: "2",
    patients: "3",
    networks: "4",
    admins: "5",
    "activity-logs": "6",
    "shop-offers": "7",
    "admin-warnings": "8",
  };

  const displayConditions = (path: string) => {
    if (hasMedicalAccess) {
      return [
        "overview",
        "admins",
        "networks",
        "shop-offers",
        "admin-warnings",
      ].includes(path);
    }
    if (hasGeneralAccess) {
      return ["admins", "networks"].includes(path);
    }
    return false;
  };

  const color = (key: any, currentKey: any) => {
    if (currentKey[0] == key) return "#F0F8FF";
    else return "";
  };

  return (
    <Menu
      selectedKeys={[keyCollection[currentKey]]}
      style={{ backgroundColor: "transparent", color: "#fff" }}
    >
      {menuData.map((item, key) => (
        <Menu.Item
          key={key}
          icon={item.icon}
          style={{
            paddingLeft: "20px",
            fontSize: "16px",
            color: color(key, [keyCollection[currentKey]]),
            display: displayConditions(item.path) ? "none" : "",
          }}
        >
          <Link to={`/dashboard/${item.path}`}>{item.text}</Link>
          {item.text.toLowerCase() === "consultations" && (
            <Badge
              count={props.purchasedConsultationsCount}
              style={{
                marginLeft: 10,
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "rgb(255,	250,	224)",
                border: "none",
                color: "rgb(202,	120,	43	)",
              }}
            />
          )}
          {item.text.toLowerCase() === "admin warnings" && (
            <Badge
              count={props.clinicErrorsCount}
              style={{
                marginLeft: 10,
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "rgb(255,	250,	224)",
                border: "none",
                color: "rgb(202,	120,	43	)",
              }}
            />
          )}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default SidebarList;
