import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { endpoints } from "../../utils/apiClient";
import { ContentTitle } from "../ContentTitle";
import { RouteContext } from "../Dashboard";
import useAdminsActions from "../AdminProfile/useAdminsActions";
import useAccessPermissions from "../../utils/useAccessPermissions";
import { validatePhoneNumber } from "./phoneValidator";
import Spinner from "../Spinner";

interface AddNewAdminFormParameters {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  networkId: number;
  password: string;
  confirmPassword: string;
}

const AddNewAdmin = () => {
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const { networks, getNetworks, postAdminData } = useAdminsActions();
  const { hasMedicalAccess, hasGeneralAccess } = useAccessPermissions();
  const history = useHistory();

  const [showNetworks, setShowNetworks] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getNetworks().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (hasMedicalAccess || hasGeneralAccess) {
      history.push("/dashboard/patients");
      message.error("Unauthorized to access this resource!");
    }
  }, [hasGeneralAccess, hasMedicalAccess, history]);

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Admins",
        path: "/admins",
      },
      {
        title: "Add New",
        path: "/add-new-admin",
      },
    ]);
    setCurrentKey("admins");
  }, [setBreadcrumbItems, setCurrentKey]);

  const handleFormSubmit = async (values: AddNewAdminFormParameters) => {
    const response = await postAdminData(endpoints.createAdmin, values);
    if (response) {
      history.push("/dashboard/admins");
    }
  };

  if (loading) return <Spinner />;

  return (
    <Row style={{ margin: "0 30px" }}>
      <Col span="7">
        <Card size="small" title={ContentTitle("Add new admin")}>
          <Form
            name="createAdmin"
            layout="vertical"
            onFinish={handleFormSubmit}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
                {
                  min: 3,
                  message: "Your name should have at least 3 characters!",
                },
              ]}
              style={{ marginBottom: "10px" }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
                {
                  min: 3,
                  message: "Your name should have at least 3 characters!",
                },
              ]}
              style={{ marginBottom: "10px" }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input a valid email address!",
                },
              ]}
              style={{ marginBottom: "10px" }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              hasFeedback
              rules={[
                {
                  type: "string",
                },
                () => ({
                  validator(_, value) {
                    const validationResult = validatePhoneNumber(value);
                    if (validationResult.isSuccessful === true)
                      return Promise.resolve();
                    return Promise.reject(new Error(validationResult.message));
                  },
                }),
              ]}
              style={{ marginBottom: "10px" }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Role"
              name="role"
              style={{ marginBottom: "10px" }}
              rules={[{ required: true, message: "Please select a role!" }]}
            >
              <Select
                placeholder="Select from dropdown"
                allowClear
                onChange={(val) => {
                  setShowNetworks(val == 10);
                }}
              >
                <Select.Option value="10">Medical Access</Select.Option>
                <Select.Option value="50">General Access</Select.Option>
                <Select.Option value="100">Full Access</Select.Option>
              </Select>
            </Form.Item>

            {showNetworks && (
              <Form.Item
                label="Network"
                name="networkId"
                style={{ marginBottom: "10px" }}
                rules={[
                  { required: true, message: "Please select a network!" },
                ]}
              >
                <Select placeholder="Select from dropdown" allowClear>
                  {networks?.map((n: any) => {
                    return (
                      <Select.Option key={n.id} value={n.id}>
                        {n.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}

            <Form.Item
              label="Password"
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 5,
                  message: "Your password should have at least 5 characters!",
                },
              ]}
              style={{ marginBottom: "10px" }}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              hasFeedback
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                {
                  min: 5,
                  message: "Your password should have at least 5 characters!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Space style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button>
                  <Link to="/dashboard/admins">Cancel</Link>
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default AddNewAdmin;
