import { Form, Select } from "antd";
import { useContext } from "react";
import { RouterProps } from "react-router-dom";
import { RouteContext } from "../Dashboard";

const OverviewDates = ({ dispatch, history }: { dispatch: React.Dispatch<any>; history: RouterProps["history"] }) => {
  const { filters } = useContext(RouteContext);

  const handleLogLevelSelect = (value: string) => {
    dispatch({ type: "UPDATE_FILTERS", paramKey: "days", paramValue: value, history });
  };

  return (
    <Form.Item name="overview-dates" style={{ margin: "0" }}>
      <Select defaultValue={filters?.days || "30"} onChange={handleLogLevelSelect} style={{ width: "180px" }}>
        <Select.Option value="7">Last 7 days</Select.Option>
        <Select.Option value="30">Last 30 days</Select.Option>
        <Select.Option value="90">Last 3 months</Select.Option>
      </Select>
    </Form.Item>
  );
};

export default OverviewDates;
