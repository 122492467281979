import { Card, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import { conditionStatus, conditionStatusColors } from "../ConditionProfile/conditionStatus";
import { ContentTitle } from "../ContentTitle";
import Spinner from "../Spinner";
import { PatientConditionsProperties } from "./PatientConditions";

const ConditionsCard = ({
  data,
  loading,
  title
}: {
  data: PatientConditionsProperties | undefined;
  loading: boolean;
  title: string;
}) => {
  return (
    <Card
      title={ContentTitle(title)}
      size="small"
      style={{ flex: "1", margin: title === "Inflammation" ? "0 0 15px" : "0" }}
      bodyStyle={{ textAlign: "center" }}
      loading={loading}
    >
      {loading ? (
        <Spinner />
      ) : data ? (
        <Link to={`/dashboard/condition-profile/${data.id}`}>
          <Tag style={{ width: "100%" }} color={conditionStatusColors[data.conditionResultLevel]}>
            {conditionStatus[data.conditionResultLevel]}
          </Tag>
        </Link>
      ) : (
        <Typography.Paragraph>No data available!</Typography.Paragraph>
      )}
    </Card>
  );
};

export default ConditionsCard;
