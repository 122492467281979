import {Select} from "antd";

export const processOrderStatuses = (transactionStatus: string) => {
    if(transactionStatus === 'Succeeded')
        return  ['Awaiting Review', 'Approved', 'Partial Results', 'Completed']
    return [];
 }

export const processTransactionStatuses = (orderStatus: string) => {
    if(orderStatus === 'AwaitingReview' || orderStatus === 'Approved' || orderStatus === 'Completed' || orderStatus == 'PartialResults')
        return ['Succeeded'];
    return [];
}

export const processItems = (items: string[]) => {
    const filterOptions = items.map((item) => {
      return  <Select.Option key = {item} value={item.replace(/\s/g, '')}>{item}</Select.Option>
    });

    return filterOptions;
}
