import { Form, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { RouterProps, useLocation } from "react-router-dom";

const SearchInput = ({
  dispatch,
  history,
}: {
  dispatch: React.Dispatch<any>;
  history: RouterProps["history"];
}) => {
  const [placeholder, setPlaceholder] = useState("orders");
  const { pathname } = history.location;
  const searchParams = new URLSearchParams(useLocation().search);
  const [style, setStyle] = useState({ width: "340px" });

  useEffect(() => {
    setPlaceholder(
      pathname.includes("finances")
        ? "orders"
        : pathname.includes("admins")
        ? "admins"
        : pathname.includes("networks")
        ? "networks"
        : pathname.includes("activity-log")
        ? "logs"
        : pathname.includes("admin-warnings")
        ? "orders"
        : "patients"
    );

    pathname.includes("activity-log") ||
    pathname.includes("finances") ||
    pathname.includes("patients") ||
    pathname.includes("consultations")
      ? setStyle({ width: "200px" })
      : setStyle({ width: "340px" });
  }, [pathname]);

  const handleInput = (e: any) => {
    const { value } = e.target;
    dispatch({
      type: "UPDATE_BULK_FILTERS",
      paramCollection: pathname.includes("activity-log")
        ? { messageSequence: value, page: 1 }
        : { searchFilter: value, page: 1 },
      history,
    });
  };

  return (
    <Form.Item name="search-input" style={{ margin: "0" }}>
      <Input
        allowClear
        style={style}
        placeholder={`Search here for ${placeholder}`}
        prefix={<SearchOutlined />}
        onChange={debounce(handleInput, 500)}
        onPressEnter={handleInput}
        defaultValue={
          searchParams?.get("searchFilter")?.toString() ??
          searchParams?.get("messageSequence")?.toString()
        }
      />
    </Form.Item>
  );
};

export default SearchInput;
