import { useState } from "react";
import apiClient from "../../utils/apiClient";

const useFetchSidebarData = () => {
  const [loading, setLoading] = useState(false);

  const fetchWarningsCount = (path: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      apiClient.get(path).then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject();
        }
      });
      // .then(() => {
      //   setLoading(false);
      // });
    });
  };

  const fetchConsultationsPurchasedCount = (path: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(path)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject();
          }
        })
        .then(() => {
          setLoading(false);
        });
    });
  };

  return {
    loading,
    setLoading,
    fetchWarningsCount,
    fetchConsultationsPurchasedCount,
  };
};

export default useFetchSidebarData;
