import { CalendarOutlined } from "@ant-design/icons"
import { Card } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { BlackText, GreyText, InfoRow } from "."
import { ContentTitle } from "../ContentTitle"
import Spinner from "../Spinner"
import useFetchPatientProfile from "./useFetchPatientProfile"

export const GeneticsSubmit = ({ id, dataType }: { id: number, dataType: number }) => {
    const [variableData, setVariableData] = useState<any[]>([])

    const { getManualVariables, loading } = useFetchPatientProfile()

    useEffect(() => {
        getManualVariables(id, dataType).then(data => {
            setVariableData(data.manualLabsInputs)
        })
    }, [])

    return (
        <>
            {loading === true ? (<Spinner />) : (
                <Card
                    size="small"
                    title={ContentTitle("Genetics Submit")}
                    style={{ marginBottom: "15px", marginLeft: "10px" }}
                    bodyStyle={{ maxHeight: "400px", overflow: "hidden", overflowY: "auto" }}
                >
                    {Object.keys(variableData).length === 0 ? ("No data available!") : (

                        Object.keys(variableData).map((data: any, index: any) => (
                            <Card
                                title={<div>
                                    <CalendarOutlined /> {moment(Object.keys(variableData)[index]).format("MM/DD/YYYY")}
                                </div>}
                                type="inner"
                                size="small"
                                style={{ margin: "0 0 15px" }}
                            >
                                {Object.keys(variableData[data]).map((item: any) => (
                                    <InfoRow>
                                        <GreyText>{variableData[data][item].name}</GreyText>
                                        <BlackText>{variableData[data][item].value}</BlackText>
                                    </InfoRow>
                                )
                                )}
                            </Card>
                        ))

                    )}

                </Card>
            )}
        </>
    )
}
