import { Card, Col, Modal, Row, Typography, notification } from "antd";
import { PatientDataProperties } from ".";
import { formatDate } from "../../utils/utils";
import { ContentTitle } from "../ContentTitle";
import { deviceTypes } from "./deviceTypes";
import apiClient from "../../utils/apiClient";
import { DeleteTwoTone, ExclamationCircleFilled } from "@ant-design/icons";
import useAccessPermissions from "../../utils/useAccessPermissions";

const PatientData = ({
  data: {
    id,
    dateOfBirth,
    email,
    firstName,
    lastName,
    gender,
    state,
    registerDate,
    emailConfirmed,
    authProvider,
    emailConfirmedAt,
    deviceType,
  },
  patientNetworks,
  setPatientNetworks,
}: {
  data: PatientDataProperties;
  patientNetworks: { networkName: string; networkId: number }[];
  setPatientNetworks: (
    val: { networkName: string; networkId: number }[]
  ) => void;
}) => {
  //birthday date process
  let birthDate: string;
  let joinedAt: string;
  birthDate = formatDate(dateOfBirth).toString();
  joinedAt = formatDate(registerDate, true).toString();

  const { hasFullAccess } = useAccessPermissions();

  const dataArr = [
    [{ title: "First Name", value: firstName ? firstName : "Unknown" }],
    [{ title: "Last Name", value: lastName ? lastName : "Unknown" }],
    [{ title: "Email", value: email }],
    [
      {
        title: "State",
        value: state,
      },
    ],
    [
      {
        title: "Sex",
        value: gender ? gender : "Unknown",
      },
      {
        title: "Date Of Birth",
        value: birthDate.substr(6, 4) < "1900" ? "-" : formatDate(dateOfBirth),
      },
    ],
    [
      {
        title: "Auth provider",
        value: authProvider,
      },
      {
        title: "Device Type",
        value: deviceTypes[deviceType],
      },
    ],
    [
      {
        title: "Register Date",
        value: joinedAt.substr(6, 4) < "1900" ? "-" : joinedAt,
      },
    ],
    [
      {
        title: "Email Confirmed",
        value: emailConfirmed
          ? emailConfirmedAt
            ? "Yes, on " + formatDate(emailConfirmedAt, true)
            : "Yes"
          : "No",
      },
    ],
  ];

  const handleRemoveFromNetwork = (networkId: number) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleFilled />,
      content: `Are you sure you want to remove the patient from ${
        patientNetworks.filter((n) => n.networkId === networkId)[0].networkName
      }?`,

      okText: "Yes",
      cancelText: "No",
      async onOk() {
        await apiClient
          .delete(`/networks/remove-patient/${id}/from-network/${networkId}`)
          .then(() => {
            notification.success({
              message: "Success!",
              description: "Patient deleted successfully!",
              duration: 5,
            });

            setPatientNetworks(
              patientNetworks.filter((n) => n.networkId !== networkId)
            );
          })
          .catch((err) => {
            console.log("Remove patient from network endpoint crashed: ", err);
          });
      },
    });
  };

  return (
    <Col
      className="patient-data-card"
      xxl={{ span: 4 }}
      xl={{ span: 6 }}
      span="24"
      style={{ paddingLeft: "0" }}
    >
      <Card
        title={ContentTitle(`${firstName || ""} ${lastName || ""}`)}
        size="small"
        style={{ height: "100%" }}
      >
        {dataArr.map((items, key) => (
          <Row key={key}>
            {items.map((item, key) => (
              <Col span={24 / items.length}>
                <Typography.Text type="secondary">{item.title}</Typography.Text>
                <Typography.Paragraph strong>{item.value}</Typography.Paragraph>
              </Col>
            ))}
          </Row>
        ))}
        {
          <Row key={dataArr.length + 2}>
            <Col
              span={24}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography.Text type="secondary">Networks</Typography.Text>
              {patientNetworks?.length ? (
                patientNetworks.map((network, key) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      textAlign: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Typography.Paragraph
                      style={{ fontSize: "13px", margin: 0, marginRight: 10 }}
                    >
                      {network.networkName}
                    </Typography.Paragraph>
                    {hasFullAccess && (
                      <DeleteTwoTone
                        twoToneColor="red"
                        onClick={() => {
                          handleRemoveFromNetwork(network.networkId);
                        }}
                      />
                    )}
                  </div>
                ))
              ) : (
                <Typography.Text>No networks available!</Typography.Text>
              )}
            </Col>
          </Row>
        }
      </Card>
    </Col>
  );
};

export default PatientData;
