export const conditionStatus: { [key: number]: string } = {
  1: "Present",
  2: "Not Present",
  3: "Unknown",
  10: "High",
  11: "Moderate",
  12: "Low",
  13: "Unknown"
};

export const conditionStatusColors: { [key: number]: string } = {
  1: "error",
  2: "default",
  3: "processing",
  10: "#DB0000",
  11: "#F48842",
  12: "#018806",
  13: "#4285F4"
};

export const shortRiskLevels = ["", "#4285F4", "#018806", "#F48842", "#DB0000"];
