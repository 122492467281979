import { Button, Checkbox, Form, Input, Space, Typography } from "antd";
import { AuthProperties } from "./useAuth";

export interface LoginFormProperties {
  email: string;
  password: string;
  remember: boolean;
}

const LoginForm = ({ auth, setNeeds2FAValidation }: { auth: AuthProperties | null; setNeeds2FAValidation: Function }) => {
  const onFinish = async (values: LoginFormProperties) => {
    const response = await auth?.attemptToLogin(values);
    setNeeds2FAValidation(response);
  };

  return (
    <>
      <Typography.Title level={5} style={{ margin: "5% 0" }}>
        Login to your account
      </Typography.Title>

      <Form
        layout="vertical"
        name="login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ width: "330px", margin: "auto" }}
        requiredMark={false}
      >
        <Form.Item
          className="formItem"
          label="Email"
          name="email"
          rules={[{ required: true, type: "email", message: "Please input your email!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="formItem"
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Space align="center" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          {/* <Form.Item className="formItem">
            <Button type="text" style={{ paddingRight: "0" }}>
              Forgot password?
            </Button>
          </Form.Item> */}
        </Space>

        <Form.Item className="formItem">
          <Button type="primary" block htmlType="submit" style={{ backgroundColor: "#4285F4" }} loading={auth?.loading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginForm;
