import { Button, Card, Col, Form, Input, Space } from "antd";
import { endpoints } from "../../utils/apiClient";
import { ContentTitle } from "../ContentTitle";
import useAdminsActions from "./useAdminsActions";

interface ChangePassFormProperties {
  adminId: number;
  newPassword: string;
  confirmPassword: string;
}

const ChangePass = ({ setShowChangePass, id }: any) => {
  const { postAdminData } = useAdminsActions();
  const [form] = Form.useForm();

  const handleChangePass = async (values: ChangePassFormProperties) => {
    const response = await postAdminData(endpoints.changeAdminPassword, values);
    response && form.resetFields();
  };

  return (
    <Col span="8">
      <Card title={ContentTitle("Change Password")} size="small">
        <Form name="changeAdminPassword" form={form} layout="vertical" requiredMark={false} onFinish={handleChangePass}>
          <Form.Item hidden name="adminId" initialValue={id} />

          <Form.Item
            label="Password"
            name="newPassword"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input your password!"
              },
              {
                min: 5,
                message: "Your password should have at least 5 characters!"
              }
            ]}
            style={{ marginBottom: "10px" }}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            hasFeedback
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!"
              },
              {
                min: 5,
                message: "Your password should have at least 5 characters!"
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The two passwords do not match!"));
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={setShowChangePass.bind(null, false)}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Col>
  );
};

export default ChangePass;
