import { Button, Form, Input, Typography } from "antd";
import { useEffect, useRef } from "react";
import { AuthProperties } from "./useAuth";

export interface TwoFactorFormProperties {
  code: string;
}

const TwoFactorForm = ({ auth, setNeeds2FAValidation }: { auth: AuthProperties | null; setNeeds2FAValidation: Function }) => {
  const inputRef = useRef<Input | null>(null);

  useEffect(() => {
    auth?.setLoading(false);
    inputRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values: TwoFactorFormProperties) => {
    auth?.validate2FA(values);
  };

  const handleBackBtn = () => {
    setNeeds2FAValidation(false);
  };

  return (
    <>
      <Typography.Title level={5} style={{ margin: "5% 0" }}>
        Verify that it's you.
      </Typography.Title>

      <Typography.Paragraph style={{ width: "330px", margin: "30px auto 40px", textAlign: "left" }}>
        We have sent a verification code to the phone number attached to your account.
      </Typography.Paragraph>

      <Form
        layout="vertical"
        name="twofactorauth"
        onFinish={onFinish}
        style={{ width: "330px", margin: "auto" }}
        requiredMark={false}
      >
        <Form.Item label="Verification code" name="code" rules={[{ required: true, message: "Please input your code!" }]}>
          <Input ref={inputRef} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" block htmlType="submit" style={{ backgroundColor: "#4285F4" }} loading={auth?.loading}>
            Verify and login
          </Button>
        </Form.Item>
      </Form>

      <Button type="text" onClick={handleBackBtn} style={{ margin: "30px 0 0" }}>
        Back to <Typography.Text style={{ margin: "0 0 0 4px", color: "#4285F4" }}>Login</Typography.Text>
      </Button>
    </>
  );
};

export default TwoFactorForm;
