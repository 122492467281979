import { useParams } from "react-router-dom";
import { endpoints } from "../../utils/apiClient";
import useFetchPatientProfile from "../PatientProfile/useFetchPatientProfile";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "antd";
import AboutCondition from "./AboutCondition";
import ConditionVariablesTable from "./ConditionVariablesTable";
import ConditionHistoryTable from "./ConditionHistoryTable";
import { RouteContext } from "../Dashboard";
import Spinner from "../Spinner";

interface PatientVariableProperties {
  patientId: number;
  rawValue: string;
  variableName: string;
  booleanValue: boolean;
  valueType: string;
  choiceValue: string | null;
  variableCode: string;
  decimalValue: string | null;
  id: number;
  updatedAt: string;
}

export interface PatientRiskFactorsProperties {
  longRiskLevel: number;
  shortRiskLevel: number;
  riskFactorSource: number;
  riskVariableDescription: string;
  hidden: boolean;
  collectedAt: string;
  isExpired: boolean;
  isDeleted: boolean;
  riskFactorLevelCode: string;
  riskFactorCode: string;
  patientId: number;
  patientVariableId: number;
  id?: number;
  patientVariable?: PatientVariableProperties;
}

export interface PatientRisksHistoryProperties {
  [key: string]: PatientRiskFactorsProperties[];
}

interface ConditionDetailsProperties {
  id: number;
  updatedAt: string;
  createdAt: string;
  conditionName: string;
  conditionResultLevel: number;
  conditionResultId: string;
  patientId: number;
  patientRiskFactors: PatientRiskFactorsProperties[];
  patientRisksHistory: PatientRisksHistoryProperties;
}

const ConditionProfile = () => {
  const { conditionId } = useParams<{ conditionId: string }>();
  const [data, setData] = useState<ConditionDetailsProperties>();
  const [patientName, setPatientName] = useState("");
  const [patientId, setPatientId] = useState("");
  const [breadcrumbTitle, setBreadcrumTitle] = useState("Condition Profile");
  const [currentHistoryTable, setCurrentHistoryTable] = useState("");
  const [historyTableVisible, setHistoryTableVisible] = useState(false);
  const { getConditions, loading } = useFetchPatientProfile();
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Patients Data",
        path: "/patients"
      },
      {
        title: patientName || "Patient Profile",
        path: `/patient-profile/${patientId}`
      },
      {
        title: breadcrumbTitle,
        path: `/condition-profile/${conditionId}`
      }
    ]);
    setCurrentKey("patients");
  }, [setBreadcrumbItems, setCurrentKey, conditionId, breadcrumbTitle, patientId, patientName]);

  useEffect(() => {
    getConditions(endpoints.patientsConditionsData, conditionId).then(responseData => {
      if (!responseData) return;

      setData(responseData);
      setBreadcrumTitle(responseData.conditionName);
      setPatientId(responseData.patientId);

      if (!responseData.patientId) return;
      getConditions(endpoints.patientDetails, responseData.patientId).then(response => {
        response.firstName && response.lastName && setPatientName(`${response.firstName} ${response.lastName}`);
      });
    });
  }, [getConditions, conditionId]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Row gutter={15} style={{ margin: "0 30px" }}>
          <Col span="4" style={{ paddingLeft: "0" }}>
            <AboutCondition conditionResultLevel={data?.conditionResultLevel} />
          </Col>
          <Col span="14">
            <ConditionVariablesTable
              data={data?.patientRiskFactors}
              setCurrentHistoryTable={setCurrentHistoryTable}
              setHistoryTableVisible={setHistoryTableVisible}
            />
          </Col>
          {historyTableVisible && (
            <Col span="6">
              <ConditionHistoryTable data={data?.patientRisksHistory} currentHistoryTable={currentHistoryTable} />
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default ConditionProfile;
