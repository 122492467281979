import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import apiClient, { endpoints } from "../../utils/apiClient";
import { FinancesTableProperties } from ".";
import useDownloadReport from "../Navbar/useDownloadReport";

export interface Filters {
  currentPage: number;
  skip: number;
  startDate: string | null;
  endDate: string | null;
  transactionStatus: string | null;
  orderStatus: string | null;
  query: string | null;
  networkIds: number[] | undefined;
}

export const useItems = () => {
  const [items, setItems] = useState<FinancesTableProperties[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [financesFilters, setFinancesFilters] = useState<Filters | null>(null);

  const history = useHistory();
  const currentLocation = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocation.search);

    setFinancesFilters({
      startDate: searchParams.get("startDate"),
      endDate: searchParams.get("endDate"),
      query: searchParams.get("searchFilter"),
      transactionStatus: searchParams.get("transactionStatus"),
      orderStatus: searchParams.get("orderStatus"),
      currentPage: searchParams.get("page")
        ? Number(searchParams.get("page"))
        : 1,
      skip:
        ((searchParams.get("page") ? Number(searchParams.get("page")) : 1) -
          1) *
        (10 as number),
      networkIds: searchParams.get("networkIds")?.length
        ? searchParams
            .get("networkIds")
            ?.split(",")
            .map((n) => parseInt(n))
        : [],
    });
  }, [currentLocation]);

  useEffect(() => {
    console.log("FILTERS FINANCES: ", financesFilters);

    if (financesFilters === null) return;

    const filters = {
      skip: financesFilters.skip,
      take: 10,
    };
    if (financesFilters.startDate && financesFilters.endDate) {
      Object.assign(filters, {
        startDate: financesFilters.startDate,
        endDate: financesFilters.endDate,
      });
    }
    if (financesFilters.networkIds) {
      Object.assign(filters, {
        networkIds: financesFilters.networkIds,
      });
    }
    if (financesFilters.orderStatus) {
      Object.assign(filters, { orderStatus: financesFilters.orderStatus });
    }
    if (financesFilters.transactionStatus) {
      Object.assign(filters, {
        transactionStatus: financesFilters.transactionStatus,
      });
    }
    if (financesFilters.query) {
      Object.assign(filters, { searchFilter: financesFilters.query });
    }
    apiClient
      .post(endpoints.purchases, filters)
      .then((response) => {
        setItems(response.data.purchases);
        setTotalItems(response.data.count);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          history.push("/dashboard/patients");
        }
      });
  }, [financesFilters]);

  return {
    items,
    setItems,
    loading,
    setLoading,
    totalItems,
    financesFilters,
    setFinancesFilters,
  };
};
