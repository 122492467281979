import { EyeFilled, EyeInvisibleFilled, InfoCircleFilled } from "@ant-design/icons";
import { Button, Table, Tooltip, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContentTitle, PriceContentTitle } from "../ContentTitle";
import { RouteContext } from "../Dashboard";
import useShopOffers from "./useShopOffers";

interface LabPanelsProperties {
  key: number;
  packageType: number;
  labName: string;
  totalPrice: number;
  isHidden: boolean;
}

const ShopOffers = () => {
  const [offers, setOfOffers] = useState<LabPanelsProperties[]>([]);
  const { getOffers, loading } = useShopOffers();
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Shop Offers",
        path: "/shop-offers"
      }
    ]);
    setCurrentKey("shop-offers");
  }, [setBreadcrumbItems, setCurrentKey]);

  useEffect(() => {
    getOffers().then((offers: LabPanelsProperties[]) => setOfOffers(offers.map((offer, key) => ({ ...offer, key }))));
  }, [getOffers]);

  return (
    <div style={{ margin: "10px 30px", overflow: "auto" }}>
      <Table
        columns={[
          {
            title: ContentTitle("Lab name"),
            dataIndex: "labName",
            render: (text, record) => (
              <Typography.Text>
                {record.isHidden && (
                  <Tooltip title="Lab is hidden">
                    <EyeInvisibleFilled style={{ color: "#ACACAC", marginRight: "5px", fontSize: "17px" }} />
                  </Tooltip>
                )}
                {text}
              </Typography.Text>
            )
          },
          {
            title: PriceContentTitle,
            dataIndex: "totalPrice",
            render: (record: string) => (
              <>
                {"$" + record}
              </>
            ),
          },
          {
            title: ContentTitle("action"),
            dataIndex: "action",
            width: "100px",
            render: (text, record, index) => (
              <Tooltip title={`Go to ${record.labName}`}>
                <Button type="text">
                  <Link to={`/dashboard/offer-details/${record.packageType}`}>
                    <EyeFilled style={{ color: "#ACACAC", fontSize: "17px" }} />
                  </Link>
                </Button>
              </Tooltip>
            )
          }
        ]}
        dataSource={offers}
        loading={loading}
        size="small"
        pagination={{
          size: "small",
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize: 10
        }}
        style={{ minWidth: "max-content" }}
      />
    </div>
  );
};

export default ShopOffers;
