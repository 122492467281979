import { useState } from "react"
import apiClient from "../../utils/apiClient"

const useSolveOrder = () => {

    const solveOrder = (path: string, params: any, setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<any> => {

      return new Promise((resolve, reject) => {
        apiClient
          .post(path, params)
          .then(response => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              setLoading(false);
              reject();
            }
          })
          .then(() => {
          });
      });
    };
  
    return { solveOrder };
  };
  
  export default useSolveOrder;
  