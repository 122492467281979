import { useCallback, useState } from "react";
import apiClient, { endpoints } from "../../utils/apiClient";

const useFetchLogsExcerpt = () => {
  const [loading, setLoading] = useState(true);

  const fetchLogsExcerpt = useCallback((id: number): Promise<any> => {
    return new Promise(resolve => {
      apiClient
        .post(endpoints.activityLogsExcerpt, {
          skip: 0,
          take: 10,
          patientId: id
        })
        .then(response => {
          if (response.status === 200) {
            resolve(response.data.activityLogs);
          }
        })
        .then(() => setLoading(false));
    });
  }, []);

  return { fetchLogsExcerpt, loading };
};

export default useFetchLogsExcerpt;
