import { useCallback, useContext, useEffect, useState } from "react";
import apiClient, { endpoints } from "../../utils/apiClient";
import { RouteContext } from "../Dashboard";

export interface OverviewProperties {
  recentPatientsCount: number;
  percentageIncreasePatients: number;
  recentOrdersCount: number;
  percentageIncreaseOrders: number;
  recentTotalIncome: number;
  percentageIncreaseIncome: number;
  totalPatientsCount: number;
  totalDeletedPatientsCount: number;
  totalPatientsWithoutSurveys: number;
  surveys: {
    totalScreeningSurveysCount: number;
    recentScreeningSurveysCount: number;
    totalLifestyleSurveysCount: number;
    recentLifestyleSurveysCount: number;
    totalInsightsSurveysCount: number;
    recentInsightsSurveysCount: number;
  };
  actions: {
    generatedLifestyleActionsCount: number;
    recentGeneratedLifestyleActionsCount: number;
    generatedAwarenessActionsCount: number;
    recentGeneratedAwarenessActionsCount: number;
    generatedShopActionsCount: number;
    recentGeneratedShopActionsCount: number;
    completedLifestyleActionsCount: number;
    recentCompletedLifestyleActionsCount: number;
    completedAwarenessActionsCount: number;
    recentCompletedAwarenessActionsCount: number;
    completedShopActionsCount: number;
    recentCompletedShopActionsCount: number;
  };
}

const useFetchOverviews = () => {
  const [loading, setLoading] = useState(true);
  const [numberOfDays, setNumberOfDays] = useState(30);
  const { filters } = useContext(RouteContext);

  useEffect(() => {
    setNumberOfDays(Number(filters?.days) || 30);
    setLoading(true);
  }, [filters]);

  const fetchOverviews = useCallback((): Promise<OverviewProperties> => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(endpoints.dashboardOverview, { params: { ...filters } })
        .then(response => {
          if (response.status === 200) {
            resolve(response.data);
          }
        })
        .then(() => setLoading(false));
    });
  }, [filters]);

  return { fetchOverviews, loading, numberOfDays };
};

export default useFetchOverviews;
