import { AutoComplete, Form, Input, Select, Space } from "antd";
import { debounce } from "lodash";
import { useState, useEffect } from "react";
import { RouterProps, useLocation } from "react-router-dom";
import { endpoints } from "../../utils/apiClient";
import Spinner from "../Spinner";
import useFetchEmails from "./useFetchEmails";

interface AdminEmailOptionsProperties {
  label: string;
  value: string;
  data: { adminId: string };
}

interface PatientEmailOptionsProperties {
  label: string;
  value: string;
  data: { patientId: string };
}

const LogFilters = ({
  dispatch,
  history,
  isResponsive
}: {
  dispatch: React.Dispatch<any>;
  history: RouterProps["history"];
  isResponsive?: boolean;
}) => {
  const searchParams = new URLSearchParams(useLocation().search);

  const [adminEmailOptions, setAdminEmailOptions] = useState<AdminEmailOptionsProperties[]>([]);
  const [patientEmailOptions, setPatientEmailOptions] = useState<PatientEmailOptionsProperties[]>([]);
  const { loading, setLoading, fetchEmails } = useFetchEmails();

  const [patientDefaultData, setPatientDefaultData] = useState();
  const [adminDefaultData, setAdminDefaultData] = useState();

  const adminId = searchParams.get("adminId");
  const patientId = searchParams.get("patientId");

  useEffect(() => {
    const getAccountEmail = async (accountId: string, isAdmin: boolean) => {

      await fetchEmails(endpoints[isAdmin ? "adminEmail" : "patientEmail"], { [isAdmin ? "adminId" : "patientId"]: accountId }).then(response => {
        if (response.email !== null && response.email !== undefined)
          isAdmin ? setAdminDefaultData(response.email) : setPatientDefaultData(response.email);
      });
    }

    if (adminId !== null && adminId !== undefined && adminDefaultData === undefined)
      getAccountEmail(adminId, true)

    if (patientId !== null && patientId !== undefined && patientDefaultData === undefined)
      getAccountEmail(patientId, false);

  }, [patientId, adminId]);


  const handleLogLevelSelect = (value: string) => {
    dispatch({ type: "UPDATE_BULK_FILTERS", paramCollection: { logLevel: value, page: 1 }, history });
  };

  const handleChange = async (value: string, isAdmin = false) => {

    if (value === undefined || value === "") {
      dispatch({ type: "UPDATE_FILTERS", paramKey: isAdmin ? "adminId" : "patientId", history });
      return;
    }

    setLoading(true);

    const results = await fetchEmails(endpoints[isAdmin ? "searchAdmins" : "searchPatients"], {
      [isAdmin ? "keyword" : "patientEmail"]: value
    });

    if (isAdmin) {
      setAdminEmailOptions(
        results.map((item: { email: string; id: number }) => ({
          label: item.email,
          value: item.email,
          data: { adminId: item.id },
          key: item.id
        }))
      );
    } else {
      setPatientEmailOptions(
        results.map((item: { email: string; id: number }) => ({
          label: item.email,
          value: item.email,
          data: { patientId: item.id },
          key: item.id
        }))
      );
    }
  };

  const handleSelect = (option: any, isAdmin?: boolean) => {
    dispatch({ type: "UPDATE_BULK_FILTERS", paramCollection: isAdmin ? { adminId: option.data.adminId, page: 1 } : { patientId: option.data.patientId, page: 1 }, history });
  };

  const handleFilter = (value: string, option: any) => option.label.includes(value);


  return (
    <Space style={{ display: isResponsive ? "block" : "inline-flex" }}>
      <Form.Item name="log-level-select" style={{ margin: isResponsive ? "10px 0" : "0" }}>
        <Select placeholder="Select Log Level" allowClear defaultValue={searchParams?.get("logLevel")?.toString()} onChange={handleLogLevelSelect}>
          <Select.Option value="Information">Information</Select.Option>
          <Select.Option value="Warning">Warning</Select.Option>
        </Select>
      </Form.Item>

      {
        patientId ?
          (
            patientDefaultData ?
              <Form.Item name="patient-autocomplete" style={{ margin: isResponsive ? "10px 0" : "0" }}>
                <AutoComplete
                  defaultValue={patientDefaultData}
                  allowClear
                  options={patientEmailOptions}
                  filterOption={handleFilter}
                  /* @ts-ignore */
                  loading={loading}
                  onSelect={(value, option) => {
                    handleSelect(option);
                  }}
                  onChange={debounce(value => {
                    handleChange(value);
                  }, 200)}

                >
                  <Input placeholder="Filter by patient email" />
                </AutoComplete>
              </Form.Item>
              :
              <Spinner></Spinner>
          )
          :
          <Form.Item name="patient-autocomplete" style={{ margin: isResponsive ? "10px 0" : "0" }}>
            <AutoComplete
              allowClear
              options={patientEmailOptions}
              filterOption={handleFilter}
              /* @ts-ignore */
              loading={loading}
              onSelect={(value, option) => {
                handleSelect(option);
              }}
              onChange={debounce(value => {
                handleChange(value);
              }, 200)}
            >
              <Input placeholder="Filter by patient email" />
            </AutoComplete>
          </Form.Item>
      }

      {
        adminId ?
          (
            adminDefaultData ?
              <Form.Item name="admin-autocomplete" style={{ margin: "0" }}>
                <AutoComplete
                  defaultValue={adminDefaultData}
                  allowClear
                  options={adminEmailOptions}
                  filterOption={handleFilter}
                  /* @ts-ignore */
                  loading={loading}
                  onSelect={(value, option) => {
                    handleSelect(option, true);
                  }}
                  onChange={debounce(value => {
                    handleChange(value, true);
                  }, 200)}
                >
                  <Input placeholder="Filter by admin email" />
                </AutoComplete>
              </Form.Item>
              :
              <Spinner></Spinner>
          )
          :
          <Form.Item name="admin-autocomplete" style={{ margin: "0" }}>
            <AutoComplete
              allowClear
              options={adminEmailOptions}
              filterOption={handleFilter}
              /* @ts-ignore */
              loading={loading}
              onSelect={(value, option) => {
                handleSelect(option, true);
              }}
              onChange={debounce(value => {
                handleChange(value, true);
              }, 200)}
            >
              <Input placeholder="Filter by admin email" />
            </AutoComplete>
          </Form.Item>
      }
    </Space>
  );
};

export default LogFilters;
