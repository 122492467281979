import { Button, Card, Col, Space, Typography } from "antd";
import { adminRoles } from "../AdminProfile/adminRoles";
import { AdminActionsProperties } from "../AdminProfile/useAdminsActions";
import { ContentTitle } from "../ContentTitle";

const MyAccountInfo = ({
  data,
  loading,
  editProfile,
  changePass,
}: {
  data: AdminActionsProperties | undefined;
  loading: boolean;
  editProfile: { showEditProfile: boolean; setShowEditProfile: Function };
  changePass: { showChangePass: boolean; setShowChangePass: Function };
}) => {
  const accountInfoData = [
    {
      label: "First Name",
      value: data?.firstName,
    },
    {
      label: "Last Name",
      value: data?.lastName,
    },
    {
      label: "Email",
      value: data?.email,
    },
    {
      label: "Phone Number",
      value: data?.phone,
    },
    {
      label: "Role",
      value: data ? adminRoles[data?.role] : "",
    },
  ];

  const toggleEditDisplay = () => {
    editProfile.setShowEditProfile(!editProfile.showEditProfile);
    changePass.setShowChangePass(false);
  };

  const toggleChangeDisplay = () => {
    changePass.setShowChangePass(!changePass.showChangePass);
    editProfile.setShowEditProfile(false);
  };

  return (
    <Col span="5" style={{ paddingLeft: "0" }}>
      <Card
        title={ContentTitle("My Account Info")}
        size="small"
        loading={loading}
      >
        {accountInfoData.map((el, key) => (
          <div key={key}>
            <Typography.Text type="secondary">{el.label}</Typography.Text>
            <Typography.Paragraph>{el.value}</Typography.Paragraph>
          </div>
        ))}
        {data?.role == 10 && (
          <div key={data.lastName}>
            <Typography.Text type="secondary">Network</Typography.Text>
            <Typography.Paragraph>{data.networkName}</Typography.Paragraph>
          </div>
        )}
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button onClick={toggleEditDisplay} block>
            Edit My Account
          </Button>
          <Button onClick={toggleChangeDisplay} block>
            Change Password
          </Button>
        </Space>
      </Card>
    </Col>
  );
};

export default MyAccountInfo;
