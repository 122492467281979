import { useEffect, useState, useCallback, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import apiClient from "../../utils/apiClient";
import { heardAboutUsDbAnswerValues } from "./PatientFilteringOptions";
import filterReducer from "../Navbar/filterReducer";

export interface Filters {
  currentPage: number;
  skip: number;
  userStatus: string | null;
  query: string | null;
  sortProperty: string | null;
  sortOrder: number;
  variableCode: string | null;
  answerIndex: string | null;
  networkId: string | null;
}

const useFetchPatients = (path: string) => {
  const currentLocation: any = useLocation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [patientsFilters, setPatientsFilters] = useState<Filters | null>(null);

  const history = useHistory();
  const [state, dispatch] = useReducer(filterReducer, {});

  useEffect(() => {
    if (currentLocation.state) {
      const state: any = currentLocation.state;

      dispatch({
        type: "UPDATE_BULK_FILTERS",
        paramCollection: {
          networkId: state.networkId,
          page: 1 + "",
        },
        history,
      });

      const searchParams = new URLSearchParams(currentLocation.search);
      setPatientsFilters({
        query: searchParams.get("searchFilter"),
        userStatus: searchParams.get("userStatus"),
        currentPage: searchParams.get("page")
          ? Number(searchParams.get("page"))
          : 1,
        skip:
          ((searchParams.get("page") ? Number(searchParams.get("page")) : 1) -
            1) *
          (10 as number),
        sortProperty: searchParams.get("sortProperty"),
        sortOrder: Number(searchParams.get("sortOrder")),
        variableCode: searchParams.get("variableCode"),
        answerIndex: searchParams.get("answerIndex"),
        networkId: currentLocation.state.networkId,
      });
    } else {
      const searchParams = new URLSearchParams(currentLocation.search);

      setPatientsFilters({
        query: searchParams.get("searchFilter"),
        userStatus: searchParams.get("userStatus"),
        currentPage: searchParams.get("page")
          ? Number(searchParams.get("page"))
          : 1,
        skip:
          ((searchParams.get("page") ? Number(searchParams.get("page")) : 1) -
            1) *
          (10 as number),
        sortProperty: searchParams.get("sortProperty"),
        sortOrder: Number(searchParams.get("sortOrder")),
        variableCode: searchParams.get("variableCode"),
        answerIndex: searchParams.get("answerIndex"),
        networkId: searchParams.get("networkId"),
      });
    }
  }, [currentLocation]);

  const getData = useCallback(async () => {
    if (patientsFilters === null) return;

    try {
      const filters = {
        skip: patientsFilters.skip,
        take: 10,
        [patientsFilters.sortProperty ?? "idSortingType"]:
          patientsFilters.sortOrder !== 0 ? patientsFilters.sortOrder : 2,
      };

      if (patientsFilters.query) {
        Object.assign(filters, { searchFilter: patientsFilters.query });
      }
      if (patientsFilters.networkId) {
        Object.assign(filters, { networkId: patientsFilters.networkId });
      }
      if (patientsFilters.userStatus) {
        Object.assign(filters, { userStatus: patientsFilters.userStatus });
      }
      if (patientsFilters.answerIndex && patientsFilters.variableCode) {
        Object.assign(filters, {
          possibleAnswerValue: {
            variableCode: patientsFilters.variableCode,
            answerIndex: patientsFilters.answerIndex,
            rawValue:
              heardAboutUsDbAnswerValues[Number(patientsFilters.answerIndex)]
                .dbValue,
          },
        });
      }
      const response = await apiClient.post(path, filters);
      console.log(response.data);

      setData(response.data.patients);
      setTotalItems(response.data.count);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [patientsFilters]);

  useEffect(() => {
    getData();
  }, [getData]);

  return { data, loading, totalItems, patientsFilters, setLoading };
};

export default useFetchPatients;
