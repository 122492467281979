import { useEffect, useState } from "react"
import { WarningTableProperties } from "."
import apiClient, { endpoints } from "../../utils/apiClient"


export const useAdminWarnings = () =>{
    const [loading, setLoading] = useState<boolean>(false)
    const [warnings, setWarnings] = useState<WarningTableProperties[]>([])

    const getStuckOrders = async () =>{
        setLoading(true)
        try{
            const response = await apiClient.get(endpoints.stuckOrders)
            setWarnings(response.data.map((row:any)=>({
                ...row,
                key:row.patientOrderId
            })))
        }
        catch(error) {
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(()=>{
        getStuckOrders()
    }, [])

    return{
        getStuckOrders,
        warnings,
        setWarnings,
        loading,
        setLoading
    }
}