import { useState } from "react";
import apiClient, { endpoints } from "../../utils/apiClient";
import { message } from "antd";
import { generateUUID } from "../../utils/utils";
import { LoginFormProperties } from "./LoginForm";
import { TwoFactorFormProperties } from "./TwoFactorForm";

interface UserProperties {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: number;
  isDeleted: boolean;
}

export interface AuthProperties {
  user: UserProperties | undefined;
  setUser: Function;
  attemptToLogin: Function;
  validate2FA: Function;
  logout: Function;
  loading: boolean;
  setLoading: Function;
}

interface TwoFactorLoginProperties {
  isSuccessful: boolean;
  status: string;
  token: string;
  needsTwoFactor: boolean;
  admin: UserProperties;
}

const useAuth = (): AuthProperties => {
  const [user, setUser] = useState<UserProperties>();
  const [currentUserCredentials, setCurrentUserCredentials] = useState<LoginFormProperties>();
  const [loading, setLoading] = useState(false);

  const updateLocalData = (data: TwoFactorLoginProperties) => {
    localStorage.setItem("token", data.token);
    localStorage.setItem("user", JSON.stringify(data.admin));
    setUser(data.admin);
  };

  const attemptToLogin = (credentials: LoginFormProperties) => {
    setLoading(true);

    if (!localStorage.getItem("deviceIdentifier")) {
      localStorage.setItem("deviceIdentifier", navigator.userAgent.split(" ").join("") + generateUUID());
    }

    return new Promise(resolve => {
      apiClient
        .post(endpoints.twoFactorLogin, {
          ...credentials,
          isRememberDevice: credentials.remember,
          rememberDeviceCode: localStorage.getItem("deviceIdentifier")
        })
        .then(response => {
          if (response?.data.isSuccessful) {
            setCurrentUserCredentials(credentials);
            response.data.needsTwoFactor && resolve(true);
            response.data.token && updateLocalData(response.data);
          }
        })
        .catch(() => setLoading(false));
    });
  };

  const validate2FA = async (values: TwoFactorFormProperties) => {
    setLoading(true);
    try {
      const response = await apiClient.post(endpoints.twoFactorValidate, {
        ...currentUserCredentials,
        ...values,
        isRememberDevice: currentUserCredentials?.remember,
        rememberDeviceCode: localStorage.getItem("deviceIdentifier")
      });

      if (response?.data.isSuccessful) {
        updateLocalData(response.data);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      await apiClient.post(endpoints.logout);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.reload();
    } catch (err) {
      message.error("Error!");
      console.error(err);
    }
  };

  return {
    user,
    setUser,
    attemptToLogin,
    validate2FA,
    logout,
    loading,
    setLoading
  };
};

export default useAuth;
