import { Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CSSProperties } from "react";

const Spinner = ({ spinnerStyles }: { spinnerStyles?: CSSProperties }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Space align="center" style={spinnerStyles || { display: "flex", width: "100%", justifyContent: "center" }}>
      <Spin indicator={antIcon} />
    </Space>
  );
};

export default Spinner;
