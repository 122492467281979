import { Card, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import { endpoints } from "../../utils/apiClient";
import { formatDate } from "../../utils/utils";
import { orderStatus, orderStatusColors } from "./orderStatus";
import Spinner from "../Spinner";
import useFetchPatientProfile from "./useFetchPatientProfile";
import { ContentTitle } from "../ContentTitle";
import { useEffect, useState } from "react";
import { BaseType } from "antd/lib/typography/Base";

interface OrderProperties {
  patientOrderId: number;
  orderName: string;
  createdAt: string;
  orderStatus: number;
}

const Orders = ({ id }: { id: number }) => {
  const [data, setData] = useState<OrderProperties[]>();
  const { getConditions, loading } = useFetchPatientProfile();

  useEffect(() => {
    getConditions(endpoints.patientsOrders, id).then(responseData => setData(responseData));
  }, [getConditions, id]);

  return (
    <Card
      title={ContentTitle("Lab Results")}
      size="small"
      style={{ marginBottom: "15px", marginLeft: "10px" }}
      bodyStyle={{ maxHeight: "400px", overflow: "hidden", overflowY: "auto" }}
    >
      {loading ? (
        <Spinner spinnerStyles={{ width: "100%", margin: "10px 0 0", justifyContent: "center" }} />
      ) : data?.length ? (
        data.map((report: OrderProperties, key: number) => (
          <div key={key}>
            <Typography.Text strong style={{ color: "#4285F4", fontSize: "13px" }}>
              <Link to={`/dashboard/order-profile/${report.patientOrderId}`}>{report.orderName}</Link>
            </Typography.Text>
            <Typography.Paragraph type="secondary" style={{ fontSize: "10px" }}>
              <Space style={{ fontSize: "13px" }} align="center">
                {formatDate(report.createdAt)}
                <div style={{ width: "4px", height: "4px", backgroundColor: "#000", borderRadius: "50%" }}></div>
                <Typography.Text type={orderStatusColors[report.orderStatus] as BaseType | undefined}>
                  {orderStatus[report.orderStatus]}
                </Typography.Text>
              </Space>
            </Typography.Paragraph>
          </div>
        ))
      ) : (
        <Typography.Text>No data available!</Typography.Text>
      )}
    </Card>
  );
};

export default Orders;
