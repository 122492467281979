import { Col } from "antd";
import { useEffect, useState } from "react";
import { endpoints } from "../../utils/apiClient";
import ConditionsCard from "./ConditionsCard";
import RootCauses from "./RootCauses";
import useFetchPatientProfile from "./useFetchPatientProfile";

export interface RootCausesProperties {
  [key: string]: { conditionName: string; id: number }[];
}

export interface PatientConditionsProperties {
  id: number;
  conditionResultLevel: number;
}

const PatientConditions = ({ id }: { id: number }) => {
  const [arterialPlaqueData, setArterialPlaqueData] = useState<PatientConditionsProperties>();
  const [inflammationData, setInflammationData] = useState<PatientConditionsProperties>();
  const [rootCausesData, setRootCausesData] = useState<RootCausesProperties>();
  const { getConditions, loading } = useFetchPatientProfile();

  useEffect(() => {
    getConditions(endpoints.patientsConditions, id).then(responseData => {
      if (!responseData) return;
      setArterialPlaqueData(responseData.myArterialPlaque);
      setInflammationData(responseData.myInflammation);
      setRootCausesData(responseData.rootCauses);
    });
  }, [getConditions, id]);

  return (
    <>
      <Col
        className="patient-conditions-cards"
        xxl={{ span: 3 }}
        xl={{ span: 4 }}
        span="24"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <ConditionsCard title="Inflammation" data={inflammationData} loading={loading} />
        <ConditionsCard title="Arterial Plaque" data={arterialPlaqueData} loading={loading} />
      </Col>
      <RootCauses data={rootCausesData} loading={loading} />
    </>
  );
};

export default PatientConditions;
