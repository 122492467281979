import { Cascader, Form, Select, Space } from "antd";
import { useDebugValue, useEffect, useState } from "react";
import { RouterProps } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { heardAboutUsDbAnswerValues } from "../PatientsTable/PatientFilteringOptions";
import { NetworksTableProperties } from "../NetworksTable/types";
import apiClient from "../../utils/apiClient";
import Spinner from "../Spinner";
import useAccessPermissions from "../../utils/useAccessPermissions";

const PatientFilters = ({
  dispatch,
  history,
  isResponsive,
}: {
  dispatch: React.Dispatch<any>;
  history: RouterProps["history"];
  isResponsive?: boolean;
}) => {
  const searchParams = new URLSearchParams(useLocation().search);
  const [defaultValue, setDefaultValue] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [networks, setNetworks] = useState<NetworksTableProperties[]>([]);

  const { hasMedicalAccess, hasFullAccess } = useAccessPermissions();

  useEffect(() => {
    if (hasFullAccess) {
      setLoading(true);
      apiClient
        .post("/networks", {
          skip: 0,
          take: 99999,
          searchFilter: "",
        })
        .then((res) => {
          setNetworks(res.data.networks);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    let rawValue = searchParams.get("answerIndex")
      ? heardAboutUsDbAnswerValues[
          parseInt(searchParams.get("answerIndex") ?? "")
        ].uiValue
      : "";
    setDefaultValue(rawValue);
  }, [searchParams]);

  const handleUserStatusSelect = (value: string) => {
    dispatch({
      type: "UPDATE_BULK_FILTERS",
      paramCollection: { userStatus: value, page: 1 },
      history,
    });
  };

  const handleNetworkSelect = (value: string) => {
    dispatch({
      type: "UPDATE_BULK_FILTERS",
      paramCollection: { networkId: value, page: 1 },
      history,
    });
  };

  const handleHeardAboutUsAnswerSelect = (
    code: string | undefined,
    index: string | undefined
  ) => {
    dispatch({
      type: "UPDATE_BULK_FILTERS",
      paramCollection: { variableCode: code, answerIndex: index, page: 1 },
      history,
    });
  };

  if (loading) return <Spinner />;

  return (
    <Space style={{ display: isResponsive ? "block" : "inline-flex" }}>
      {!hasMedicalAccess && (
        <Form.Item
          name="patient-status-select"
          style={{ margin: isResponsive ? "10px 0" : "0" }}
        >
          <Select
            defaultValue={searchParams.get("userStatus")?.toString()}
            placeholder="Select user status"
            allowClear
            onChange={handleUserStatusSelect}
          >
            <Select.Option value="Developer">Developer</Select.Option>
            <Select.Option value="Patient">Patient</Select.Option>
          </Select>
        </Form.Item>
      )}

      {hasFullAccess && (
        <Form.Item
          name="patient-network"
          style={{ margin: isResponsive ? "10px 0" : "0" }}
        >
          <Select
            defaultValue={searchParams.get("networkId")?.toString()}
            placeholder="Select network"
            allowClear
            onChange={handleNetworkSelect}
          >
            {networks.map((n) => {
              return <Select.Option value={n.id + ""}>{n.name}</Select.Option>;
            })}
          </Select>
        </Form.Item>
      )}

      {((searchParams.get("answerIndex") !== null && defaultValue !== "") ||
        searchParams.get("answerIndex") === null) && (
        <Cascader
          name="patient-answer-select"
          style={{ margin: isResponsive ? "10px 0" : "0" }}
          placeholder="Select heard about us awnser"
          defaultValue={defaultValue !== "" ? [defaultValue] : undefined}
          onChange={(value) =>
            handleHeardAboutUsAnswerSelect(
              value.length > 0 ? "heard_about_us" : undefined,
              value.length > 0 ? value[value.length - 1].toString() : undefined
            )
          }
          expandTrigger="hover"
          changeOnSelect
          options={[
            {
              value: "1",
              label: "From my doctor",
              children: [
                {
                  value: "101",
                  label: "Ford Brewer, MD, MPH",
                },
                {
                  value: "102",
                  label: "David B Wright, MD",
                },
                {
                  value: "104",
                  label: "David Meinz RD",
                },
                {
                  value: "103",
                  label: "Other Doctor",
                },
              ],
            },
            {
              value: 2,
              label: "Social media",
              children: [
                {
                  value: "201",
                  label: "PrevMed/Ford Brewer MD MPH",
                },
                {
                  value: "203",
                  label: "Marie Pizano",
                },
                {
                  value: "202",
                  label: "Other social media",
                },
              ],
            },
            {
              value: 3,
              label: "From a friend",
            },
            {
              value: 4,
              label: "Other",
            },
          ]}
        />
      )}
    </Space>
  );
};

export default PatientFilters;
