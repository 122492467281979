import { Layout, Space, Typography } from "antd";
import { CopyrightOutlined } from "@ant-design/icons";

const MHPFooter = () => {
  const { Footer } = Layout;
  const copyrightYear = new Date().getFullYear();

  return (
    <Footer style={{ backgroundColor: "#fff" }}>
      <Space style={{ display: "flex", justifyContent: "center" }} align="center">
        <Typography.Text>
          MyHealthPlan <CopyrightOutlined /> {copyrightYear}
        </Typography.Text>
      </Space>
    </Footer>
  );
};

export default MHPFooter;
