import { Button, Modal, Space, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import apiClient, { endpoints } from "../../utils/apiClient";
import { useState } from "react";

const FileDownload = ({ item }: { item: any }) => {
    const [loading, setLoading] = useState(false);

    const downloadFile = async (patientId: string, documentId: string) => {
        const res = await apiClient.get(endpoints.patientOrderFile, {
            params: { patientId, documentId },
            responseType: "blob"
        });
        setLoading(false);
        return res.data;
    };

    const handleDownloadFile = async (patientId: string, documentId: string, title: string) => {
        setLoading(true);
        const file = await downloadFile(patientId, documentId);

        Modal.confirm({
            title: "Your file is ready!",
            okText: (
                <a href={URL.createObjectURL(file)} download={title}>
                    Download
                </a>
            )
        });
    };

    return (
        <Space style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
            <Typography.Text>{item.title}</Typography.Text>
            <Button
                loading={loading}
                onClick={handleDownloadFile.bind(null, item.patientId, item.documentId, item.title)}
                icon={<DownloadOutlined />}
            />
        </Space>
    );
};

export default FileDownload;
