import { Console } from "console";
import { RouterProps } from "react-router-dom";

interface ActionProperties {
  type: string;
  history: RouterProps["history"];
  paramValue?: string;
  paramKey?: string;
  paramCollection?: { [key: string]: string };
  networkIds?: number[];
}

const filterReducer = (state: any, action: ActionProperties) => {
  const newFilters = { ...state };
  const searchParams = new URLSearchParams(action.history.location.search);

  switch (action.type) {
    case "UPDATE_FILTERS": {
      if (!action.paramKey) return;

      action.paramValue
        ? searchParams["set"](action.paramKey, action.paramValue)
        : searchParams["delete"](action.paramKey);

      action.history.replace({ search: searchParams.toString() });

      if (action.paramValue) {
        Object.assign(newFilters, { [action.paramKey]: action.paramValue });
      } else {
        delete newFilters[action.paramKey];
      }

      return newFilters;
    }

    case "UPDATE_BULK_FILTERS": {
      for (const paramKey in action.paramCollection) {
        searchParams[action.paramCollection[paramKey] ? "set" : "delete"](
          paramKey,
          action.paramCollection[paramKey]
        );

        if (action.paramCollection[paramKey]) {
          Object.assign(newFilters, {
            [paramKey]: action.paramCollection[paramKey],
          });
        } else {
          delete newFilters[paramKey];
        }
      }

      action.history.replace({ search: searchParams.toString() });

      return newFilters;
    }

    case "UPDATE_NETWORK_IDS": {
      if (action.networkIds) {
        searchParams["set"]("networkIds", action.networkIds.join(","));

        Object.assign(newFilters, {
          networkIds: action.networkIds.join(","),
        });
      }

      action.history.replace({ search: searchParams.toString() });

      return newFilters;
    }

    case "CLEAR_FILTERS": {
      action.history.replace({ search: "" });
      return {};
    }

    default:
      throw new Error("No matching action type");
  }
};

export default filterReducer;
