import { Card, Col, List, Space, Typography } from "antd";
import { ContentTitle } from "../ContentTitle";
import { ConsultationDetailsProps } from ".";

export const ConsultInfo = ({ data }: { data: ConsultationDetailsProps }) => {
  const detailsArray = [
    {
      value: "30 minutes phone consultation with Dr. David B. Wright",
    },
    {
      value: "Comprehensive Health Risk Management Planning",
    },
    {
      value:
        "Does NOT include any medication prescriptions. You can speak with Dr. Wright about additional services like this during your consult.",
    },
  ];

  return (
    <Col
      className="recommendations-card"
      xxl={{ span: 24 }}
      xl={{ span: 24 }}
      span="24"
      style={{ paddingLeft: "0" }}
    >
      <Card
        title={ContentTitle("Included in package")}
        bodyStyle={{
          paddingBottom: "0",
          maxHeight: "250px",
          overflow: "hidden",
          overflowY: "auto",
        }}
        size="small"
      >
        <List
          dataSource={detailsArray}
          renderItem={(item) => (
            <Typography.Paragraph type="secondary" style={{ fontSize: "10px" }}>
              <Space style={{ fontSize: "13px" }} align="center">
                <div
                  style={{
                    width: "4px",
                    height: "4px",
                    backgroundColor: "#000",
                    borderRadius: "50%",
                  }}
                ></div>
                <Typography.Text>{item.value}</Typography.Text>
              </Space>
            </Typography.Paragraph>
          )}
        />
      </Card>
    </Col>
  );
};
