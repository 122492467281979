import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover, Table, Tag, Typography } from "antd";
import { useContext, useEffect, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import { endpoints } from "../../utils/apiClient";
import { formatDate } from "../../utils/utils";
import { ContentTitle } from "../ContentTitle";
import { RouteContext } from "../Dashboard";
import filterReducer from "../Navbar/filterReducer";
import useFetchLogs from "./useFetchLogs";

interface LogProperties {
  id: number;
  patientId: string | number;
  adminId: number;
  message: string;
  patientEmail: string;
  adminEmail: string;
  timestamp: string | number | Date;
  logLevel: string;
}

const ActivityLogs = () => {
  const [tableRows, setTableRows] = useState<LogProperties[]>([]);
  const { logs, loading, setLoading, totalItems, logsFilters } = useFetchLogs(endpoints.activityLogs);
  const { setBreadcrumbItems, setCurrentKey } = useContext(RouteContext);
  const history = useHistory();
  const [state, dispatch] = useReducer(filterReducer, {});

  useEffect(() => {
    setBreadcrumbItems([
      {
        title: "Activity Logs",
        path: "/activity-logs"
      }
    ]);
    setCurrentKey("activity-logs");
  }, [setBreadcrumbItems, setCurrentKey]);

  const logLevelColors: { [key: string]: string } = { Debug: "blue", Information: "green", Warning: "gold" };

  const tableColumns = [
    { title: ContentTitle("log id"), dataIndex: "id", width: "100px" },
    { title: ContentTitle("patient id"), dataIndex: "patientId", width: "100px" },
    { title: ContentTitle("admin id"), dataIndex: "adminId", width: "100px" },
    {
      title: ContentTitle("message"),
      dataIndex: "message",
      ellipsis: true,
      width: "30%",
      render: (text: string, record: { message: string }) => (
        <>
          <Popover content={record.message} overlayStyle={{ maxWidth: "400px", wordWrap: "break-word", whiteSpace: "pre-line" }}>
            <InfoCircleOutlined />
          </Popover>
          <Typography.Text style={{ margin: "0 0 0 5px" }}>{text}</Typography.Text>
        </>
      )
    },
    {
      title: ContentTitle("patient email"),
      dataIndex: "patientEmail",
      render: (text: string, record: { patientEmail: string }) => <Typography.Text>{record.patientEmail}</Typography.Text>
    },
    {
      title: ContentTitle("admin email"),
      dataIndex: "adminEmail",
      render: (text: string, record: { adminEmail: string }) => <Typography.Text>{record.adminEmail}</Typography.Text>
    },
    { title: ContentTitle("timestamp"), dataIndex: "timestamp" },
    {
      title: ContentTitle("log level"),
      dataIndex: "logLevel",
      render: (text: string) => <Tag color={logLevelColors[text]}>{text}</Tag>
    }
  ];

  const handlePageUpdate = (page: number, pageSize: number | undefined) => {
    dispatch({ type: "UPDATE_FILTERS", paramKey: "page", paramValue: page.toString(), history });
    setLoading(true);
  };

  const prepareTableData = (data: LogProperties[]) => {
    return data.map((item: LogProperties, key: number) => ({
      ...item,
      timestamp: formatDate(item.timestamp, true),
      patientId: item.patientId || "",
      key
    }));
  };

  useEffect(() => {
    setTableRows(prepareTableData(logs));
  }, [logs]);

  return (
    <div style={{ margin: "10px 30px", overflow: "auto" }}>
      <Table
        columns={tableColumns}
        dataSource={tableRows}
        pagination={{
          size: "small",
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize: 10,
          current: logsFilters ? logsFilters.currentPage : 1,
          total: totalItems,
          showTotal: (total) => `${tableRows.length} of ${total} results`,
          onChange: handlePageUpdate
        }}
        style={{ minWidth: "1100px" }}
        loading={loading}
        size="small"
      />
    </div>
  );
};

export default ActivityLogs;
